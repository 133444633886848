.search {
  background: #201f2d;
  border: solid 1px #403a59;
  box-shadow: 0 10px 15px 1px #141321;
  border-radius: 12px;
  padding: 15px;
  margin-top: 5px;
  max-height: 70vh;
  min-height: 200px;
  width: 450px;
  overflow: scroll;
  position: relative;
  z-index: 999;

  .loader {
    margin: auto;
    margin-top: 30%;
  }

  .searchListDetail {
    display: grid;
  }

  h3 {
    font-weight: 900;
    border-bottom: solid 1px #403a59;
  }

  .header {
    color: #fff;
    font-size: 15px;
  }

  .text {
    font-size: 12px;
    font-weight: 300;
    color: #6a6388;
    b {
      color: #01cdfd;
    }
    &__white {
      color: #fff;
    }
  }

  img {
    position: unset !important;
    border-radius: 10px;
    width: 50px;
  }

  ul {
    width: 100% !important;
    display: block !important;

    li .searchList {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      &:hover {
        cursor: pointer;
      }

      span {
        padding-left: 10px;
      }
    }

    li:last-child {
      position: unset !important;
    }
  }

  .collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    .item {
      background: #141321;
      border: solid 1px #403a59a6;
      border-radius: 10px;
      text-align: center;
      margin: 5px;
      transition: ease-in-out 0.3s;
      &:hover {
        background: #090817;
        border: solid 1px #403a59;
      }

      span {
        display: flex;
        justify-content: space-between;
      }
      .header {
        display: unset;
        border-bottom: solid 1px #403a59a6;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
      img {
        height: 90px !important;
        width: auto !important;
      }
    }
    .searchCard {
      padding: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .NoResult {
    margin: auto;
    width: 100%;
    text-align: center;
    margin-top: 15%;
  }

  .searchMore {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    background: #403a59;
    padding: 10px;
    border-radius: 10px;
    transition: ease-in-out 0.3s;
    &:hover {
      background: #494362;
    }
  }
}

.search_field {
  display: flex;
  position: relative;
  z-index: 999;
}

.search_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 30%);
  z-index: 2;
}

.mobile_search {
  padding: 30px 10px;
  height: 100vh;
  width: 100%;

  position: fixed;
  top: 0;
  z-index: 999;
  background: #090817;

  .search_field {
    display: flex;
    position: relative;
    z-index: 999;
  }
  input {
    width: 100%;
    border: solid 1px #403a59;
    box-shadow: 0 10px 15px 1px #141321;
    border-radius: 0 12px 12px 0;
    padding: 15px;
    //text-align: center;
  }
  button {
    width: 15%;
    background-color: #201f2d;
    border: solid 1px #403a59;
    box-shadow: 0 10px 15px 1px #141321;
    border-radius: 12px 0 0 12px;
    padding: 15px;
    text-align: center;
  }
  .search {
    background: #201f2d;
    border: solid 1px #403a59;
    box-shadow: 0 10px 15px 1px #141321;
    border-radius: 12px;
    padding: 10px;
    margin-top: 20px;
    max-height: calc(100vh - 200px);
    width: 100%;
    overflow: scroll;
    z-index: 999;
    position: relative;
  }
  .search {
    .collections {
      grid-template-columns: 1fr;
      .item img {
        height: auto !important;
        width: 100% !important;
      }
    }
  }
}

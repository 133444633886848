@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");
@import "../../styles/mixins";

.container{
  padding: 100px;
  .heading{
    font-size: 40px;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;
  }
}
@import "../styles/mixins";

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(11 12 16 / 50%); /* Fallback color */
  //background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  // -webkit-backdrop-filter: blur(3px);
  // backdrop-filter: blur(3px);
  /*
  div {
    background: #1e1e2a !important;
  }
  */
}

/* Modal Content */
.modalContent {
  position: relative;
  background-color: #0b0a1d;
  //background-color: #1e1e2a;
  // border: 4px solid rgba(255, 255, 255, 0.05);
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 777px;
  //min-height: 357px;
  min-height: 150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
  z-index: 9999;
  @include mobile {
    width: 90%;
    margin: auto !important;
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  right: 20px;
  top: 0;
  cursor: pointer;
  font-size: 32px;
  font-weight: 100;
}


.modalHeader {
  padding: 30px 16px;
  //background-color: $darkblue;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  text-align: center;

  h1 {
    font-weight: 500;
  }
}

.modalBody {
  padding: 0;
}

.modalFooter {
  padding: 20px 16px;
  background-color: $blue;
  color: white;
}

.open_modal {
  display: block;
}

.close_modal {
  display: none;
}

@import '../../styles/mixins';
@import '../../styles/_search.scss';

.mintButton {
  text-align: center;
  margin: 0 10px;
  padding: 15px;
  border-radius: 50em;
  border: 2px solid #42cdfd;
  border-radius: 10px;
  background: #141321;
  color: #42cdfd;
  //margin-top: 50px;
  margin-top: 25px;
  transition: ease-in-out 0.2s;

  &:hover {
    background: #42cdfd;
    color: #141321;
    opacity: 1;
  }
  &:active {
    transform: scale(0.9);
  }

  @include mobile {
    margin-top: 100px !important;
  }
}

.disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  pointer-events: none;
}

.warning-msg {
  margin-top: 10px;
  color: #ef4f5a;
  text-align: center;
}

.launchPadContrainer {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 90px;
  position: relative;
  z-index: 0;

  &__no_margin {
    margin-top: 0px !important;
  }

  @include mobile {
    margin-top: 20px;
    &__no_margin {
      margin-top: 50px !important;
    }
  }

  .dot {
    width: 13px;
    height: 13px;
    background-color: #fd3eda;
    margin-right: 20px;
    margin-left: 10px;
    border-radius: 20px;
    box-shadow: 0 0 0px 4px #141321, 0 0 0px 10px #81207094;
  }

  h6 {
    font-family: 'shapiro65_light_heavy_wide';
    position: absolute;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

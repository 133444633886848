@import "../../styles/mixins";

.buttonDivider {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, rgba(2,0,31,0) 0%, #464a77 50%, rgba(2,0,31,0) 100%);
  margin: 10px 10px;
}

.platformMenuButton {
  background: #232240 !important;
  padding: 6px !important;
  border: none !important;
  border-radius: 10px !important;

  img {
    margin: auto !important;

    height: 100%;
  }
}

.platformMenuButtonMobile {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border: none !important;
  padding: 15px 20px !important;
  
  width: 100% !important;
  /* display: flex;
  gap: 10px;
  align-items: center; */
  text-align: right;
  background: transparent !important;
/*   &::after {
    content: '\25bc';
    margin-left: 0;
    font-size: 10px;
    color: #787B9C;
  } */
}

.platformMenuButtonMobileActive {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border: none !important;
  padding: 15px 20px !important;

  width: 100% !important;
  /* display: flex;
  gap: 10px;
  align-items: center; */
  text-align: right;
  opacity: 0.6;
  background: transparent !important;
  /* &::after {
    content: '\25bc';
    margin-left: 0;
    font-size: 10px;
    color: #787B9C;
  } */
}

.platformMenuModal {
  position: absolute;
  width: max-content;
  min-width: 450px;
  height: auto;
  top: 10px;
  right: 0;
  z-index: 99999;

  padding: 20px 15px;
  padding-bottom: 0;
  margin-bottom: 50px;

  background: #232240;
  border: 1px solid #383755;
  border-radius: 6px;

  text-align: right;
  overflow-x: hidden;
  overflow-y: scroll;

  @include mobile {
    position: relative;
    width: 100%;
    min-width: 100%;
    height: 100%;

    background: transparent;
    border: transparent;
    overflow: hidden;
    padding: 20px 0px;
    //padding-bottom: 100px;
  }

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: rgba(246, 245, 255, 0.6);

    padding: 0 30px 20px 30px;

  }

  .platformItems {
    width: 100%;
    height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;

    @include mobile {
      height: auto;
    }

    &::-webkit-scrollbar {
      width: 5px;
      display: block !important;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
      padding-right: 5px !important;
      margin-top: 0px !important;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #878BB7;
      border: none !important;
    }

    .links {
      padding: 10px 30px;
      a {
        text-decoration: none;
        display: block;
        margin: 20px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $blue !important;
        &:hover {
          opacity: 0.7;
        }
        @include mobile {
          text-align: right !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(2,0,31,0) 0%, #464a77 50%, rgba(2,0,31,0) 100%);
      margin: 24px 10px 14px 10px;
    }

    .link {
      padding: 0 !important;
      text-decoration: none;

      &:hover {
        color: #fff !important;
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      gap: 20px;

      padding: 10px 30px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        color: #FFFFFF;
      }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        width: 109px !important;
        height: 36px;

        position: relative;
        padding: 8px 14px;

        background: linear-gradient(45deg, transparent, transparent);
        color: #fff;
        border: 1px solid #464A77;
        border-radius: 8px;

        overflow: hidden;
        transition: ease-in-out 300ms;

        .rocket {
          //display: none;
          position: absolute;
          left: -20px;
          top: 50px;
          width: 18px;
          transition: ease-in-out 300ms;
        }

        &:hover {
          background: linear-gradient(45deg, #62d9ec, #E659D6);

          p,
          img {
            display: none !important;
          }

          .rocket {
            display: block !important;
            position: absolute;
            left: 45px;
            top: 8px;
          }

          &:focus {
            .rocket {
              position: absolute;
              left: 100px;
              top: -50px;
            }
          }
        }
      }
    }
  }

  button {
    width: 100% !important;
  }
}
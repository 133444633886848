.pricePerTicket {
  margin-top: 30px;
}

.amountOfTickets {
  margin-top: 30px;
}

.mint {
  margin-top: 40px;

  span {
    font-weight: 300;
    color: #8d8d8d;
    font-size: 12px;
    line-height: 19px;

    b {
      color: #fff;
      margin: 0 5px;
    }
  }
}

.input {
  display: inline-flex;
  width: 100%;
  margin: 20px 0;

  input {
    width: 100%;
    border: solid 2px #2f2f43;
    padding: 15px;
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
  }

  button {
    width: 90px;
    color: #fff !important;
    transition: ease-in-out 0.2s;
    border: 2px solid #2f2f43 !important;
    background: #313336 radial-gradient(circle, transparent 1%, #313336 1%) center/15000% !important;

    &:first-child {
      border-right: none !important;
      border-radius: 10px 0 0 10px;

      &::before {
        //content: url('../../../assets/webapp_minus.svg');
        display: block;
        //margin-top: 7px;
      }
    }

    &:last-child {
      border-left: none !important;
      border-radius: 0 10px 10px 0;

      &::before {
        //content: url('../../../assets/webapp_add.svg');
        display: block;
        //margin-top: 7px;
      }
    }

    &:last-child, &:first-child {
      transition: background 0.4s;

      &:hover {
        background: #01cffdb0 radial-gradient(circle, transparent 1%, #01cffdb0 1%) center/15000% !important;
      }

      &:disabled {
        background: #4e4d5d radial-gradient(circle, transparent 1%, #4e4d5d 1%) center/15000% !important;

        &:active {
          background-color: #272738 !important;
          background-size: 100% !important;
          transition: background 0s;
        }
      }

      &:active {
        background-color: #01cdfd !important;
        background-size: 100% !important;
        transition: background 0s;
      }
    }
  }
}

.footer {
  padding: 0;
  margin: 0 !important;
  float: none;
  #mint {
    &:disabled {
      img {
        filter: brightness(6);
      }
    }
  }
  button {
    background: #01cdfd radial-gradient(circle, transparent 1%, #01cdfd 1%) center/15000%;
    border-radius: 10px;
    width: 100%;
    height: 52px;
    margin-top: 20px;
    font-size: 18px;
    color: #1b1b1b;
    font-weight: 500;
    transition: background 0.8s;
    &:hover {
      background: #01cffdca radial-gradient(circle, transparent 1%, #01cffdca 1%) center/15000%;
      border: solid 2px #01cdfd;
    }
    &:active {
      background-color: #13c3ea83;
      background-size: 100%;
      transition: background 0s;
    }
    &:disabled {
      background: #4E4D5D;
      border: solid 2px #4E4D5D;
      color: #a1a1a1;
    }
  }
}

.launchLiveProject {
  &::-webkit-scrollbar {
    display: none;
  }
}

.webapp_header_tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  text-align: center;
  div {
    width: 113px;
  }
  .active {
    background: #27253E;
    border-radius: 16px;
    padding: 5px 10px !important;
  }
}

.webapp_launchLiveSlider {
  display: block;
  margin-top: 40px;
  max-width: 600px;
  &::-webkit-scrollbar {
    display: none;
  }

  .sliderBlock {
    width: 100%;
    //padding: 5px;
    border-radius: 20px;
    @include mobile {
      width: 100%;
    }
    .daynamicSlider {
      display: flex;
      border: none;
      margin: 0;
      position: relative;
      .image_overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(23,23,40);
        background: linear-gradient(0deg, rgba(23,23,40,1) 0%, rgba(23,23,40,0) 50%);
      }

      img {
        width: 100%;
        //flex-shrink: 0;
        //min-width: 100%;
        min-height: 100%;
        border: 1px solid #25213E;
        border-radius: 12px;
        transform: scale(1) !important;
      }

      .webapp_project {
        display: flex;
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 100%;
        justify-content: left;
        align-items: center;

        .date {
          display: grid;
          text-align: center;
          background: #111023;
          border: 1px solid #25213E;
          border-radius: 8px;
          padding: 8px;
          .day {
            font-size: 16px;
          }
          .month {
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.64px;
            text-transform: uppercase;

            color: #999CB6;
          }

        }
        .project_right {
          p {
            padding: 0 15px;
            font-size: 14px;
            text-align: left;
            color: #fff;
            line-height: 17px;
          }
          span {
            padding: 0 15px;
            font-size: 12px;
            color: #8d8d8d;
          }
        }
      }
    }
  }

  p {
    padding: 0 0;
    font-size: 16px;
    text-align: center;
    color: #8d8d8d;
  }

  .social {
    text-align: center;
    border-top: 1px solid #2e2e2e;
    //margin: 25px 35px;
    margin: 20px 35px;
    a {
      padding: 0 20px;
      position: relative;
      top: -12px;
      background: #1e1e2a;
    }
  }
}

.webapp_launchLiveSliderInner {
  padding: 0 15px;
  .details {
    padding: 20px 0;
    h6 {
      position: inherit;
      justify-content: initial;
      font-size: 14px;
      line-height: 30px;
    }
    .highlight {
      font-weight: 300;
      font-size: 14px;
      color: #E36B7D;
    }
    .highlight_soon {
      background: linear-gradient(113.55deg, #FCCFF7 13.87%, #E659D6 32.17%, #8877EA 57.87%, #86E2EE 82.56%), #999CB6;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    p {
      font-size: 14px;
      display: flex;
      gap: 5px;
    }
  }
}




.webapp_launchViewHeader {
  display: flex;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  
  .launchViewLeft {
    width: 100%;
  }

  .launchViewRight {
    width: 100%;
    max-width: 600px;
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    .nft_view {
      background: #25213E;
      border: 1px solid #25213E;
      border-left: none;
      box-shadow: 0px 20px 40px rgb(0 0 0 / 10%);
      border-radius: 0;
      position: relative;
      padding: 5px 20px;

      .loginStyleHeader {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 0;
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer_div {
          align-items: baseline;
          gap: 10px;
        }

        #minus {
          background: #6959E6 !important;
          border-radius: 72px;
          width: 40px;
          height: 40px;
        }

        #plus {
          background: #6959E6 !important;
          border-radius: 72px;
          width: 40px;
          height: 40px;
        }

        input {
          width: 65px;
          background: #090817;
          border: 1px solid #6959E6;
          box-shadow: 0px 0px 8px rgba(0, 33, 188, 0.5);
          border-radius: 12px;
        }

        .buyNowButton {
          width: 167px;
          height: 56px;
        }

        button {
          width: 100%;
          background: #01cdfd radial-gradient(circle, transparent 1%, #01cdfd 1%) center/15000%;
          border-radius: 10px;
          height: 70px;
          margin-top: 0;
          &:disabled {
            background: #3b3659 !important;
            border: solid 2px #3b3659;
            color: #848080;
          }
        }
        b {
          display: flex;
          align-items: center;
          gap: 20px;
          .ticket_icon {
            padding: 20px;
            background: #1D1C37;
            border-radius: 12px;
            width: 65px;
          }
          div {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: rgba(246, 245, 255, 0.6);
            }
          }
        }
      }
    }
  }

  .daynamicSlider {
    @include desktop {
      overflow: hidden !important;
    }

    img {
      width: 100%;
      //flex-shrink: 0;
      //min-width: 100%;
      min-height: 100%;
      border: 1px solid #25213E;
      border-radius: 12px;
      transform: scale(1.2) !important;
    }
  }

  .titleBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    color: #8d8d8d;
    //padding: 0 15px;
    margin-bottom: 33px;
    @include mobile {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .title {
      display: flex;
      align-items: center;

      img {
        @include mobile {
          display: none;
        }
      }
      h6 {
        font-family: 'shapiro65_light_heavy_wide';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @include mobile {
          font-size: 18px;
          padding: 0 0 !important;
        }
      }
    }

      .status {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 14px;
        color: #fff;
        width: fit-content;
        margin: 0 auto;
        padding: 4px 8px;
        border: 2px solid transparent;
        border-radius: 10px;
        background: #FA7753;
        border: 0.5px solid #FFFFFF;
        border-radius: 4px;
        z-index: 9999;

        @include mobile {
         // margin-top: 100px !important;
        }
    }
    .activeTab {
      color: #fff;
      background:
      linear-gradient(#3168F7, #01CDFD) padding-box,
      linear-gradient(#3168F7, #01CDFD) border-box;
    }
  }

  .webapp_project_about {
    margin-top: 50px;
    margin-bottom: 200px;
    h6 {
      padding: 0;
      text-align: left;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: #787B9C;
    }
    p {
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #F6F5FF;
    }
  }


  .webapp_project {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 40px;

    .date {
      display: grid;
      text-align: center;
      background: #1a1930;
      border: 1px solid #25213E;
      border-radius: 8px;
      padding: 15px;
      .day {
        font-size: 16px;
      }
      .month {
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.64px;
        text-transform: uppercase;

        color: #999CB6;
      }

    }
    .project_right {
      padding: 0 5px;
      margin-top: 15px;
      .title {
        margin-top: 0 !important;
        padding: 0 5px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        color: #fff;
        line-height: 17px;
      }
      div {
        display: flex;
        flex-direction: row-reverse;
        margin: 15px 0;
      }
      p {
        margin-top: 0 !important;
        font-size: 12px;
        text-align: left;
        color: #fff;
        line-height: 17px;
        padding: 0 5px;
      }
      span {
        padding: 0 5px;
        font-size: 12px;
        color: #8d8d8d;
      }
    }
  }
}


.webapp_container {
  width: 100%;
  height: 177px;
  margin-top: 30px;

  background: #1a1930;
  border-radius: 12px;

  .ticket_progress {
    padding: 5px 32px;
    margin-top: 10px;
    .container {
      background: #3b3a3a;
      border-radius: 10px;
      div {
        background: linear-gradient(104.36deg, #FCCFF7 10.19%, #E659D6 35.3%, #8877EA 62.03%, #86E2EE 88.37%), #1E1D3A !important;
      }
    }
  }

  .tickets {
    display: flex;
    justify-content: space-between;
    padding: 5px 32px;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #999CB6;

      span {
        color: #fff;
      }
    }
  }
}


.webapp_details {
  background: #100F1E;
  border: 1px solid #24243C;
  border-radius: 12px 12px 0px 0px;
  //height: 250px;
  width: 100%;
  padding: 25px;

  .div {
    margin-top: 20px;
    border-top: 2px solid #16152B;
  }

  h6 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: #787B9C;

    margin-bottom: 20px;
  }

  .mintCount {
    padding: 3px 6px;
    background: #1d1c37;
    border-radius: 8px;
    font-size: 16px;
    p {
      font-size: 16px; 
    }
  }
  .mintLimit {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(246, 245, 255, 0.6);

    margin-top: 5px;
    span {
      color: #fff;
    }
  }

  .lowBal {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #E36B7D;

    margin-top: 15px;
  }

  .add_bal {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.07em;
    font-feature-settings: 'cv05' on, 'cv11' on;
    border: 1px solid rgba(105, 89, 230, 0.4);
    border-radius: 19px;
    padding: 3px 7px;

    color: #6959E6;

  }


  b {
    display: flex;
    align-items: center;
    gap: 20px;
    .ticket_icon {
      padding: 12px;
      background: #1D1C37;
      border-radius: 12px;
      width: 45px;
    }
    div {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: rgba(246, 245, 255, 0.6);
      }
    }
  }
}



.mintButton {
  text-align: center;
  margin: 0 10px;
  padding: 15px;
  border-radius: 50em;
  border: 2px solid #42cdfd;
  border-radius: 10px;
  background: #141321;
  color: #42cdfd;
  //margin-top: 50px;
  margin-top: 25px;
  transition: ease-in-out 0.2s;

  &:hover {
    background: #42cdfd;
    color: #141321;
    opacity: 1;
  }
  &:active {
    transform: scale(0.9);
  }

  @include mobile {
    margin-top: 100px !important;
  }
}

.disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  pointer-events: none;
}

.warning-msg {
  margin-top: 10px;
  color: #ef4f5a;
  text-align: center;
}



.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* Animation */

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}


.webapp_back {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #01CDFD;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0;
  cursor: pointer;
}

.myticketsInner {
  max-width: 400px;
  margin: auto;
  margin-top: 10px;

  .mynftRight {
    
    .header {
      padding: 3px 0 !important;
    }
  }

  .nftData {
    label {
      p {
        font-size: 14px;
        color: #fff !important;
        font-weight: 400;
        b {
          font-size: 14px;
          margin-bottom: 0;
          padding-left: 5px;
          font-weight: 400;
        }
      }
      b {
        font-size: 11px;
      }
    }
    &__webapp {
      padding: 0 20px;
    }
  }

  .nftRightChild {
    //max-height: 518px;
    //min-height: 618px;
    background: #1e1e2a;
    background: linear-gradient(90deg, rgba(38 35 60) 0%, rgba(36,36,60,1) 49%, rgba(23,23,40,1) 100%);
    box-shadow: 0px 20px 40px rgb(0 0 0 / 10%);
    border-radius: 15px;
    padding: 0;
    display: block;
    margin-bottom: 20px;
    &__webapp {
      background-image: url('/assets/images/ticket_card_bg.png');
      background-repeat: no-repeat;
      background-position: center;
    }

    span {
      width: 100%;
      background: transparent;
    }

    .mynftHub {
      padding: 0;
      width: 100%;
      .nftData {
        padding: 0 20px;
      }
    }

    .img_container {
      width: 100%;
      max-height: 160px;
      margin: auto;
      background: transparent;
      border-radius: 10px;
      overflow: hidden;
      display: block;

      border: 2px solid #474662;

      &_full {
        max-height: 300px;
        img {
          border-radius: 10px;
          width: 100%;
          margin-top: 0 !important;
        }
      }

      img {
        border-radius: 10px;
        width: 100%;
        margin-top: -20%;
      }
    }

    .dashed_div {
      width: 100%;
      height: 50px;
      background-image: url('/assets/images/ticket_card_dash.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    .mynftHubHedare {
      margin-bottom: 0;
      label {
        background: transparent;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 15px 0 0 0;
        font-size: 15px;
        line-height: 19px;
        text-transform: capitalize;
        color: #ffffff;
        span {
          background: transparent;
        }
      }
      .nftTitle {
        padding: 0 20px;
        .header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 !important;
          margin-bottom: 0;

          .qrcode {
            width: 52px;
            height: 52px;
            background-color: #fff;
            border-radius: 8px;
            img {
              min-width: 100% !important;
            }
          }
          span {
            color: #787B9C;
            padding: 10px;
          }
        }
      }
    }
  }
}

.webapp_actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  padding: 20px;
  padding-top: 0;

  a {
    border-radius: 12px;
    width: 100%;
    button {
      color: #fff;
      background: #8877EA;
      border: 1px solid #8877EA;

      font-weight: 500;
    }
  }

  button {
    color: #000;
    background: #59D6E6;
    border: 1px solid #59D6E6;
    border-radius: 12px;
    padding: 11px;

    font-weight: 500;

    width: 100%;
  }
}


.note {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */


  color: rgba(246, 245, 255, 0.6);

  padding: 15px 10px;
}

.webapp_walletconnected {
  display: flex;
  img {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #26253E;
    border-radius: 30px;
    padding: 5px;
    margin-left: 10px;
  }
}

.webapp_stakeModal {
  h5 {
    text-align: center;
    color: #F6F5FF;
    font-weight: 600;
    font-size: 18px !important;
  }
  .Modal_modalContent__5WIER {
    max-width: 323px;
    min-height: 259px;
    margin: auto;
    background: #141321 !important;
    border: 1px solid #29273c;
    border-radius: 16px;
  }
  .aucHub .auc_split {
    width: 100%;
    padding-left: 0;
    margin: auto;
  }
  button {
    width: 100%;
    padding: 10px 80px;
    margin: 2px 0;
    color: #787B9C;
  }
  .cnfrmStake {
    color: #040317;
    background: #59D6E6;
    border-radius: 12px;
  }

  .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
  }
}

.webapp_modalBlur {
  filter: blur(1.5rem);
}


.FullProductInfo_webapp {
  max-width: 800px;
  margin: auto;
  padding-top: 0px !important;

  .wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .detailTrending {
    display: block;
    margin-top: 80px;
    align-items: flex-start;
    margin-top: 0px;
  }
  .detailTrendingLeft, .detailTrendingRight {
    margin: auto;
  }
}
@import "../styles/mixins";
@import "../styles/variables";

.webAppButton {
  max-width: 360px;
  min-width: 360px;

  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
}

.button {
  @include flex;
  @include button;
  @include transition;
  color:$theme_black_color;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  & + & {
    margin-left: 10px;
  }

  // sequence important
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.loading {
    opacity: 0.5;
  }
}

@mixin hover {
  &:hover:not(.loading):not(.disabled) {
    @content;
  }
}

.button:not(.outline) {
  background-color:$blue_color ;
  // $theme_light_blue_color;
  color: #1b1b1b;
  border: 1px solid $blue_color ;
  // $theme_light_blue_color;

  @include hover {
    background-color:$blue_color ;
    // $theme_dark_blue_color;
    border: 1px solid $blue_color ;
    // $theme_dark_blue_color;
  }

  &.disabled {
    background-color:$blue_color ;
    //  $theme_dark_blue_color;
  }
}

/* outline */
.outline {
  border-width: 1px;
  border-style: solid;

  @include hover {
    opacity: 0.75;
  }

  @mixin button-outline-variant($color) {
    border-color: $color;
    color: $color;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      @include button-outline-variant($color);
    }
  }

  &.secondary {
    @include button-outline-variant(fade-out(white, 0.4));
  }
}

/* block */
.block {
  width: 100%;
}

/* sizes */
@mixin button-size($font-size, $height, $padding) {
  border-radius: ($height / 5);
  font-size: $font-size;
  height: $height;
  padding: 0 $padding;
}

.xs {
  @include button-size(10px, 22px, 10px);
}

.sm {
  @include button-size(13px, 26px, 10px);
  min-width: 74px;
}

.md {
  @include button-size(14px, 36px, 20px);
  min-width: 160px;
}

.lg {
  @include button-size(16px, 50px, 30px);
  width: 100%;
}

/* theme */
.submit {
  margin-top: 30px;
}

/* label */
.progress {
  margin-right: 5px;
}

.swapIcon{
  width:35px;
}
img.swapIcon {
  width: 20px;
  margin-right: 3px;
}
.desc {
  .claim {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 10px 0;
    padding: 20px;
    background: rgba(1, 205, 253, 0.08);
    border: 1px solid rgba(1, 205, 253, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    color: #fff;
    span {
      color: #bbbbbb;
    }
    button {
      color: #01cdfd;
      text-decoration: underline;
    }
  }
  .detailStakeSellButtons {
    .noPad {
      padding: 0 !important;
    }
  }
}

.th--cursor {
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
  }
}
.iconnftname{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img{
    height: 35px;
  }
}
.collectionName{
  color: #C83E93 !important;
  cursor: pointer !important;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.nft-attributes {
  //border: 1px solid #2d2d2d;
  padding: 20px 20px !important;
  border-radius: 20px;
  //background: #1E1E2A;
  //box-shadow: 0px 20px 40px rgb(0 0 0 / 10%);
/*
  li {
    border-bottom: 1px solid #2b2a35;
  }
*/
}

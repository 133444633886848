@import '../../styles/mixins';


.card {
  margin-bottom: 150px;

  .right {
    max-width: 600px;
    width: 100%;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 80px;
    max-width: 2000px;

    @include mobile {
      display: block;
    }

    .collectionImage {
      border: 1px solid #22203B;
      position: relative;
      border-radius: 8px;

      width: 600px;
      height: 600px;
      overflow: hidden;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .placeholderImage {
        width: 100%;
        height: 100%;
        object-fit: cover;

        background-color: #f6f7f8;
        background: linear-gradient(to right, #1b1a28 8%, #ffffff15 25%, #1b1a28 33%);
        background-size: 800px 104px;
        
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
      }

      @include mobile {
        width: 100%;
        height: 400px;

        margin: 50px 0;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      color: #FFFFFF;
    }

    p {
      margin-top: 15px;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      color: #999CB6;

    }

    .mintDate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 18px;
      padding: 40px 0;

      @include mobile {
        flex-wrap: wrap;
      }

      .date {
        width: calc(50% - 10px);
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        color: #fff;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 16px;
        padding: 20px 40px;

        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          color: #999CB6;
          display: block;
          padding-top: 6px;
        }
      }
    }

    .mintStart {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding-bottom: 20px;

      span {
        display: block;
        color: #999CB6;
      }

      p {
        color: #fff;

        @include mobile {
          font-size: 14px;
        }
      }
    }

    .mintButton {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 35px 48px;
      color: #fff;
      //background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%);
      border: none;
      border-radius: 12px;

      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      background: var(--background-gradient);
      background-size: 200% 100%;

      animation: gradient 8s ease infinite;
      transition: all 500ms;

      &:hover {
        border: none !important;
        transform: scale(0.97);
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
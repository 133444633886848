
@import "../../../../styles/mixins.scss";


.collectibleDetails {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  //margin-top: 120px;
  position: relative;
  //margin-bottom: 200px;

  .items {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;

    .item_card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      gap: 10px;

      width: 100%;
      height: 56px;

      background: var(--primary-bg);
      border-radius: 12px;
      border: solid 1px var(--border-color);

      p {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        img {
          width: 24px;
        }
      }
    }
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0;
    }
    @include mobile {
      display: block;
      width: 100%;
      margin: 50px 0;
      .item_card { 
        margin: 20px auto;
        width: 100%;
      }
    }
  }

  .title {
    display: flex;
    margin-bottom: 30px;

    img {
      margin-right: 15px;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 36px;
    line-height: 53px;
    text-align: left;
    color: white;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    span {
      background: linear-gradient(123.75deg, #9B83F9 53.46%, #8164F5 66.94%, #27AEF8 80.94%, #31A2FA 97.26%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .info_header {
    @include mobile {
      width: 100%;
    }
    p {
      margin: auto;
      text-align: left;
      font-size: 15px;
      line-height: 18px;
      color: #BBBBBB;
    }
  }

  .tableHeader__mobile {
    background: #1e1e2a !important;
    border: solid 1px #262636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    p {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: solid 1px #262636;
    }
    @include desktop {
      display: none;
    }
  }
  .tableHeader__mobile__divider {
    background: #262636;
    width: 100%;
    height: 1px;
    @include desktop {
      display: none;
    }
  }
}

.projectinfo {
  margin-top: 120px;
  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      display: block;
    }
  }

  .split {
    display: flex;
    justify-content: space-between;
    @include mobile {
      display: block;
    }
  }

  .info_card {
    background: #1E1E2A;
    border-radius: 15px;
    padding: 32px;
    text-align: center;
    //width: 100%;
    p {
      display: grid;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      color: #919191;
    }
    span {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }

    &__left {
      margin: 26px 0 0 0;
    }
    &__center {
      margin: 26px 0 0 26px;
    }
    &__right {
      margin: 26px 0 0 26px;
    }
    &__wallet {
      min-width: 600px;
    }
    @include mobile {
      &__left, &__center, &__right {
        margin: 26px 0 !important;
      }
      &__wallet {
        min-width: 100%  !important;
      }
    }
  }
}

.containter {
  border: 1px solid #363646;
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: grey;

  div:last-child {
    color: #c83e93;
    cursor: pointer;
  }
}

.attribute {
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
}

.attribute.selected {
  background: #2d2c3b;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.values {
  background: #2d2c3b;
  border-bottom-left-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom-right-radius: 15px;

  .line {
    margin: 0px;
  }
}

.valueItem {
  padding: 15px 5px;
  font-size: 12px;
  span {
    color: grey;
  }
}

.line {
  border-bottom: 1px solid grey;
  margin-left: 15px;
  margin-right: 15px;
}

.checkbox {
  position: relative;
  border-radius: 2px;
  border: 1px solid grey;
  width: 15px;
  height: 15px;
  float: right;
}

.checkbox .checkmark {
  position: absolute;
  left: 5px;
  top: -6px;
  width: 9px;
  height: 17px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

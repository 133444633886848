@import "../styles/mixins";

$background: #1b1a283d;

.page {
    max-width: 1145px;
    margin: auto;
    margin-top: 250px;
    @include tablet {
        margin-top: 50px;
    }
    @include mobile {
        margin-top: 0px;
    }
}

.loadingWrapper {
    .nftImage {
        float: left;
        align-items: center;
        border-radius: 5%;
        overflow: hidden;
        margin-bottom: 20px;

        background: $background;
        position: relative;
        overflow: hidden;

        img {
            width: 400px;
            opacity: 0;
        }
    }

    .attributes {
        @include mobile {
            margin-bottom: 90px;
        }
    }

    .small_loader {
        background: $background;
        color: transparent !important;
        width: fit-content;
        height: fit-content;

        min-width: 200px;
        position: relative;
        overflow: hidden;
    }

    .large_loader {
        background: $background;
        color: transparent !important;
        width: fit-content;
        height: fit-content;

        position: relative;
        overflow: hidden;
    }

    .attribute_loader {
        width: 100% !important;

        position: relative;
        overflow: hidden;
    }

    .nftImage,
    .small_loader,
    .large_loader,
    .attribute_loader {
        background: $background;
        border: solid 1px #ffffff08;

        color: transparent !important;

        border-radius: 5px;

        position: relative;
        overflow: hidden;

        @include mobile {
            max-width: 100%;
        }

        &::before {
            content: "";
            overflow: hidden;
            position: absolute;
            background: linear-gradient(90deg,
                    transparent,
                    #ffffff15,
                    transparent);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            animation: loading 1.5s infinite;
        }
    }
}

@keyframes loading {
    0% {
        transform: skewX(-10deg) translateX(-100%);
    }

    100% {
        transform: skewX(-10deg) translateX(200%);
    }
}
@import './mixins';
@import './_search.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@font-face {
  font-family: 'allura';
  src: url('../assets/fonts/allura.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Shapiro';
  src: url('../assets/fonts/shapiro.otf') format('truetype'); /* Safari, Android, iOS */
}

:root {
  --wallet-button: #151331;
}

.cosmos {
    --primary-color: #C83E93;
    --primary-hue: #c83e9373;
  
    --secondary-color: #403a59;
    --secondary-hue: #c83e9331;
  
    --primary-bg: #151331;
    --secondary-bg: #1E1B45;
  
    --disabled-bg: #4E4D5D;
    --disabled-color: #a1a1a1;
  
    --border-color: #38364E;
  
    --disabled: #4E4D5D;
  
    --color-first: #62D9EC;
    --color-second: #9469E0;
    --block-width: 300px;
    --block-height: 300px;
    --border-width: .280rem;
    box-sizing: border-box;
    line-height: 1.4;

    --background-gradient: linear-gradient(107.74deg, #c83e93c1 27.71%, #C83E93 49.51%, #27AEF8 72.15%, #31A2FA 98.54%), #F6F5FF;
}

.fans {
  --primary-color: #9c67e3;
  --primary-hue: #9d67e358;

  --secondary-color: #403a59;
  --secondary-hue: #37236378;

  --primary-bg: #151331;
  --secondary-bg: #1E1B45;

  --disabled-bg: #4E4D5D;
  --disabled-color: #a1a1a1;

  --border-color: #38364E;

  --disabled: #4E4D5D;

  --color-first: #62D9EC;
  --color-second: #9469E0;
  --block-width: 300px;
  --block-height: 300px;
  --border-width: .280rem;
  box-sizing: border-box;
  line-height: 1.4;

  --background-gradient: linear-gradient(107.74deg, #9B83F9 27.71%, #8164F5 49.51%, #27AEF8 72.15%, #31A2FA 98.54%), #F6F5FF;
  }

img {
  pointer-events: none;
}

html {
  background: transparent;
  min-height: 100vh;
}

body {
  //background-color: #141321;
  background-color: #02001F;
}

.walletConnectModal {
  width: 400px;
  max-width: 500px;
  @include mobile {
    width: 100%;
    max-width: 100%;
  }
}

.body-dark {
  background-color: #02001F;
}
.homeTrending h3.exploreHeading {
  display: none;
}
// to show and hide
.hideContent {
  display: none !important;
}

.showContent {
  display: block !important;
}

.showContent .exploreHeading {
  font-size: 32px;
  line-height: 30px;
  font-weight: normal;
  text-align: left;
  padding: 50px 0;
}

.exploreHeading {
  text-align: left;
  color: white;
  margin: 0;
  padding: 30px 0 25px;
  font-family: 'shapiro65_light_heavy_wide';
}

.bannerdiv {
  .discoverBanner {
    padding-top: 100px !important;
  }
}

.banner {
  position: relative;
  //top: 170px;
  top: 5.5rem;
  width: 100%;
  border-bottom: 1px solid rgba(238, 238, 238, 0.1490196078);
  //z-index: 9999;
  padding: 15px 0;
  background: var(--primary-bg);
  //background: #201f2d;
  //margin-top: 150px;
  //margin-bottom: -50px;

  div {
    max-width: 1440px;
    margin: auto;
    div {
      display: flex;
      gap: 20px;
      &:before {
        content: '!';
        height: fit-content;
        border: solid 1px #fff;
        border-radius: 20px;
        padding: 0px 10px;
        margin-top: 10px;
      }
    }
  }

  h3 {
    font-weight: 700;
  }
  p {
    font-weight: 200;
    a {
      font-weight: 400;
    }
  }

  p,
  h3 {
    text-align: left;
  }

  @media (max-width: 575px) {
    top: 0 !important;
    height: auto;
    margin-bottom: 50px !important;
    div {
      padding: 0 20px;
      div {
        &:before {
          margin-top: 20px;
          display: none;
        }
      }
    }
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
  @media (max-width: 1025px) {
    top: 80px;
    //margin-top: 75px;
    margin-bottom: 120px;
    div {
      padding: 0 20px;
      div {
        &:before {
          margin-top: 10px;
        }
      }
    }
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
}

.backLink {
  font-size: 14px;
}
li.cmsoon {
  position: relative;
  a {
    opacity: 0.2;
  }
}

li.cmsoon:before {
  background: #9c2a6f;
  content: 'Coming Soon';
  position: absolute;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 3px;
  left: 0;
  bottom: -20px;
}

li.cmsoon:after {
  width: 10px;
  height: 10px;
  background: #9c2a6f;
  content: '';
  position: absolute;
  left: 10px;
  top: 38px;
  transform: rotate(45deg);
  z-index: -1;
  border-radius: 3px;
}

li.cmsoon:before,
li.cmsoon:after {
  display: none;
}
li.cmsoon:hover::before,
li.cmsoon:hover::after {
  display: block;
}
.mynftSideNav {
  padding: 24px 28px !important;
  .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #F6F5FF;

  }
  .external {
    border-top: solid 1px #262636;
    padding: 10px 0;
    @include mobile {
      display: none;
    }
    .link a {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--primary-color);

      padding: 10px 0;
      transition: ease-in-out 400ms;
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        display: inline-block;
        background-image: url('../assets/icons/newwindow.svg');
        background-size: cover;
        background-repeat: no-repeat;
        margin-left: auto;
      }
      &:hover {
        color: var(--primary-color);
        text-decoration: none;
        filter: brightness(150%);
      }
    }
  }
}
.mynftSideNav ul li a {
  display: flex;
  align-items: center;
}

.mynftSideNav ul li a img {
  margin-right: 12px;
}

.mynftSideNav ul li a img:nth-child(2) {
  display: none;
}

.mynftSideNav ul li a:hover img {
  display: none;
}

.mynftSideNav ul li a:hover img:nth-child(2) {
  display: block;
}

.mynftSideNav ul li.myActive a img:nth-child(2) {
  display: block;
}

.mynftSideNav ul li.myActive a img {
  display: none;
}

.mynftSideNav ul li.myActive a,
.mynftSideNav ul li a:hover {
  color: var(--primary-color);
}
.mynftSideNav {
  min-width: 272px;
  margin-right: 30px;
  //background: #1e1e2a;
  background: rgba(21, 19, 49, 1);
  padding: 15px 15px;
  border-radius: 10px;
  border: 1px solid #262636;
  position: sticky;
  top: 130px;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.nftRightChild_Standard {
  text-align: left !important;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #999CB6;

    padding: 5px 0;
  }
}

.mynftInner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__webapp {
    max-width: 600px;
    margin: auto;
    .mynftLeft {
      ul {
        display: flex;
        justify-content: space-between;
        grid-gap: 20px;
        gap: 20px;
        flex-wrap: wrap;
      }
    }
    .nftRightChild span {
      width: 100%;
    }
    .nftRightChild {
      flex-direction: column;
      span {
        padding: 15px;
      }
    }
    .mynftHub {
      width: 100%;
      margin-top: 15px;
      padding-left: 0;
    }
    .nftData label {
      float: left;
      width: 50%;
      padding-bottom: 20px;
    }
    .mynftHubHedare label {
      width: 100%;
      justify-content: center;
      margin-bottom: 5px;
      padding: 20px;
    }
    .nftButtons {
      display: flex;
      justify-content: space-between;
      a, button {
        width: 48%;
        height: 62px;
        button {
          width: 100%;
          &:last-child {
            margin-left: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  section {
    width: 100%;
  }
}

.mynftSideNav li a {
  display: block;
  //color: #919191;
  color: #787B9C;
  //padding: 10px 0px;
  padding: 20px 0;
  font-size: 14px;
  @include mobile {
    padding: 8px 0 !important;
  }
}

.mynftSideNav ul {
  margin-top: 10px;
}

.mynftSideNav li a:hover {
  color: var(--primary-color);
}
.nftFarmMain {
  .wrapper {
    max-width: 1000px;
  }
}

.nftMain {
  .wrapper {
    max-width: $--max-width;
  }
}
.logo * {
  display: block;
}
.textHubCard > section {
  position: relative;
}

.textHubCard > section b {
  position: absolute;
  background: #fd3eda;
  border: 1.5px solid #ffffff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px;
  top: 35px;
  left: 35px;
}
.taraCard {
  position: absolute;
  width: 150px;
  left: -92px;
  top: 35px;
  z-index: -1;
}

.taraCard img {
  width: 100%;
  height: 100%;
  display: block;
}

.taraCard img {
  width: 100%;
  height: 100%;
  display: block;
}
.textHub {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include tablet {
    display: flex;
  }
}

.textHubCard {
  position: inherit;
  width: 480px;
  max-width: 90%;
  //z-index: 1;
  .featured_nft {
    border: solid 11px #fff;
    border-radius: 20px;
  }
}

.textHubCard section img {
  width: 100%;
  display: block;
}

.textHubValue {
  position: absolute;
  right: 0;
  left: 0;
  width: calc(100% - 70px);
  margin: auto;
  //margin-right: 35px;
  background: white;
  bottom: -40px;
  height: 90px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.textHubValue h6 {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #141321;
  margin-bottom: 5px;
  small {
    font-size: 12px;
    color: hsl(0deg 0% 0% / 50%);
    text-align: left;
  }
}

.noFlex h6{
  display:block !important;
}

.textHubValue label p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #77768e;
  margin: 0;
}

.textHubValue label p b {
  font-weight: 600;
  font-size: 21px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #4e87f7;
  //margin-left: 5px;
  @include mobile {
    font-size: 16px;
  }
}

.textHubValue label b {
  text-align: right;
}

.textHubValue * {
  max-width: 100%;
  width: 100%;
}

.textHubValue button {
  background: linear-gradient(95.63deg, #3168f7 0%, #01cdfd 106.47%);
  border-radius: 10px;
  color: white;
  font-weight: 500;
  width: 130px;
  height: 60px;
  line-height: 40px;
  position: absolute;
  right: 17px;
  @include mobile {
    display: none;
  }
}
.setNewTrending li {
  width: calc(25% - 20px);
  background: #15142C;
  //background: #1e1e2a;
  border: 1px solid #262636;
  border-radius: 15px;
  padding-bottom: 15px;
  margin: 10px 10px;
  overflow: hidden;
  cursor: pointer;
}

.setNewTrending ul {
  display: flex;
  flex-wrap: wrap;
}

.setNewTrending li a {
  display: block;
}

.setNewTrending li a > span {
  display: block;
  margin-bottom: 15px !important;
}

.setNewTrending li span img {
  width: 100%;
  display: block;
}

.setNewTrending li p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #919191;
  justify-content: space-between;
  padding: 2px 15px;
  .reward {
    font-size: 12px !important;
  }
}

.setNewTrending li p b {
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  text-transform: capitalize;

  /* White */
  color: #ffffff;
}

.setNewTrending .title {
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.setNewTrending li p label {
  display: contents;
  align-items: center;
  color: white;
  font-size: 14px;
}

.setNewTrending li p label img {
  margin-right: 5px;
  max-width: 18px;
  border: solid 1px rgba(255, 255, 255, 0.212);
  padding: 1px;
  border-radius: 10px;
}

.setNewTrending li p label small {
  color: #919191;
  margin-left: 3px;
  font-size: 10px;
}
.setNewTrending li a span {
  margin: 0;
  padding: 0;
}
.setNewTrending {
  .collectionImage {
    width: 100%;
    height: 250px;
    overflow: hidden;

    @include mobile {
      width: 100%;
      height: 200px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .collectionProfileImage {
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .rewardInfo-wrapper {
    justify-content: initial;
    padding-top: 10px;
    img {
      margin-right: 8px;
      margin-left: 0;
      width: auto;
    }
  }
}

.collectionTrendingList.trendingInfo .colletionListingSet ul {
  flex-wrap: inherit;
  margin: 0 -6px;
}

.collectionTrendingList.trendingInfo .colletionListingSet ul span {
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  flex-direction: initial;
  justify-content: flex-start;
  align-items: center;
}

.colletionListingSet ul span img {
  margin-right: 5px;
  width: 20px;
}

.colletionListingSet ul li {
  background: #252433;
  border-radius: 15px;
  width: 18%;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #919191;
  padding: 17px 15px 0px;
  margin: 0 6px;
  b {
    font-weight: 300;
    font-size: 12px;
    margin-left: 3px;
    opacity: 0.5;
  }
}

.colletionListingSet ul li:last-child {
  width: 28%;
}

.colletionListingSet ul li:last-child li {
  padding-left: 0;
}

.colletionListingSet ul li:last-child ul li {
  padding: 0;
}

.colletionListingSet ul li:last-child ul li img {
  display: block;
  max-width: 18px;
}
.collectionTrendingList.trendingInfo .colletionListingSet ul li ul {
  position: relative;
  top: 10px;
  padding-right: 0;
}

.collectionTrendingList.trendingInfo .colletionListingSet ul li ul li {
  display: flex;
  justify-content: center;
}
.colletionListingSet {
  margin-bottom: 40px;
}
.nftexploreMain .trending.collectionListing {
  padding-top: 160px;
}

.trending.collectionListing h2.showContent {
  display: flex !important;
  align-items: flex-start;
  flex-direction: column;
}
.trending.collectionListing h2.showContent a {
  font-family: 'Lexend', sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #0998b9;
  margin-right: 20px;
  margin-bottom: 20px;
}

.trending.collectionListing h2.showContent a img {
  margin-right: 6px;
}
.newFilterJey.nftListingOptions section.newFilterJeyInner {
  max-width: 100%;
  flex-direction: row-reverse;
}

.newFilterJey.nftListingOptions section.newFilterJeyInner .newFilterJeyRight {
  display: flex;
  width: 40%;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.newFilterJey.nftListingOptions
  section.newFilterJeyInner
  .newFilterJeyRight
  section {
  width: calc(50% - 10px);
}

.newFilterJey.nftListingOptions section.newFilterJeyInner .newFilterJeyLeft {
  width: 60%;
  padding-left: 20px;
  padding-right: 0;
}

.newFilterJey.nftListingOptions > label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 15px;
  /* identical to box height, or 218% */
  text-transform: capitalize;

  /* white - text #FFFFFF */
  color: #ffffff;
}

.newFilterJey.nftListingOptions > label a {
  color: var(--primary-color);
}
.trendingFiltersRight.hideMakingNew .trendingFiltersExplore,
.trendingFiltersRight.hideMakingNew .trendingFiltersLeft {
  display: none;
}
.newFilterJeyLeft h6 button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: capitalize;

  /* MAGENTA var(--primary-color) */
  color: var(--primary-color);
}

.newFilterJeyLeft h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  font-weight: 500;
  color: white;
}
.newFilterJeyLeft {
  width: 100%;
}

section.newFilterJeyInner {
  max-width: 272px;
  margin-right: 30px;
  background: var(--primary-bg);
  //background: #1e1e2a;
  padding: 5px 15px;
  border-radius: 10px;
  border: 1px solid #262636;
  position: sticky;
  top: 130px;

  overflow: scroll;
  width: 100%;
  height: 80vh;
}

.newFilterJeyRight {
  min-width: 240px;
}

.newFilterJeyRight section {
  display: flex;
  //background: #3c3b4987;
  background: #25233e;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0;
}

.newFilterJeyRight section button {
  width: 50%;
  height: 57px;
  font-size: 15px;
  font-weight: 300;
}

.newFilterJeyRight section button.jeyActive,
.newFilterJeyRight section button:hover {
  color: white;
  font-weight: 400;
  background: #403a59 radial-gradient(circle, transparent 1%, #403a59 1%)
    center/15000% !important;
  /* background: #3b3b4a radial-gradient(circle, transparent 1%, #3b3b4a 1%)
    center/15000% !important; */
  position: relative;
  transition: background 0.8s;
}
.newFilterJeyRight section button.jeyActive,
.newFilterJeyRight section button {
  transition: background 0.8s;
  &:active {
    background-color: rgba(255, 255, 255, 0.265) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.newFilterJeyRight section button:hover:after {
  width: 100%;
}

.newFilterJeyLeft section {
  margin: 20px 0;
  position: relative;
}

.newFilterJeyLeft section input[type='text'] {
  background: #212227;
  border-radius: 10px;
  height: 57px;
  width: 100%;
  padding-left: 110px;
  font-size: 15px;
  font-weight: 300;
}

.newFilterJeyLeft section label {
  position: absolute;
  display: flex;
  align-items: center;
  left: 15px;
  font-size: 15px;
  color: #919191;
}

.newFilterJeyLeft section label img {
  margin-right: 10px;
}

.jeyDrops {
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.jeyDrops span {
  display: flex;
  background: #2d2c3a;
  border-radius: 10px;
  overflow: hidden;
  height: 57px;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  color: #919191;
  position: relative;
}

.jeyDropsValues {
  position: absolute;
  background: #121416;
  width: 100%;
  top: 60px;
  border-radius: 10px;
  z-index: 1;
  display: none;
  overflow: hidden;
}

.jeyDropsValues b {
  width: 100%;
  display: block;
  padding: 15px;
  font-size: 15px;
  color: #919191;
  font-weight: 400;
  border-bottom: 1px solid #1b1e22;
}

.jeyDropsValues b:last-child {
  border: none;
}

.jeyDropsValues b:hover {
  background: #1b1e22;
}

.jeyDrops span:before {
  width: 8px;
  height: 8px;
  content: '';
  transform: rotate(45deg);
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
  position: absolute;
  right: 15px;
  top: 24px;
}

.newFilterJeyRight section button i {
  font-style: normal;
}

.newFilterJeyRight section button:hover i,
.newFilterJeyRight section button.jeyActive i {
  border-bottom: 2px solid white;
}

.trendingFiltersRight.hideMakingNew .trendingFiltersExplore,
.trendingFiltersRight.hideMakingNew .trendingFiltersLeft {
  display: none;
}

.trendingFiltersHub .trendingFiltersRight .trendingFiltersExplore {
  max-width: 1000px;
  margin: 0 auto;
}

.trendingFiltersHub .trendingFiltersRight .trendingFiltersLeft {
  max-width: 1000px;
  margin: 0 auto 20px;
}

.homeTrending
  .trendingFiltersHub
  .trendingFiltersRight
  .trendingFiltersHomeTrending {
  display: none;
}

.homeTrending .trendingFiltersHub .newFilterJey {
  display: none;
}

.counterButton {
  clear: both;
  padding: 5px 0 0;
}

.counterButton button {
  width: 100%;
  height: 52px;
  background: #01cdfd;
  border-radius: 10px;
  color: #1b1b1b;
  margin: 10px 0;
}

.homeTrending
  .trendingFiltersHub
  .trendingFiltersRight
  .trendingFiltersHomeTrending {
  display: none;
}

header.mobileHeader {
  min-height: 76px;
  width: 100%;
  border-bottom: 5px solid rgba(238, 238, 238, 0.1490196078);
  transition: all 0.5s ease-in-out;
  background: #212326;
}
.subSearch input {
  background: #212227;
  width: 500px;
  border-radius: 10px;
  height: 47px;
  padding: 0 15px 0 50px;
  width: 100%;
}

.subSearch {
  position: relative;
  width: 500px;
}

.subSearch img {
  position: absolute;
  left: 17px;
  top: 15px;
}
.sideBarTop {
  background-image: url('/assets/images/nav_bg.jpg');
  background-size: cover;
  position: absolute;
  //top: 110px;
  top: 95px;
  width: 100%;
  left: 0;
  border-bottom: 1px solid rgba(238, 238, 238, 0.1490196078);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  ::-webkit-input-placeholder {
    /* Edge */
    color: #919191;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #919191;
  }

  ::placeholder {
    color: #919191;
  }
}
.sideBarTop .wrapper {
  max-width: $--max-width;
}
.sideBarTop ul li:last-child {
  position: absolute;
  right: 0;
  top: -4px;
}

.sideBarTop ul li:last-child input[type='text'] {
  background: $theme_dark_black_color;
  border-radius: 12px;
  width: 450px;
  height: 52px;
  padding: 8px 10px 8px 46px;
  transition: background 0.8s;
  &:focus {
    background: #2d2c3a radial-gradient(circle, transparent 1%, #2d2c3a 1%)
      center/15000% !important;
    border: 2px solid rgba(238, 238, 238, 0.1490196078) !important;
  }
  &:active {
    background-color: #46455d !important;
    background-size: 100% !important;
    transition: background 0s;
  }
  @media (max-width: 1110px) {
    width: 230px;
  }
}

.sideBarTop ul li:last-child img {
  position: absolute;
  left: 14px;
  top: 12px;
}
.sideBarTop ul {
  display: flex;
  position: relative;
  .sideBarMenu {
    display: contents;
    li:last-child {
      position: inherit !important;
    }
  }
}

.sideBarTop ul a {
  display: block;
  padding: 10px 50px 10px 0;
  color: #919191;
  font-weight: 500;
}

.sideBarTop ul li.activeMarket a,
.sideBarTop ul a:hover {
  color: var(--primary-color);
}

.expireDate p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #9bb0cf;
  margin-bottom: 10px;
  margin: 0;
}

.expireDate p img {
  margin-left: 4px;
}

.expireDate > label {
  display: flex;
  float: left;
  width: 40%;
  flex-direction: column;
  padding-right: 20px;
}

.expireDate > label > span {
  margin-top: 7px;
  width: 100%;
  height: 52px;
  //background: #262626;
  background: rgb(20 20 29);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
  color: #919191;
}

.expireDate > label > span > input,
.expireDateTiming > span > div > input {
  width: 100%;
}

.expireDate > label > span i {
  position: absolute;
  width: 9px;
  height: 9px;
  right: 15px;
  transform: rotate(45deg);
  border-right: 1px solid;
  border-bottom: 1px solid;
  opacity: 0.8;
  top: 20px;
}

.expireDateTiming {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.expireDateTiming > label {
  width: 100%;
  margin: 0;
  color: #9bb0cf;
}

.expireDateTiming > span {
  margin-top: 7px;
  width: 100%;
  display: flex;
}
section.puaction {
  position: initial;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  color: #fa6281;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
}

section.puaction p {
  margin: 0 !important;
  font-weight: 400 !important;
  color: white;
  padding-right: 4px;
  text-transform: none;
}
.expireDateTiming > span > div {
  padding-left: 10px;
  padding-right: 10px;
  height: 52px;
  //background: #262626;
  background: rgb(20 20 29) !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  color: #919191;
  margin-left: 7px;
  justify-content: center;
  width: 50%;
}

.expireDateTiming > label > img {
  margin-left: 5px;
}

p.mtop {
  margin-top: 20px;
}

.SellAuction {
  padding: 0 15px 25px;
  margin-top: -30px;
}

.SellAuction h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 30px;
}
// {padding-left: 250px;position: relative;}

//.aucHub > section {position: absolute;left: 0;width: 220px;top: 0;}

.AucInput h5,
.TransferInput h5 {
  font-size: 24px;
  font-weight: bold;
  color: white;
  //margin-bottom: 25px;
}

.AucInput label label {
  margin: 0;
  font-size: 12px;
  color: #bbb;
  font-weight: normal;
}

.AucInput label label span {
  color: #01cdfd;
  margin-left: 3px;
}

.AucInput label label span i {
  color: antiquewhite;
  font-style: normal;
}
.SellAuction h6 b {
  color: white;
  margin-left: 5px;
}
.aucHub {
  //padding-left: 250px;
  //position: relative;
  display: flex;
  justify-content: space-between;
  .auc_split {
    width: 100%;
    padding-left: 25px;
    margin: auto;
  }
  @include mobile {
    display: block;
  }
}
.aucHub > section {
  //position: absolute;
  //left: 0;
  width: 350px;
  //top: 0;
  @include mobile {
    width: 100%;
    margin: auto;
  }
}
.SellAuction section span {
  padding: 15px;
  background: #1313136e;
  border-radius: 4px;
  min-height: 203px;
  margin-top: 8px;
  display: block;
}

.SellAuction section span img {
  max-width: 100%;
  display: block;
}

.AucInput {
  position: relative;
}
.selectWithIcon{
  display: flex;
  flex-direction: row;
  align-content: center;
  font-size: 13px !important;
  img{
    margin-right: 3px;
  }
}
.css-1e48vrb-singleValue {
  overflow: auto !important;
}
.stylesell_dropdown{
  display: flex;
  flex: 1;
}
.stylesell{
  display: flex;
  flex-direction: row;

  .stylesellInput{
    display: flex;
    flex-direction: row;
    flex: 3;

    img{
      height: 40px;
      width: 40px;
    }
  }
}
.AucInput.auctionSet label {
  justify-content: flex-start;
}
.AucInput.auctionSet label {
  justify-content: flex-start;
}

.expireDateTiming > span > div:first-child {
  margin-left: 0;
}

.expireDate {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}
.AucInput label,
.TransferInput label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #9bb0cf;
  margin-bottom: 10px;
  justify-content: space-between;
}
.SellAuction .aucHub button.cnfrmTransfer {
  margin-top: 30px;
}
.AucInput input {
  //background: #2d2d2d;
  background: rgb(0 0 0 / 55%);
  opacity: 0.6;
  border-radius: 5px;
  height: 56px;
  width: 100%;
  padding: 0 60px 0 10px;
}

.AucInput .invalid {
  border: 1px solid red;
}

.TransferInput input {
  //background: #2d2d2d;
  background: rgb(20 20 29);
  opacity: 0.6;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding: 0 20px 0 20px;
}

.AucInput > img {
  position: absolute;
  left: 15px;
  bottom: 16px;
}

.AucInput b {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* gray - text #919191 */
  color: #919191;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.SellAuction p {
  margin-top: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  color: #464646;
}

.SellAuction p b {
  font-weight: normal;
  color: #bbbbbb;
  margin-right: 5px;
}

.SellAuction p b i {
  font-style: normal;
  color: white;
}

.SellAuction p b:last-child {
  margin-left: 5px;
}

.SellAuction button {
  background: var(--primary-color);
  border-radius: 10px;
  height: 52px;
  width: 100%;
  color: black;
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
  transition: background 0.8s;
  &:hover {
    background: var(--primary-color) !important;
    opacity: 0.8;
  }
  &:active {
    background-color: var(--primary-color) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.AucInput label img {
  margin-left: 3px;
}
.detailTrendingLeft > img {
  min-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #212128;
}

.detailTrending .detailTrendingLeft section {
  padding: 20px 0;
}

.detailTrendingLeft ul li div {
  display: block;
  height: 6px;
  background: #2c3336;
  border-radius: 100px;
  margin-top: 10px;
  margin-bottom: 15px;
  position: relative;
}

.detailTrendingLeft ul li div span {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 10%;
  background: #01CDFD;
  border-radius: 100px;
}
.detailTrendingLeft ul li {
  color: #bbbbbb;
  font-size: 14px;
  padding: 10px 0;
  font-weight: 500;
  &:last-child {
    margin-bottom: 35px;
  }
}

.detailTrendingLeft ul li b {
  color: #01cdfd;
  font-weight: normal;
}

.detailTrendingLeft section {
  padding: 20px 0;
}

.detailTrendingLeft h5 {
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;

  /* white - text #FFFFFF */
  color: #ffffff;
  margin-bottom: 5px;
  margin-top: 10px;
}

.detailStakeSell {
  padding: 0px 0 20px;
  border-bottom: 1px solid #333333;
}

.detailStakeSell section {
  display: flex;
  justify-content: space-between;
  background: #16161b;
  border: 1px solid #26262c91;
  border-radius: 8px;
  padding: 20px;
}

.detailStakeSellLeft {
  width: calc(50% - 20px);
}

.detailStakeSellRight {
  width: calc(50% + 20px);
  border-left: 1px solid #29292e;
  padding-left: 20px;
  padding-bottom: 80px;
}

.detailStakeSellLeft p {
  color: #bbbbbb;
  font-size: 17px;
  padding: 7px 0;
  font-weight: 300;
}

.detailStakeSellLeft p b {
  color: #01cdfd;
  font-weight: normal;
}

.detailStakeSellLeft h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: white;
  margin-bottom: 10px;
}

.detailStakeSellRight h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: white;
  margin-bottom: 10px;
}

.detailStakeSellRight p {
  color: #bbbbbb;
  font-size: 17px;
  padding: 7px 0;
  font-weight: 300;
}

.detailStakeSellRight p b {
  font-weight: normal;
  color: white;
}
.detailStakeSellButtons .unstake {
  background: var(--primary-color) !important;
  &:hover {
    background: var(--primary-color) !important;
    opacity: 0.8;
  }
  &:active {
    background-color: var(--primary-color) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}
.detailStakeSellButtons button {
  height: 53px;
  background: var(--primary-color);
  border-radius: 10px;
  width: calc(100% - 10px);
  margin: 10px 0px;
  color: #1b1b1b;
  font-weight: 700;
  font-size: 18px;
  transition: background 0.8s;
  &:disabled {
    background: #4c4c4c81 radial-gradient(circle, transparent 1%, #4c4c4c81 1%);
    &:hover {
      background: #4c4c4c81 radial-gradient(circle, transparent 1%, #4c4c4c81 1%)
        center/15000% !important;
    }
  }
  &:hover {
    background: var(--primary-color) !important;
    opacity: 0.8;
  }
  &:active {
    background-color: var(--primary-color) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.detailStakeSellButtons {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  span {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
}

.detailStakeSellButtons button img {
  position: relative;
  top: 5px;
}

.detailStakeSellButtons .stake {
  background: #32fe9a radial-gradient(circle, transparent 1%, #32fe9a 1%)
    center/15000%;
  transition: background 0.8s;
  &:hover {
    background: #32fe9b87 radial-gradient(circle, transparent 1%, #32fe9b87 1%)
      center/15000% !important;
  }
  &:active {
    background-color: #32fe9b87 !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}
.SellAuction .aucHub p {
  margin-top: 15px;
}

.SellAuction .aucHub button {
  margin-top: 15px;
}
//=======
.handleDivide.currency {
  position: relative;
}
.headerDisconnectTop span p {
  position: relative;
  width: 100%;
  text-align: left;
  cursor: pointer !important;
}

.headerDisconnectTop span p img {
  position: absolute;
  right: 0;
  top: 5px;
  width: auto;
  cursor: pointer !important;
  margin: 0;
}
.navbar {
  .navbar_dropdown:hover {
    cursor: pointer !important;
  }
  .navbar_dropdown:hover .navbar_dropdown_content {
    display: grid !important;
    cursor: pointer !important;
  }
  .navbar_dropdown_content {
    display: none;
    position: absolute;
    padding: 10px 20px;
    transition: all 0.5s ease;
    background: #141321;
    border: 1px solid #373643;
    border-radius: 10px;
    font-size: 14px;
    min-width: 200px;
    list-style: none;
    text-align: left;
    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      justify-content: flex-start !important;
    }
    li:first-child {
      display: block !important;
    }
  }
}

.navbar .menu .headerDisconnect section > a,
.headerDisconnect section > a:hover {
  justify-content: flex-start !important;
  font-size: 14px;
  padding-left: 0 !important;
  padding-top: 0 !important;
  color: white !important;
}
.headerDisconnect {
  position: absolute;
  right: 0;
  margin-top: 4px;
  padding: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  width: 352px;
  height: 169px;
  display: none;
  background: #090817;
  border: 1px solid #373643;
  border-radius: 10px;

  .wallet-image {
    &:hover {
      filter: invert(37%) sepia(66%) saturate(902%) hue-rotate(277deg)
        brightness(86%) contrast(98%);
    }
  }
}
.headerDisconnectTop span div {
  width: 38px;
  height: 38px;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 10px;
}

.headerDisconnectTop span p b {
  display: block;
  text-align: left;
  font-size: 12px;
  color: #e6dbdb;
  font-weight: normal;
}
.headerDisconnectTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
  @include mobile {
    display: inline-flex !important;
  }
}

.headerDisconnectTop span {
  display: flex;
  align-items: center;
  color: white;
  div {
    @include mobile {
      display: none !important;
    }
  }
  p {
    @include mobile {
      display: none;
    }
  }
  img {
    @include mobile {
      right: -20px !important;
    }
  }
}

.headerDisconnect img {
  max-width: 100%;
  display: block;
  margin-right: 7px;
}

.headerDisconnectTop span img {
  margin-right: 10px;
  width: 100%;
}

.handleDivide .headerDisconnectTop button {
  padding: 0 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
  //width: 100%;
  height: 32px;
  background: #fa628124;
  border-radius: 5px !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #fa6281;
  margin-top: 15px;
  text-align: center !important;
  @include mobile {
    display: block !important;
  }
}

.headerDisconnectBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerDisconnectBottom a {
  padding: 0 !important;
}

.headerDisconnectBottom a img {
  width: 14px;
}

.headerDisconnectBottom a {
  color: #1eb2ff !important;
  font-size: 12px;
}

.headerDisconnect section .headerDisconnectBottom + button {
  border-radius: 5.2px;
  font-size: 13px;
  height: 26px;
  padding: 0 10px;
  min-width: 74px;
  border-color: #47d7e2;
  color: #47d7e2;
  background: no-repeat;
  border: 1px solid;
  width: 100%;
  margin-top: 15px;
}

.mfnftMain {
  //padding: 255px 0 150px;
  padding: 175px 0 150px;
  // background: #111117;
  @include tablet {
    padding: 0;
  }
  @include mobile {
    padding: 0;
  }
}

.mynftHub {
  width: calc(100% - 223px);
  padding-left: 20px;
}

.mynftHubHedare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.mynftHubHedare label div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.mynftHubHedare label img {
  width: 37px;
  margin-right: 10px;
}

.nftData {
  display: flex;
  flex-wrap: wrap;
  &__webapp {
    label {
      width: 50% !important;
    }
    .full {
      width: 100% !important;
      padding-bottom: 0;
    }
  }
}

.nftData label p.nftGreen {
  color: #32fe9a;
}

.nftData label p.nftSky {
  color: #9bb0cf;
}

.nftButtons {
  display: flex;

  button.stakeNft {
    background-color: #32fe9a;
  }
}

.nftButtons button {
  background: var(--primary-color);
  border-radius: 8px;
  text-align: center;
  text-transform: capitalize;
  color: #1b1b1b;
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  font-weight: 600;
  transition: background 0.8s;
  &:hover {
    background: var(--primary-color) !important;
  }
  &:active {
    background-color: var(--primary-color) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.nftButtons button img {
  margin-right: 10px;
}

.nftButtons button:last-child {
  margin-left: 10px;
  width: 170px;
}

.graphingNft {
  margin: 35px 0 40px;
}

.graphingNft img {
  max-width: 100%;
}

.otherNft h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-transform: capitalize;
  color: #9bb0cf;
  margin-bottom: 15px;
}

.otherNft ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.otherNft ul li {
  width: calc(25% - 30px);
  background: #1b1b1b;
  border-radius: 20px;
  margin: 0 15px 30px;
}

.nfImageHolder img {
  display: block;
  width: 100%;
  height: auto !important;
}

.otherNft ul li a span {
  float: left;
  width: 100%;
  padding: 15px;
}

.otherNft ul li label {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
}

.otherNft ul li label b {
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.otherNft ul li p {
  justify-content: space-between;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.5);
}

.otherNft ul li p i {
  font-size: 14px;
  color: #fff;
  font-style: normal;
  margin-left: 4px;
  margin-top: -1px;
}

.otherNft ul li label:first-child {
  margin-bottom: 5px;
}

.otherNft ul li label {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
}

.otherNft ul li p {
  justify-content: space-between;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.5);
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}

.waitApprovalHub {
  padding: 40px 0;
}

.approval2,
.approval3,
.approval4 {
  display: none !important;
}

.waitApprovalPopup label {
  white-space: nowrap;
  background: #212326;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 50px;
  color: #01cdfd;
}

.waitApprovalPopup label b {
  color: white;
  font-weight: normal;
}

.waitApprovalPopup button.mintNow {
  background: #32fe9a;
}

.waitApprovalPopup label + .buttonMints button {
  margin-top: 50px;
  background: var(--primary-color);
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  padding: 14px;
  width: 154px;
}

.buttonMints {
  display: flex;
}

.buttonMints button:first-child {
  margin-right: 10px;
}

.waitApprovalPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 410px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.waitApprovalPopup img.rotating {
  display: block;
}

.waitApprovalPopup h6 {
  margin: 50px 0 15px;
  font-family: 'shapiro65_light_heavy_wide';
  font-style: normal;
  font-weight: 286;
  font-size: 32px;
  line-height: 49px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;

  /* white - text #FFFFFF */
  color: #ffffff;
}

.waitApprovalPopup p {
  padding: 0 20px;
}

.waitApprovalPopup button {
  margin-top: 100px;
  background: #d45663;
  border-radius: 10px;
  width: 150px;
  height: 52px;
  color: #000;
}

.mynft .wrapper {
  max-width: $--max-width;
}

.mynftInner h3 {
  text-align: left;
  color: white;
  margin: 0;
  padding: 40px 0;
  font-family: 'shapiro65_light_heavy_wide';
  font-size: 32px;
  line-height: 30px;
  font-weight: normal;
}

// .mynftInner section {
//   display: flex;
//   justify-content: space-between;
// }

.mynftLeft {
  width: 100%;
  margin-bottom: 30px;
}

.mynftRight {
  width: 100%;
}

.mynftLeft h6 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;

  /* White */
  color: #ffffff;
  margin-bottom: 15px;
}
.mynftLeft ul {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.mynftLeft ul li {
  //background: #1e1e2a;
  background: rgba(21, 19, 49, 1);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #262636;
  border-radius: 15px;
  //width: calc(33.33% - 15px);
  width: 100%;
}

.mynftLeft ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 107px;
  padding: 0 30px;
}

.mynftLeft ul li > img {
  width: 50px;
  object-fit: none;
}

.mynftRight .header {
  display: flex;
  justify-content:space-between;
  margin-bottom: 15px;
}

.mynftRight .header h6 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ffffff;
}

.mynftRight .header .claim {
  border: 2px solid #c83e94;
  border-radius: 8px;
  padding: 8px;
  color: #c83e94;
}

.mynftLeft ul li b {
  font-family: Lexend;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;

  /* blue - text #9BB0CF */
  color: #9bb0cf;
}

.mynftLeft ul li b img {
  margin-left: 3px;
}

.mynftLeft ul li p {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;

  /* CYAN #01CDFD */
  color: white;
}

.mynftLeft ul li p i {
  font-style: normal;
  font-weight: normal;
  color: #9bb0cf;
  margin-left: 5px;
}

.nftRightChild {
  //background: #1e1e2a;
  background: #262636;
  border: 1px solid #262636;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;

  &__webapp {
    background-image: url('/assets/images/ticket_card.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.nftRightChild span {
  width: 223px;
  padding: 10px;
  background: #13131387;
  border-radius: 6px;
}

.nftRightChild span img {
  display: block;
  width: 100%;
}

.mynftHub {
  width: calc(100% - 223px);
  padding-left: 20px;
}

.mynftHubHedare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.mynftHubHedare label {
  background: #13131387;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ffffff;
}

.mynftHubHedare label img {
  width: 37px;
  margin-right: 10px;
}

.nftData label p.nftSky {
  color: #9bb0cf;
}

.nftButtons {
  display: flex;

  b {
    display: flex;
    align-items: center;
  }

  button.stakeNft {
    background-color: #32fe9a;
  }
}

.nftButtons button {
  background: #32fe9a radial-gradient(circle, transparent 1%, #32fe9a 1%)
    center/15000% !important;
  border-radius: 8px;
  text-align: center;
  text-transform: capitalize;
  color: #1b1b1b;
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  font-weight: 600;
  transition: background 0.8s;
  &:disabled {
    background: #4c4c4c81 radial-gradient(circle, transparent 1%, #4c4c4c81 1%) !important;
    &:hover {
      background: #4c4c4c81 radial-gradient(circle, transparent 1%, #4c4c4c81 1%)
        center/15000% !important;
    }
  }
  &:hover {
    background: #32fe9b87 radial-gradient(circle, transparent 1%, #32fe9b87 1%)
      center/15000% !important;
  }
  &:active {
    background-color: #32fe9b87 !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.nftButtons a button {
  background: var(--primary-color) radial-gradient(circle, transparent 1%, var(--primary-color) 1%)
    center/15000% !important;
  border-radius: 8px;
  text-align: center;
  text-transform: capitalize;
  color: #1b1b1b;
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  font-weight: 600;
  transition: background 0.8s;
  &:hover {
    background: var(--primary-color) !important;
    opacity: 0.8;
  }
  &:active {
    background-color: var(--primary-color) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.nftButtons button img {
  margin-right: 10px;
}

.nftButtons button:last-child {
  margin-left: 10px;
  @include mobile {width: 100%;}
}

.nftData label b {
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;

  /* blue - text #9BB0CF */
  color: #9bb0cf;
  font-weight: normal;
  margin-bottom: 5px;
}

.nftData label b img {
  margin-left: 4px;
}

.nftData label p {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: white;
}

.nftData label p i {
  font-style: normal;
  color: #9bb0cf;
  margin-left: 5px;
  //font-weight: normal;
  font-weight: 400;
}

.nftData label {
  float: left;
  width: 33%;
  padding-bottom: 20px;
}

.exploreCollections {
  padding: 0 15px 70px;
  width: 100%;
  max-width: $--max-width;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  @include tablet {
    display: block;
  }
}

.newFilterJey.collectionHub {
  //margin-top: 185px;
  margin-top: 100px;
  padding: 0 15px;
}

.headerText h3.exploreHeading {
  text-align: left;
  font-size: 32px;
  line-height: 30px;
  font-weight: normal;
  padding: 60px 15px 55px;
  width: 100%;
  max-width: $--max-width;
  margin: 0 auto;
  @include mobile {
    padding: 15px 25px;
  }
}

.exploreList ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.exploreList ul li {
  //background: #1e1e2a;
  //border: 1px solid #2e2e2e;
  background: #15142C;
  border: 1px solid #262636;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  width: calc(50% - 20px);
  margin: 0px 10px 20px;
  transition: all ease-in-out 0.2s;
  &:hover {
    background: #403a59;
  }
}

.exploreList ul li a {
  float: left;
  width: 100%;
}

.exploreList ul li a img {
  width: 100%;
  display: block;
}

.exploreList ul li span {
  margin: -35px auto 20px;
  display: block;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  color: #ffffff;
}

.exploreList ul li span div.newColl {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px;
  //background: #141321;
  background: #25233e;
  border-radius: 10px;
  padding: 13px;
}

.exploreList ul li span div.newColl label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: #0998b9;
}

.exploreList ul li span div.newColl label b {
  margin: 0 7px 0 0;
}

.exploreList ul li span div {
  margin-bottom: 15px;
}

.exploreList ul li span img {
  width: 58px;
  margin: 0 auto;
}

.exploreList ul li b {
  display: block;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  margin-top: 15px;
}

.exploreList ul li span p {
  color: #8d8d8d;
  font-weight: normal;
  font-size: 14px;
  margin-top: 5px;
  max-width: 326px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

span.optionSet.toggleust {
  display: block;
}

.toggleust {
  display: block;
}

.exploreUST {
  position: relative;
}

.exploreUSTInner {
  width: 67px;
  background: #313336;
  max-height: 52px;
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.exploreUSTInner img {
  margin: 0;
}

.trendingFiltersNewHub {
  display: flex;
  width: calc(100% - 380px);
}

.trendingNewMenu {
  display: none;
  position: sticky;
  top: 160px;
  background: #1b1b1b;
  width: 193px;
  border-radius: 15px;

  li.trendingActiveMenu {
    a {
      color: var(--primary-color);

      img:first-child {
        display: none;
      }

      img {
        display: block;
      }
    }
  }
}

// .trending .trendingFiltersHub .trendingNewMenu {
//   display: none;
// }
.trendingNewMenu ul:first-child {
  border-bottom: 1px solid #2d2d2d;
}

.trendingNewMenu ul {
  padding: 17px 0;
}

.trendingNewMenu ul li {
  a span {
    text-align: center;
    width: 55px;
  }
}

.trendingNewMenu ul li a {
  display: flex;
  align-items: center;
  color: #919191;
  font-size: 14px;
  width: 100%;
  padding: 10px 15px;
  padding-left: 10px;
  cursor: pointer;
}

.trendingNewMenu ul li a img {
  display: none;
  margin: 0 auto;
}

.trendingNewMenu ul li a img:first-child {
  display: block;
}

.trendingNewMenu ul li a:hover img:first-child {
  display: none;
}

.trendingNewMenu ul li a:hover img {
  display: block;
}

.trendingNewMenu ul li a:hover {
  color: var(--primary-color);
}

.whitelistTopper {
  margin-bottom: 130px;
  padding-top: 190px;
}

.whitelistWrapper {
  width: 100%;
  max-width: $--max-width;
  padding: 0 15px;
  margin: 0 auto;
}

.whitelistHub {
  display: flex;
  justify-content: space-between;
}

.whitelistLeft {
  width: 50%;
  padding-right: 100px;
}

.whitelistLeft .whitelistLive b,
.whitelistLeft .whitelistNotimer b {
  color: #01cdfd;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.13em;
  display: block;
}

.whitelistLeft h5 {
  color: white;
  font-weight: 500;
  font-family: 'shapiro65_light_heavy_wide';
  font-size: 56px;
}

.whitelistLeft p {
  color: #919191;
  font-size: 18px;
}

.whitelistLeft button {
  width: 100%;
  max-width: 410px;
  background: #01cdfd;
  border: 1px solid #01cdfd;
  border-radius: 10px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 500;
}

.whitelistLeft button img {
  margin-right: 5px;
}

.whitelistCosting {
  float: left;
  width: 100%;
  margin: 15px 0;
  max-width: 410px;
}

.whitelistCosting label {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  font-size: 21px;
  color: #8d8d8d;

  b {
    font-size: 21px !important;
    color: #666666 !important;
    letter-spacing: inherit !important;
  }
}

.whitelistAdder {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 410px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.whitelistAdder p {
  font-size: 21px;
  font-weight: 500;
  color: #8d8d8d;
}

.whitelistAdder ul {
  display: flex;
}

.whitelistAdder ul li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
}

.whitelistAdder ul li:first-child:hover,
.whitelistAdder ul li:last-child:hover {
  background-color: #525458;
}

.whitelistAdder ul li:first-child {
  border-radius: 10px 0 0 10px;
  background: #313336;
  height: 52px;
  width: 52px;
}

.whitelistAdder ul li:last-child {
  background: #313336;
  border-radius: 0 10px 10px 0;
  height: 52px;
  width: 52px;
}

.whitelistAdder ul li img {
  margin: 0;
}

.whitelistAdder ul li input {
  width: 100%;
  text-align: center;
  height: 100%;
  font-size: 21px;
}

.whitelistBar {
  float: left;
  width: 100%;
  height: 6px;
  background: #3b3a3a;
  border-radius: 2px;
  position: relative;
  margin: 5px 0 15px;

  div {
    position: absolute;
    width: 40%;
    height: 100%;
    background: #32fe9a;
    border-radius: 2px;
    z-index: 1;
  }
}

.whitelistCosting label span {
  color: white;
  display: flex;
  align-items: center;
}

.whitelistTiming {
  background: #1b1b1b;
  border: 1px solid #2e2e2e;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  float: left;
  width: 100%;
  max-width: 410px;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 20px;
}

.whitelistTiming p {
  text-align: center;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 5px;
}

.whitelistTiming ul {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  font-size: 25px;
  color: #4e4e4e;
  font-weight: 900;
}

.whitelistTiming ul li {
  text-align: center;
  font-weight: 900;
  font-size: 28px;
  color: white;
}

.whitelistTiming ul li b {
  font-size: 12px !important;
  color: #8d8d8d !important;
  letter-spacing: inherit !important;
}

.whitelistRight {
  width: 50%;
}

.whitelistRight ul {
  display: flex;
  justify-content: space-between;
}

.whitelistRight ul li {
  width: 33.33%;
  padding: 0 6px;
}

.whitelistRight ul li img {
  width: 100%;
  display: block;
}

.whitelistReward {
  margin-bottom: 300px;
}

.whitelistRewardSection {
  margin: 0;
}

.whitelistReward h6 {
  font-size: 32px;
  text-align: center;
  font-weight: 500;
  color: white;
  font-family: 'shapiro65_light_heavy_wide';
  margin-bottom: 40px;
}

.whitelistReward h6 img {
  display: block;
  margin: 0 auto;
}

.whitelistReward table {
  background: #1b1b1b;
  border-radius: 15px;
  font-size: 14px;
  color: white;
  font-weight: 400;
  text-align: center;
}

.whitelistReward table tr:nth-child(odd) {
  background: #2123264d;
}

.whitelistReward table tr th {
  border-bottom: 1px solid #2d2d2d;
  background-color: #1b1b1b;
  padding: 18px 0;
  color: #919191;
  font-weight: 400;
  text-align: center;
}

.whitelistReward table tr td {
  padding: 20px 0;
  font-weight: 300;
}

.whitelistReward table tr td img {
  vertical-align: middle;
  margin-right: 5px;
}

.whitelistReward table tr td b {
  font-weight: 400;
  color: #919191;
}

.tiersRed {
  color: #ff5555;
}

.tiersYellow {
  color: #fac666;
}

.tiersGreen {
  color: #32fe9a;
}

.hidenow {
  display: none;
}

.bothbtn.bondBtn {
  button {
    max-width: calc(50% - 7px);
  }
}

.launchSection {
  padding: 0 0px 100px;
  // background: #121117;
  position: relative;
}

.launchSectionBanner {
  position: relative;

  img {
    width: 100%;
    max-height: 1100px;
    object-fit: cover;
    min-height: 450px;
  }
}

.promotedSectionInner .launchlist h6 {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  display: block;
  border-bottom: 1px solid #3b3a3a;
  padding: 13px 0 18px;
  margin-bottom: 22px;
}

.launchSectionText {
  position: absolute;
  top: 220px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0px);
  max-width: $--max-width;
  padding: 0 15px;

  div {
    max-width: 650px;
  }
}

.launchSectionText h5 {
  font-size: 56px;
  font-family: 'shapiro65_light_heavy_wide';
  color: white;
  font-weight: 300;
  line-height: 60px;
}

.launchSectionText p {
  font-size: 18px;
  padding: 25px 0;
}

.launchSectionText span {
  display: flex;
}

.launchSectionText span button {
  background: #01cdfd;
  border: 1px solid #01cdfd;
  border-radius: 10px;
  color: #1b1b1b;
  height: 52px;
  width: 200px;
  font-weight: 500;
  font-size: 18px;
}

.launchSectionText span button:last-child {
  background: transparent;
  color: #01cdfd;
  margin-left: 15px;

  a {
    background: transparent;
    color: #01cdfd;
    margin: 0 auto;
  }
}

.launchLiveProject {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 100px;
  margin-bottom: 250px !important;
  position: relative;
  z-index: 0;

  &__no_margin {
    margin-top: 0px !important;
  }

  @include mobile {
    margin-top: 20px;
    margin-bottom: 20px;
    &__no_margin {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
  }

  .dot {
    width: 13px;
    height: 13px;
    background-color: #fd3eda;
    margin-right: 20px;
    margin-left: 10px;
    border-radius: 20px;
    box-shadow: 0 0 0px 4px #141321, 0 0 0px 10px #81207094;
  }

  h6 {
    font-family: 'shapiro65_light_heavy_wide';
    position: absolute;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.launchUcomingProject {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 100px;
  position: relative;
  z-index: 0;
}

.launchUcomingProject h5, .launchUcomingProject h6 {
  font-family: 'shapiro65_light_heavy_wide';
  font-size: 32px;
  font-style: normal !important;
  text-transform: none !important;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0em;
  color: white;
}

.launchLiveSliderInner {
  width: 33.33%;
  //padding-bottom: 15px;
  //background: #1e1e2a;
  background: var(--primary-bg);
  border: 1px solid var(--border-color);
  border-left: none;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 0;
  position: relative;

  @include mobile {
    width: 100%;
    border-radius: 0 0 10px 10px;
  }

  span {
    img {
      border-radius: 10px;
    }
  }

  .daynamicSlider {
    width: 33.33%;
    display: flex;
    border: none;
    margin: 0;

    div {
      width: 33.33%;
      border: none;
      margin: 0;
    }

    img {
      width: 100%;
    }
  }
}

.launchUcomingSliderInner {
  padding-bottom: 15px;
  //background: #1e1e2a;
  background: var(--primary-bg);
  border: 1px solid rgba(56, 54, 78, 0.6);
  //border: 2px solid #262636;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;

  span {
    img {
      border-radius: 10px;
    }
  }

  .daynamicSlider {
    display: flex;
    border: none;
    margin: 0;

    div {
      width: 33.33%;
      border: none;
      margin: 0;
    }

    img {
      width: 100%;
    }
  }
  transition: ease-in-out 0.2s;
  &:hover {
    //border: solid 2px var(--primary-color);
    transform: scale(0.99);
  }
}

.daynamicSliderSocial {
  max-width: calc(100% - 30px);
  margin: 0 auto;
}

.launchLiveSlider {
  display: flex;
  //margin-top: 80px;

  @include mobile {
    display: block;
    margin-top: 80px;
  }

  .sliderBlock {
    width: 66.66%;
    background: var(--primary-bg);
    border-radius: 15px 0 0 15px;
    border: 1px solid var(--border-color) !important;
    border-right: none !important;
    overflow: hidden;
    //padding: 5px;
    @include mobile {
      width: 100%;
      border-radius: 15px 15px 0 0;
      border: 1px !important;
    }
    .daynamicSlider {
      display: flex;
      border: none;
      margin: 0;

      div {
        width: 33.33%;
        border: none;
        margin: 0;
      }

      img {
        width: 100%;
        //flex-shrink: 0;
        //min-width: 100%;
        min-height: 100%;
        border-radius: 0 !important;
        @include mobile {
          border-radius: 10px 10px 0 0 !important;
        }
      }
    }
  }

  .status {
    font-size: 14px;
    color: #fff;
    background: #fd3eda;
    border: 1.5px solid #ffffff;
    border-radius: 4px;
    padding: 4px 16px;
    position: absolute;
    right: 5%;
    margin-left: -29px;
    margin-top: 13px;
  }

  span {
    position: absolute;
    left: 50%;
    margin-left: -29px;
    margin-top: 63px;
    img {
      margin: 0;
    }
  }

  h6 {
    padding: 0 15px;
    font-size: 21px;
    text-align: center;
    margin-top: 130px;
    margin-bottom: 0;
    font-family: Lexend;
    font-weight: 700;
    position: initial;
  }

  p {
    padding: 0 15px;
    font-size: 14px;
    text-align: center;
    color: #8d8d8d;
  }

  .social {
    text-align: center;
    border-top: 1px solid #2e2e2e;
    //margin: 25px 35px;
    margin: 20px 35px;
    a {
      padding: 0 10px;
      position: relative;
      top: -12px;
      background: #1e1e2a;
    }
  }

  .details {
    padding: 10px 20px;
    label {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #8d8d8d;
      padding: 5px 15px;

      b {
        font-weight: 400;
        color: white;
      }
    }
  }
}

.launchUcomingSlider {
  margin-top: 80px;

  span {
    position: absolute;
    left: 50%;
    margin-left: -29px;
    margin-top: -33px;
    img {
      margin: 0;
    }
  }

  h6 {
    padding: 0 15px;
    font-size: 21px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0;
    font-family: Lexend;
    font-weight: 700;
    position: initial;
  }

  p {
    padding: 0 15px;
    font-size: 14px;
    text-align: center;
    color: #8d8d8d;
  }

  div {
    text-align: center;
    border-top: 1px solid #2e2e2e;
    margin-top: 25px;
  }
}
.liveProjectSlider{
  margin-top: 10px;

  span {
    position: absolute;
    left: 10% !important;
    margin-left: -29px;
    margin-top: -43px !important;
  }

  span img {
    margin: 0;
  }

  h6 {
    padding: 0 15px;
    font-size: 21px;
    text-align: left !important;
    margin-top: 20px !important;
    margin-bottom: 0;
    font-family: Lexend;
    font-weight: 700;
    position: initial;
    justify-content: unset !important;
    line-height: 35px !important;
  }

   p {
    padding: 0 15px;
    font-size: 14px;
    text-align: left !important;
     margin-top: 0 !important;
    color: #8d8d8d;
  }

  .divider {
    margin: 17px 20px;
    text-align: center;
    border-top: 1px solid #2e2e2e;
    margin-top: 25px;
    margin-bottom: 17px;
  }

  div {
    text-align: center;
    border-top: 1px solid #2e2e2e;
    margin-top: 25px;
  }

  div a {
    padding: 0 10px;
    position: relative;
    top: -12px;
    background: #1e1e2a;
  }

  div a img {
    margin: 0;
  }

  label {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #8d8d8d;
    padding: 5px 15px;
  }

  label b {
    font-weight: normal;
    color: white;
  }
}
.hotCollections div {
  border-top:  transparent !important;
}
.hotCollections label {
  padding: 3px 0 !important;
}
.launchUcomingSlider span {
  position: absolute;
  left: 50%;
  margin-left: -29px;
  margin-top: -33px;
}

.launchUcomingSlider span img {
  margin: 0;
}

.launchUcomingSlider h6 {
  padding: 0 15px;
  font-size: 21px;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 0;
  font-family: Lexend;
  font-weight: 700;
  position: initial;
}

.launchUcomingSlider p {
  padding: 0 15px;
  font-size: 14px;
  text-align: center;
  color: #8d8d8d;
}

.launchUcomingSlider .divider {
  margin: 17px 20px;
  text-align: center;
  border-top: 1px solid #2e2e2e;
  margin-top: 25px;
  margin-bottom: 17px;
}

.launchUcomingSlider div {
  text-align: center;
  border-top: 1px solid #2e2e2e;
  margin-top: 25px;
}

.launchUcomingSlider div a {
  padding: 0 10px;
  position: relative;
  top: -12px;
  background: #1e1e2a;
}

.launchUcomingSlider div a img {
  margin: 0;
}

.launchUcomingSlider label {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  //color: #8d8d8d;
  color: var(--primary-color);
  padding: 5px 15px;
}

.launchUcomingSlider label b {
  font-weight: normal;
  color: white;
}

.launchLoader {
  height: 6px;
  display: block;
  background: #3b3a3a;
  margin-top: 20px;
  border-radius: 2px;
  position: relative;
  margin-bottom: 20px;
}

.launchLoader {
  &__100 div {
    width: 100% !important;
  }
  &__30 div {
    width: 30% !important;
  }
  div {
    border-radius: 2px;
    background: #32fe9a;
    position: absolute;
    height: 100%;
    width: 80%;
    top: 0;
    left: 0;
  }
}

.pricingCompleted {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 5px 0;
}

.pricingCompleted b {
  font-weight: 400;
  color: #8d8d8d;
}

.swiper-button-next {
  background-image: url('/assets/images/sarrow.png');
  background-repeat: no-repeat;
  background-position: center;
  top: 30px !important;
}

.swiper-button-prev {
  background-image: url('/assets/images/sarrow.png');
  background-repeat: no-repeat;
  background-position: center;
  right: 50px !important;
  left: auto !important;
  top: 30px !important;
  transform: rotate(180deg);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  opacity: 0;
}

.graphingNft img {
  max-width: 100%;
}

.otherNft ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.otherNft ul li {
  width: calc(25% - 30px);
  background: #1b1b1b;
  border-radius: 20px;
  margin: 0 15px 30px;
}

.nfImageHolder img {
  display: block;
  min-width: 100%;
  height: auto !important;
}

.otherNft ul li a span {
  float: left;
  width: 100%;
  padding: 15px;
}

.otherNft ul li label {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
}

.otherNft ul li label b {
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.otherNft il li p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.otherNft ul li p i {
  font-size: 14px;
  color: white;
  font-style: normal;
  margin-left: 4px;
  margin-top: -1px;
}

.otherNft ul li label:first-child {
  margin-bottom: 5px;
}

.otherNft ul li p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.otherNft h6 {
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;

  /* blue - text #9BB0CF */
  color: #9bb0cf;
  margin-bottom: 15px;
}

.graphingNft {
  margin: 35px 0 40px;
}

.dontMiss {
  margin: 100px auto 0;
  background-image: url('/assets/images/background21.png');
  width: calc(100% - 30px);
  max-width: $--max-width;
  border-radius: 10px;
  text-align: center;
  padding: 65px 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.dontMiss h6 {
  font-size: 32px;
  font-family: 'shapiro65_light_heavy_wide';
  color: white;
  font-weight: 400;
}

.dontMiss p {
  font-size: 14px;
  color: white;
  font-weight: 300;
  max-width: 550px;
  width: 100%;
  margin: 15px auto 20px;
}

.dontMiss input {
  width: 100%;
  background: #212227;
  border: 1px solid #434343;
  border-radius: 10px;
  max-width: 230px;
  margin: 0 auto;
  display: block;
  height: 52px;
  padding: 0 20px;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.dontMiss button {
  background: var(--primary-color);
  border-radius: 8px;
  width: 130px;
  height: 52px;
  font-size: 18px;
  color: #1b1b1b;
  font-weight: 500;
  margin-top: 20px;
}

.launchCompletedSlider {
  margin-top: 130px;
}

.launchCompletedSliderInner {
  background: #1e1e2a;
  border: 1px solid #262636;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  padding: 70px 15px 20px;
}

.launchCompletedProjects {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 100px;
  position: relative;
  z-index: 0;
}

.launchCompletedProjects h6 {
  font-family: 'shapiro65_light_heavy_wide';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-bottom: 50px;
  position: absolute;
}

.launchCompletedSliderInner span {
  position: absolute;
  top: -70px;
  left: 50%;
  font-size: 21px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
  font-family: Lexend;
  font-weight: 700;
  color: white;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, 0);
  align-items: center;
}

.launchCompletedSliderInner span img {
  max-width: 58px;
  margin-bottom: 10px;
}

.launchCompletedSliderInner p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #8d8d8d;
}

.launchlist {
  margin-top: 30px;
}

.launchlist button {
  background: var(--primary-color);
  border-radius: 10px;
  width: 100%;
  height: 52px;
  margin-top: 20px;
  font-size: 18px;
  color: #1b1b1b;
  font-weight: 500;
}

.whitelist {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.whitelist p {
  font-size: 12px;
  color: white;
}

.whitelist p a {
  background: #32fe9a;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  margin-right: 10px;
  padding: 4px 10px;
  display: inline-block;
  color: #091535;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
}

.whitelist p a:last-child {
  color: #091535;
  background-color: #fff;
}

.whitelist p b {
  font-weight: 300;
  color: #999;
  //margin-left: 4px;
}

.wallet_btn {
  //display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse !important;

  width: 280px !important;
  height: 57px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin:5px;

  background: var(--wallet-button) !important;
  border-radius: 8px;
  color: white;
  position: relative;

  @include mobile {
    width: 230px !important;
  }

  &:hover {
    background: #45455f !important;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      display: flex;
      align-items: center;
      margin: auto;
  
      width: 24px;
      height: 24px;
      background-image: url("../assets/wallet-arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &:active {
    background-color: #45455f !important;
    background-size: 100% !important;
  }

  & + & {
    margin-top: 20px;
  }
  
  .wallet_btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 7px;
    //background-color: $white;
    width: 60px;
    //height: 50px;
  }

  .wallet_btn_icon {
    width: 24px;
    height: 24px;
  }

  .wallet_btn_text {
    display: flex;
    flex-direction: row;
    width: 100%;
    //justify-content: center;
    align-items: center;
    font-family: "Roboto";
  }
  

  img {
    background-color: black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/assets/images/polygonDropArrow.png') !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  padding-right: 16px !important;
}

.hidenow {
  display: none;
}

#loop {
  @include app;
}

.contents {
  padding: 80px 20px 20px;
  width: calc(100% - 233px);
  padding-top: 80px !important;
  float: right;

  @media (max-width: 1200px) and (min-width: 991px) {
  }

  @include mobile {
    margin-left: 0;
    padding: 0;
    width: 100%;
  }
}

header.toggle_header + .contents {
  width: calc(100% - 65px);
}

.main {
  width: 100%;
}

@include mobile {
  // header.header:before {
  //   position: absolute;
  //   width: 150px;
  //   height: 100%;
  //   top: 0;
  //   content: '';
  //   z-index: 1;
  //   right: 0;
  // }

  header.header h1 + button {
    filter: blur(3px);
  }

  header.header + .contents {
    filter: blur(0px);
  }

  header.header + .contents:before {
    width: 100%;
    height: 100%;
    background: black;
    position: absolute;
    content: '';
    z-index: 1;
    opacity: 0;
  }

  .overlayPop {
    position: fixed;
    background: black;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.6;
    display: block;
  }

  .overlayPop1 {
    display: none;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/assets/images/polygonDropArrow.png') !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  padding-right: 16px !important;
}

.ConnectWallet {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  //color: #1b1b1b !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600;
  transition: background 0.8s;
  &:hover {
    background: var(--primary-color) !important;
    border: solid 2px var(--primary-color) !important;
  }
  &:active {
    background-color: var(--primary-color) !important;
    background-size: 100% !important;
    transition: background 0s;
  }
}

.NotLive {
  background: #9c2a6f;
  text-align: center;
  color: white;
  border-radius: 4px;
  margin-bottom: 10px;
}

.mintBondContain {
  display: flex;
  align-items: flex-start;

  ul li {
    margin-bottom: 0 !important;
  }
}

.mintBondHoldLeft {
  width: 235px;
  margin-right: 30px;

  .ustMint {
    display: none;
  }
}

.nftmodal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: #1413218f;
  height: 100%;
  width: 100vw;
  z-index: 2;
}
.nftmodal_wrapper {
  .nftmodal {
    overflow: scroll;
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    z-index: 3;

    width: 90%;
    max-width: $--max-width;
    height: 100vh;
    padding: 10px;
    margin: auto;

    //background: #141321;
    background: #0c0b20;
    //border: solid 1px #3a3a3a;
    border: solid 1px rgba(56, 54, 78, 0.6);
    border-radius: 20px;

    transition: all .2s ease-in-out;
    .action_button {
      width: 50px;
      height: 50px;
      position: sticky;
      top: 5px;
      text-align: right;
      border-radius: 10px;
      margin: 5px;

      background: #262636a1;
      border: solid 1px #2f2f43;
      float: right;
      z-index: 99;
      cursor: pointer;
      transition: ease-in-out .2s;
      &:hover {
        background: #2f2f43;
      }
      &:active {
        transform: scale(0.8);
      }

      &__copy:before, &__open:before {
        display: none;
      }
      &__copy:hover:before, &__open:hover:before {display: block;}

      &__copy:before {
        width: 150px;
        background: #9c2a6f;
        content: "Copy Shareable URL";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        right: -10px;
        bottom: -35px;
      }
      &__copied:before {
        width: 150px;
        background: #9c2a6f;
        content: "Copied";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        right: -10px;
        bottom: -35px;
      }
      &__open:before {
        width: 150px;
        background: #9c2a6f;
        content: "Open new tab";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        right: -10px;
        bottom: -35px;
      }
      img {
        width: 30px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    @include mobile {
      top: 0px;
      width: 100%;
      border-radius: 0px;
      padding: 0px;
      border: none;
      .close {
        img {
          width: 40px;
        }
        width: 50px;
        height: 50px;
        padding: 5px;
        margin: 10px;
        position: sticky;
        top: 0;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
}

// .trendingInfo{    width: 100%;
//   height: 100%;}
.trendingInfoGrid {
  width: 100%;
  height: 100%;
}

.trendingInfoGrid li {
  width: calc(25% - 15px);
  height: 100%;
  background: #2d2d2d !important;
  border-radius: 20px !important;
  margin-bottom: 35px;
  position: relative;
}

.trendingInfoGrid li input:checked + .imageHolder {
  background-color: var(--primary-color);
}

.trendingInfoGrid li .imageHolder {
  // padding: 10px;
  margin: 0;
}

.trendingInfoGrid li input {
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.trendingInfoGrid li .imageHolder img {
  border-radius: 10px !important;
}

.mintBondHoldRight {
  width: calc(100% - 265px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ustMint {
  float: left;
  width: 100%;
}

.ustMint h3 {
  float: left;
  background: #262626;
  max-width: 100%;
  width: 258px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.ustMint h3 img {
  width: 38px;
  margin-right: 10px;
}

.mintBondHoldRight ul.payout {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.mintBondHoldRight ul.payout li {
  float: left;
  width: 25%;
  padding: 10px 15px 10px 0px;
  color: white;

  p {
    color: #fff;
    font-weight: 500;
  }

  span {
    color: #01cdfd;
  }
}

.mintBondHoldRight ul.payout li .head {
  color: #9bb0cf;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;

  img {
    margin-left: 3px;
  }
}

.mintBondHoldRight ul.payout li p .complete {
  color: #32fe9a;
}

.FarmManageMobile {
  display: none;
  font-size: 14px;
  font-weight: 300;
  clear: both;
}

.FarmManageMobile di v {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.FarmManageMobile label {
  display: flex;
  align-items: center;
}

.FarmManageMobile span {
  display: flex;
  align-items: center;
  color: white;
}

.FarmManageMobile .multiFlagImg {
  margin-right: 10px;
}

.FarmManageMobile .multiFlagImg img {
  margin-left: -10px;
  max-width: 30px;
}

.FarmManageMobile span img {
  margin-left: 3px;
}

.FarmManageMobile span.multiValues {
  flex-direction: column;
  align-items: flex-end;
}

.FarmManageMobile span.multiValues .loop {
  flex-direction: column;
  color: #919191;
  border-left: 1px dashed;
  padding: 0;
  padding-left: 5px;
}

.FarmManageMobile span.multiValues .loop p {
  line-height: 18px;
}

.FarmManageMobile span.multiValues {
  margin: 0;
}

.FarmManageMobile label img {
  margin-left: 3px;
}

.farmingPeriodHold {
  display: flex;
  justify-content: space-between;
}

.farmingPeriod {
  width: 31%;
  position: relative;
}

.farmingPeriodInner {
  width: 100%;
  border: 4px solid rgba(45, 45, 45, 0.5);
  border-radius: 8px;
  padding: 15px 20px;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}

.farmingPeriodInner p {
  text-align: center;
  font-size: 8px;
  line-height: 15px;
}

.farmingPeriod input[type='radio'] {
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.farmingPeriod input[type='radio']:checked + div {
  border-color: var(--primary-color);
}

.imageHolder {
  display: flex;
  padding: 15px;
  align-items: center;
  color: #fff;
  font-weight: 300;
  border-radius: 20px;
}

.image {
  width: 28px;
  height: 28px;
  background: #282b42;
  border-radius: 100px;
  margin-right: 7px;
  display: none;
}

.period {
  margin: 0;
}

.statics {
  margin-bottom: 5px;
  background: #2d2d2d;
  width: 96px;
  padding: 1px 10px;
  border-radius: 10px;
  height: 31px;
  text-align: center;
  font-weight: 300;
  clear: both;
  line-height: 27px;
  color: white;
  display: flex;
  align-items: center;

  span {
    color: #32fe9a;
    margin-right: 5px;
  }
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #32fe9a;
  margin-right: 6px;
}

.selectIconCustom {
  padding: 5px;
  cursor: pointer;
}

.decideFarmHolder {
  padding-top: 20px;
  clear: both;
  float: left;
  width: 100%;
}

.decideBalance {
  max-width: 304px;
  margin: 0 auto;
  clear: both;
  display: block;
}

.decideBalanceManage {
  margin: 0;
}

.decideBalanceTop {
  background: #131313;
  font-size: 11px;
  color: white;
  padding: 6px 15px;
  border-radius: 24px;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 300;
  width: 100%;
}

.decideBalanceSplit span:first-child {
  color: #01cdfd;
}

.decideBalanceSplit span.highlight {
  background: #2d2d2d;
  padding: 3px 5px;
  margin-left: 3px;
  border-radius: 4px;
  color: #9bb0cf;
  font-size: 10px;
}
.decideBalanceSplit section {
  float: right;
}
.decideBalanceManage .decideBalanceSplitEmpty {
  margin: 0;
}

.decideBalanceStake {
  background: #131313;
  border-radius: 10px;
}

.decideBalanceManage {
  position: relative;
}

.decideBalanceSplit.first {
  padding: 10px 50% 10px 15px;
}

.decideBalanceSplit.first p {
  color: #919191;
  font-size: 11px;
}

.decideBalanceSplit.first span {
  margin: 0;
  display: flex;
  align-items: center;
}

.decideBalanceSplit {
  margin: 0;
}

.decideBalanceSplit button.primaryBtnTask {
  background: #0998b9;
  border-radius: 10px;
  width: 50%;
  position: absolute;
  right: -180px;
  top: 0;
  height: 100%;
  color: black;
}

// .nftMain{background-color: red;}.decideFarm{margin:0;}
.decideFarmManage {
  display: block;
}

.decideFarmHead {
  float: left;
  width: 100%;
}

.decideFarmHead li {
  text-align: center;
  float: left;
  width: 20%;
  font-size: 14px;
  font-weight: 300;
  padding: 0 15px 5px;
}

.decideFarmHead li:first-child {
  text-align: left;
}

.decideFarmHead li:last-child {
  width: 10%;
}

.decideFarmHead li img {
  vertical-align: middle;
  cursor: pointer;
}

.decideFarmList {
  float: left;
  width: 100%;
  background: #131313;
  display: flex;
}

.decideFarmList li:first-child,
.decideFarmHead li:first-child {
  width: 30%;
  justify-content: flex-start;
}

.decideFarmList li {
  justify-content: center;
  float: left;
  width: 20%;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #fff;
}

.decideFarmList li.multiValues {
  margin: 0;
  flex-direction: column;
}

.decideFarmList li .multiFlagImg {
  padding-left: 10px;
  margin-right: 10px;
}

.decideFarmList li .multiFlagImg img {
  margin-left: -10px;
  width: 30px;
}

.decideFarmList li div.loop {
  clear: both;
  border-left: 1px dashed #919191;
  padding-left: 6px;
}

.decideFarmList li div.loop p {
  color: #919191;
  line-height: 18px;
}

.decideFarmList li:last-child {
  width: 50px;
}

.decideFarmList li span.arrowDrown {
  margin: 0;
}

.trendingFiltersLeft {
  display: none;
}

.nftexploreMain .trendingFiltersHomeTrending {
  display: none;
}

.trendingFiltersHomeTrending .trendingFiltersLeft {
  display: inline-block;
  width: 100%;
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending3Explore label {
  width: 60%;
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending3Explore input {
  font-weight: 300;
}

.trendingFiltersHomeTrending
  .trendingFiltersLeft
  .trending3Explore
  input::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}

.trendingFiltersHomeTrending
  .trendingFiltersLeft
  .trending3Explore
  input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.trendingFiltersHomeTrending
  .trendingFiltersLeft
  .trending3Explore
  input::placeholder {
  color: #ffffff;
}

.trendingFiltersHomeTrending
  .trendingFiltersLeft
  .trending1Explore
  div.homeUST {
  display: unset;
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending1Explore {
  float: left;
  width: 26%;
  margin-right: 2%;
  margin-top: 0;
  vertical-align: top;

  .optionSet {
    margin-left: 5px;
    height: 52px;

    // i {
    //   bottom: 3px;
    // }

    select {
      height: 100%;
      background-position: bottom 10px center !important;
    }
  }
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending2Explore {
  float: left;
  width: 20.5%;
  margin-right: 2.8%;
  margin-top: 0;
  vertical-align: top;
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending3Explore {
  float: left;
  width: 23.3%;
  margin-right: 2%;
  margin-top: 0;
  vertical-align: top;
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending4Explore {
  float: left;
  width: 23.3%;
  margin-right: 2%;
  margin-top: 0;
  vertical-align: top;
}

.trendingFiltersHomeTrending .trendingFiltersLeft .trending4Explore {
  margin-right: 0;
}

.trendingFiltersExplore {
  display: none;
}

.trendingFiltersRight {
  width: 100%;
  padding: 0;
}

// .nftHeader {
//   position: fixed;
//   top: 0;
//   z-index: 1;
//   left: 0;
//   width: 100%;
//   -webkit-transition: all 0.5s ease-in-out;
//   -moz-transition: all 0.5s ease-in-out;
//   -o-transition: all 0.5s ease-in-out;
//   transition: all 0.5s ease-in-out;
// }
.nftHeader {
  padding-bottom: 2.5rem;
  @include mobile {
    padding-bottom: 0;
  }
}
.previewStep2 {
  margin: 0;
}

.previewStep2 ul.trendingInfoGrid li {
  width: calc(20% - 15px) !important;
}

.headerPrimary {
  background: #1b1b1b;
  text-align: center;
}

.headerPrimary ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerPrimary ul a {
  padding: 15px 50px;
  display: block;
  font-size: 15px;
}

.headerPrimary ul li.headerActive a {
  color: white;
  font-weight: 700;
}

.scrolled {
  // position: fixed;
  // top: 0;
  // z-index: 1;
  // left: 0;
  // width: 100%;
  // background-image: url('/assets/images/nav_bg.jpg');
  // background-size: cover;
  // background-position: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.nftMain .scrolled .headerSecondary {
  padding: 10px 0;
  background-image: url('/assets/images/bannerBg.jpg');
  background-size: cover;
}

.nftMain .headerSecondary {
  padding: 10px 0;
  background-image: none;
  background-size: cover;
}

.headerSecondary {
  padding: 10px 0;
  background-image: url('/assets/images/bannerBg.jpg');
  background-size: cover;
}

.wrapper {
  width: 100%;
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
}

.wallet_login_wrapper {
  width: 100% !important;
}

.wrapper.fullwrap {
  max-width: 1220px;
}

.headerwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerSecondary ul {
  display: flex;
}

.headerSecondary ul a {
  display: block;
  margin: 0 20px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  font-family: 'Lexend', sans-serif;
}

.headerSecondary ul li.nftMainActive a {
  color: var(--primary-color) !important;
}

.logReg {
  display: flex;
}

.logReg li {
  padding: 0 !important;
}

.logReg a {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: white;
  border-radius: 10px;
}

.nftActive {
  background: var(--primary-color);
  margin-left: 40px;
  padding: 9px 20px;
}

.discoverBanner {
  //background-image: url('/assets/bannerGlow.svg');
  height: 865px;
  width: 100%;
  background-size: 700px;
  background-position: left 60% top -50px;
  //padding-top: 80px;
  background-repeat: no-repeat;
  @include tablet {
    padding-top: 0;
    padding-bottom: 100px !important;
  }
  @include mobile {
    padding-top: 0;
    padding-bottom: 100px !important;
  }
}

.bannerText {
  height: 100%;
}

.bannerText .wrapper {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  //margin-top: 20px;
}

.discoverBannerBtn {
  padding: 0;
  float: left;
  width: 100%;
  margin-top: 60px;
}

.exploreBtn {
  padding: 0;
}

.searchBtn {
  padding: 0;
}

.welcomeTexthandle .welcomeText {
  float: left;
  ._fancy {
    color: #fff !important;
    opacity: 1;
    font-family: allura;
    font-style: normal;
    font-size: 42px;
    margin-bottom: -20px;
  }
}
.welcomeTexthandle {
  @include tablet {
    margin-bottom: 50px;
  }
}

.welcomeTexthandle .comingSoon {
  float: left;
  width: 600px;
  max-width: 100%;
}

// .textHub {
//   position: absolute;
//   top: 50%;
//   transform: translate(0px, -50%);
// }
.textHub h1 {
  color: white;
  font-size: 64px;
  //width: 600px;
  line-height: 72px;
  font-family: 'shapiro65_light_heavy_wide';
  font-weight: normal;
  letter-spacing: -0.02em;
}

.textHub p {
  color: white;
  opacity: 0.8;
  margin-top: 24px;
  font-size: 24px;
}

.discoverBannerBtn a {
  padding: 12.5px;
  display: inline-block;
  border-radius: 10px;
  min-width: 200px;
  text-align: center;
  color: white;
  font-size: 18px;
  background: linear-gradient(95.63deg, #3168f7 0%, #01cdfd 106.47%);
  height: 64px;
  line-height: 40px;
  position: relative;
}
.discoverBannerBtn a:nth-child(2):before {
  position: absolute;
  content: 'REWARDS';
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #141321;
  left: 2px;
  top: 2px;
  border-radius: 10px;
  z-index: 0;
  line-height: 60px;
  color: #01cdfd;
}
.discoverBannerBtn .trending3Explore {
  background: #313336;
  border-radius: 10px;
  display: inline-flex;
  height: 52px;
  margin-left: 15px;
  position: relative;
  top: 3px;
  width: 380px;
  padding-left: 50px;
}

.discoverBannerBtn .trending3Explore input {
  font-weight: 300;
}

.discoverBannerBtn .trending3Explore span input::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}

.discoverBannerBtn .trending3Explore span input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.discoverBannerBtn .trending3Explore span input::placeholder {
  color: #ffffff;
}

a.exploreBtn {
  background: linear-gradient(95.63deg, #3168f7 0%, #01cdfd 106.47%);
  border-radius: 10px;
  color: white;
  margin-right: 15px;
  font-weight: 500;
  width: 200px;
  height: 64px;
  line-height: 40px;
  transition: all 0.2s ease;
  &:hover {
    background: linear-gradient(95.63deg, #3168f7 0%, #3168f7 106.47%);
  }
  &:focus {
    transform: scale(0.9);
  }
  @include mobile {
    width: 100%;
  }
}

input.searchBtn {
  margin-left: 15px;
  background: #212326;
  font-size: 14px;
  width: 386px;
  display: inline-block;
  padding: 18px 80px;
  vertical-align: bottom;
  text-align: left;
  background-image: url('/assets/images/search.svg');
  background-repeat: no-repeat;
  background-position: left 54px center;
  font-weight: 300;
}

input.searchBtn img {
  position: relative;
  top: 4px;
  margin-right: 5px;
}

.promotedSection {
  padding: 100px 0 40px;
  // background: #212327;
}

.promotedSection a {
  text-decoration: underline;
  color: #fff;
  display: block;
  margin: 40px auto 0;
  max-width: 200px;
  text-align: center;
  font-size: 14px;
}

.promotedSectionInner h2 {
  color: #fff;
  font-size: 32px;
  display: block;
  text-align: center;
  margin-bottom: 35px;
  font-family: 'shapiro65_light_heavy_wide';
}

.promotedSectionInner .launchCompletedSlider {
  margin-top: 10px;
  display: flex;
}

.promotedSectionInner ul {
  display: flex;
  justify-content: space-around;
}

.promotedSectionInner .launchCompletedSliderInner span + p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: center;
  color: #8d8d8d;
}

.promotedSectionInner p a {
  margin-top: 0;
}

.promotedSectionInner .launchCompletedSliderInner {
  padding: 0;
  padding-bottom: 10px;
  width: calc(33.33% - 20px);
}

.promotedSectionInner .launchCompletedSlider {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.completedSetImg {
  float: left;
  width: 100%;
}

.completedSetImg img {
  width: 100%;
}

.promotedSectionInner .launchCompletedSliderInner span {
  position: initial;
  transform: initial;
  float: left;
  width: 100%;
  margin: -35px 0 0;
}

.promotedSectionInner .launchlist {
  padding: 0 25px;
  margin-top: 0;
}

.promotedButtons {
  text-align: center;
  margin: 50px 0 30px;
}

.promotedButtons a {
  background: var(--primary-color);
  border-radius: 10px;
  height: 52px;
  width: 227px;
  color: #222;
  font-size: 18px;
  font-weight: 600;
  margin: 0 10px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.promotedButtons a img {
  vertical-align: middle;
  margin-left: 10px;
}

.promotedSectionInner .launchlist .whitelist {
  margin-top: 10px;
}

.promotedSectionInner ul li {
  width: calc(33.33% - 30px);
  background: #1b1b1b;
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  font-size: 0;

  a {
    width: 100%;
    text-decoration: none;
    margin: 0;
    display: inline-block;
    max-width: 100%;
  }
}

.promotedSectionInner ul li label {
  display: block;
  margin-top: 20px;
  font-size: 21px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.promotedSectionInner ul li p {
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
}

.promotedSectionInner ul li .small {
  text-align: center;
  padding: 0 15px;
  margin-bottom: 5px;
  font-size: 14px;
  float: left;
  width: 100%;
}

.promotedSectionInner ul li .small .smallBlock {
  display: inline-block;
  background: #ffffff;
  padding: 1px 6px;
  border-radius: 5px;
  margin: 0px 0 15px;
  color: #1b1b1b;
  font-weight: 500;
}

.promotedSectionInner ul li .small .smallBlock span {
  color: var(--primary-color);
}

.promotedSectionInner ul li span.promoteImage {
  position: relative;
  display: block;
  width: 100%;
}

.promotedSectionInner ul li span.promoteImage img {
  width: 100%;
  display: block;
}

.promotedSectionInner ul li span.promoteImage .goMoreLink {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #212326;
  width: 45px;
  padding: 10px;
  border-radius: 10px 0 0 0;
  cursor: pointer;
}

.category {
  padding: 40px 0 30px;
  background-image: url('/assets/images/creatorbg.jpg');
  background-position: center;
  background-size: cover;
}

.category .swiper-container .swiper-button-prev {
  left: -40px !important;
  background: #1b1b1b;
  top: auto !important;
  bottom: 14px;
  height: 53px;
  width: 40px;
  border-radius: 10px;
}

.category .swiper-container .swiper-button-prev:before {
  content: '';
  width: 15px;
  height: 15px;
  background: transparent;
  left: 7px;
  position: absolute;
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-top: 2px solid white;
}

.category .swiper-container .swiper-button-next {
  right: -40px !important;
  background: #1b1b1b;
  top: auto !important;
  bottom: 14px;
  height: 53px;
  width: 40px;
  border-radius: 10px;
  z-index: 5;
}

.category .swiper-container .swiper-button-next:before {
  content: '';
  width: 15px;
  height: 15px;
  background: transparent;
  left: 7px;
  position: absolute;
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-top: 2px solid white;
}

.category h2 {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-family: 'shapiro65_light_heavy_wide';
  font-weight: 300;
}

.category ul {
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  padding: 0 40px;
  overflow: hidden;
  max-width: 1100px;
}

.category .swiper-container {
  overflow: visible;
}

.category ul li {
  display: inline-block;
  background: #1b1b1b;
  // box-shadow: 0px 4px 6px 10px;
  border-radius: 10px;
  margin: 15px 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 6px 40px rgba(0, 0, 0, 0.1);
}

.category ul li a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 17px;
  padding: 2px 15px;
  font-weight: 500;
}

.category ul li a img {
  margin-right: 6px;
}

.trending {
  padding: 80px 0;
}

.trending h2 {
  color: #fff;
  display: block;
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
  font-family: 'shapiro65_light_heavy_wide';
}

.trending .trendingFilters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.trending1 {
  width: calc(30% - 25px);
  border-radius: 10px;
  height: 52px;
  display: flex;
  justify-content: space-between;
}

.trending1 span {
  width: calc(calc(50% - 30px));
  display: flex;
  align-items: center;
  background: #313336;
  border-radius: 10px;
}

.trending1 span.optionSet {
  width: 52px;
}

.trending1 label {
  width: 50%;
  text-align: right;
  margin-right: 5px;
  color: #919191;
}

.trending1 select {
  margin: 0 auto;
  color: white;
}

.trending1 input {
  color: white;
  width: 50%;
  text-align: left;
}

.trending2 {
  width: calc(20% - 25px);
  background: #313336;
  border-radius: 10px;
  height: 52px;
  display: flex;
  overflow: hidden;
}

.trending2 .trendingActive {
  color: #01cdfd;
  background: #3d3f42;
  position: relative;
  text-decoration: underline;
}

.trending2 button {
  width: 50%;
  background: #313336;
  color: #919191;
}

.trending3 {
  width: calc(25% - 25px);
  background: #313336;
  border-radius: 10px;
  height: 52px;
  display: flex;
  justify-content: center;
}

.trending3 span {
  display: flex;
  align-items: center;
}

.trending3 label {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  color: #919191;
}

.trending3 input {
  width: 50%;
  color: white;
}

.trending3 img {
  margin-right: 5px;
}

.trending4 {
  width: calc(25% - 25px);
  background: #313336;
  border-radius: 10px;
  height: 52px;
  display: flex;
  justify-content: center;
}

.trending4 span,
.nftMain .trending4Explore span {
  display: flex;
  align-items: center;
}

.trending4 label {
  color: #919191;
}

.trending4 select {
  background: #313336;
  color: white;
}

.trendingInfo_box {
  background-image: url('/assets/images/trendingInfo_bg.png');
  background-size: cover;
  margin-top: 40px;
  background-position: center;
}

.trendingInfo ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.trendingInfo li {
  transition: all ease-in-out 0.2s;
  &:hover {
    //background: #2e2d3a;
    background: #403a59;
    transform: scale(1.02);
  }
}

.trendingInfo li {
  width: calc(33% - 40px);
  //background: #1e1e2a;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: 0px 20px 40px 20px;
  position: relative;

  .icon {
    width: 15px;
  }
}

.trendingInfo li .imageHolder {
  position: relative;
}

.trendingInfo li .imageHolder .imgFloatContent {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0;
  padding-left: 5px;
  display: none;
}

.trendingInfo li .imageHolder .apr,
.trendingInfo li .imageHolder .aprTimeLeft {
  display: inline-block;
  width: 100%;
  margin-bottom: 3px;
}

.trendingInfo li .imageHolder .aprTimeLeft {
  color: #01cdfd;
}

.trendingInfo li .imageHolder .apr span,
.trendingInfo li .imageHolder .aprTimeLeft span {
  background: #212121;
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 5px;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  margin-bottom: 0px;
  width: auto;
}

.trendingInfo li .imageHolder .aprTimeLeft img {
  width: 15px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}

.trendingInfo li .imageHolder .apr i {
  color: #ffffff;
  font-style: normal;
}

.trendingInfo li .imageHolder .aprTimeLeft.aprTimeLeftTop {
  top: 10px;
  right: 10px;
  position: absolute;
  width: auto;
}

.trendingInfo li .imageHolder .aprTimeLeft.aprTimeLeftTop span {
  background: #fff;
  color: #000000;
}

.skeletonLoading {
  background: #222131 !important;
}

.skeleton {
  animation: skeleton 1s linear infinite alternate;
}

.labelSkeleton {
  margin-bottom: 10px;
  width: 70% !important;
}

@keyframes skeleton {
  0% {
    background-color: rgba(27, 27, 27, 1);
  }

  100% {
    background-color: rgba(27, 27, 27, 0.2);
  }
}

.trendingInfo li a {
  display: block;
  width: 100%;
  img {
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .imgHolder {
    width: 285px;
    height: 285px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.trendingInfo li .img {
  padding-bottom: 80%;
}

.trendingInfo li span {
  float: left;
  width: 100%;
  //padding: 1px 1px 20px;
  //flex: 1;
  //display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trendingInfo li label {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  padding: 4px 0;
}

.trendingInfo p b {
  color: #01cdfd;
  font-size: 10px;
}

.counterButton {
  clear: both;
  padding: 5px 0 0;
}

.trendingInfo li b {
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trendingInfo li p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.trendingInfo p i {
  font-size: 14px;
  color: #01cdfd;
  font-style: normal;
  margin-left: 4px;
  margin-top: -1px;
}

.trendingInfo li p img {
  margin-left: 5px;
}

.belowLabel b {
  font-size: 10px;
}

.creator {
  padding: 77px 0;
  margin: auto;
  max-width: 1800px;
  //background-image: url('/assets/images/creatorbg.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-position: center -373px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-size: cover !important;
    background-position: center;
    background: url('/assets/images/creatorbg.jpg');
    border-radius: 15px;
  }
  .creatorInner {
    img {
      width: 100px;
    }
    .levelup,
    .govern {
      padding: 0 20px;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
  .button_outline {
    width: 170px;
    height: 52px;
    font-size: 18px;
    //background: var(--primary-color);
    border: solid 1px var(--primary-color);
    color: var(--primary-color);
    border-radius: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      background: var(--primary-color);
      color: #fff;
      transition: ease-in-out 0.1s;
    }
  }
}

.creator h2 {
  color: white;
  display: block;
  text-align: center;
  //font-size: 32px;
  font-size: 26px;
  margin-bottom: 40px;
  font-family: 'shapiro65_light_heavy_wide';
  font-weight: normal;
}

.creator ul {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 40px;
}

.creator ul li {
  padding: 50px 15px;
  width: 24%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
}

.creator ul li span {
  display: flex;
  width: 75px;
  // background: #141321;
  margin: 0 auto;
  border-radius: 100px;
  height: 75px;
  align-items: center;
  justify-content: center;
}

.creator ul li span img {
  display: block;
  margin: 0 auto;
}

.creator ul li b {
  margin-top: 15px;
  display: block;
  text-transform: uppercase;
  color: white;
  margin-bottom: 15px;
  font-size: 14px;
}

.creator ul li p {
  color: white;
  font-weight: 300;
  font-size: 14px;
  padding: 0 10px;
}

.showme {
  margin: 25px auto 0;
  display: block;
  text-align: center;
  max-width: 200px;
  text-decoration: underline;
  color: white;
  font-size: 14px;
}

.refreshBtn {
  background: var(--primary-color);
  color: #1b1b1b;
  display: flex;
  max-width: 227px;
  margin: 35px auto 0;
  align-items: center;
  justify-content: center;
  padding: 10.5px;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
}
a.btnMoreInfo,
a.btnMoreInfo:hover {
  width: 170px;
  height: 52px;
  font-size: 18px;
  color: var(--primary-color);
  margin: 40px auto;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.refreshBtn img {
  display: none;
}
.refreshBtn i {
  width: 12px;
  height: 12px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  margin-left: 5px;
  position: relative;
  top: 1px;
}
::-webkit-input-placeholder {
  /* Edge */
  color: white;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::placeholder {
  color: white;
}

.footer {
  // background: #1b1b1b;
  padding: 72px 0;
  margin-top: 100px;
  float: left;
  width: 100%;
}

.footerPrimary {
  width: 25%;
  float: left;
}

.footerPrimary h3 {
  color: #bbb;
  opacity: 1;
  margin-bottom: 50px;
  margin-top: 25px;
  font-weight: 300;
  width: 60%;
  font-size: 14px;
}

.footerLogo {
  display: block;
}

.footerLogo img {
  display: block;
}

.footerPrimary ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerPrimary ul li a {
  display: block;
}

.footerPrimary ul li a img {
  display: block;
}

.footerSecondary {
  width: 60%;
  float: right;
  margin-top: 30px;
}

.footerSecondary1 {
  float: left;
  width: (100% / 4);
}

.footerSecondary1 ul li {
  font-size: 17px;
  color: var(--primary-color);
  line-height: 30px;
}

.footerSecondary1 ul li a:hover {
  color: var(--primary-color);
}

.footerSecondary1 ul li a {
  font-weight: 300;
  font-size: 14px;
  color: white;
  text-transform: capitalize;
}

.nftexploreMain .headerSecondary {
  padding: 10px 0;
}

.nftexploreMain .trending {
  //padding: 185px 0 50px;
  padding: 100px 0 50px;
}

.nftexploreMain .trending h2 {
  text-align: left;
  color: white;
  margin: 0;
  padding: 40px 0;
  font-family: 'shapiro65_light_heavy_wide';
}

.nftexploreMain .trending .trendingFiltersExplore .trendingFilters {
  display: flex;
  margin-bottom: 10px;
}

.nftexploreMain .trending .trendingFilters {
  display: none;
}

.nftexploreMain .trendingInfo {
  padding: 0;
  flex: 1;
}

.trendingFiltersHub {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.nftexploreMain .trendingFiltersLeft {
  display: block;
  margin-bottom: 20px;
}

.nftexploreMain .trendingFiltersRight {
  width: 100%;
  display: flex;
  @include mobile {
    display: block;
  }
}

.trendingFiltersNew {
  padding: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.homeTrending .trendingInner .trendingFiltersHub .trendingNewMenu {
  display: none;
}

.trending2Explore {
  background: #313336;
  border-radius: 10px;
  height: 52px;
  display: flex;
  overflow: hidden;
  margin-bottom: 10px;
  width: 216px;
}

.trending2Explore .trendingActive {
  background: #38383a;
  color: #fff !important;
  position: relative;
  text-decoration: underline;
}

.trending2Explore button {
  background: #313336;
  position: relative;
  width: 50%;
  color: #919191;
}

.trending2Explore button:last-child {
  width: 50%;
  // background: #313336;
  color: #919191;
  border-left: 1px solid #3e3e3e;
}

.trending1Explore {
  display: flex;
}

.nftexploreMain .trending1Explore div {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.trending1Explore div span {
  background: #313336;
  display: flex;
  text-align: right;
  height: 52px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.trending1Explore div span label {
  width: 50%;
  padding-right: 5px;
  color: #919191;
}

.trending1Explore div .optionSet {
  position: relative;
}

.optionSet .select > div {
  height: 100%;
}

// .trending1Explore div .optionSet i {
//   position: absolute;
//   bottom: 35px;
// }
.trending1Explore div .optionSet select,
.trending1Explore div .optionSet .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: #313336;
  height: 100%;
  text-align: center;
  font-size: 14px;
  padding-right: initial !important;
  background-position: bottom 35px center !important;
  border-radius: 10px;
}

.trending1Explore div span input {
  width: 50%;
}

.trending1Explore div .optionSet {
  margin-left: 10px;
  position: absolute !important;
  left: 0;
  top: 54px;
  z-index: 1;
  width: 67px;
  display: none;
  height: auto;
}

span.optionSet.toggleust {
  display: block;
}

.trending1Explore div .optionSet span {
  height: auto;
}

.trending1Explore .minPrice {
  padding-right: 3px;
}

.trending1Explore .maxPrice {
  padding-left: 3px;
}

.trending3Explore {
  background: #313336;
  border-radius: 10px;
  height: 52px;
  display: flex;
  margin-bottom: 20px;
}

.trending3Explore span {
  display: flex;
}

.trending3Explore label {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  align-items: center;
  color: #919191;
}

.trending3Explore label img {
  margin-right: 5px;
  width: 19px;
}

.trending3Explore span input {
  width: 100%;
  padding-right: 10px;
  color: #ffffff;
}

.trending3Explore span input::-webkit-input-placeholder {
  /* Edge */
  color: #919191;
}

.trending3Explore span input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #919191;
}

.trending3Explore span input::placeholder {
  color: #919191;
}

.trendingExploreValues {
  padding: 0;
  margin-bottom: 15px;
  display: none;
}

.trendingExploreValues span {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px 0;
  word-break: break-all;
}

.trendingExploreValues span label {
  padding: 0;
}

.trendingExploreValues span label i {
  font-style: normal;
  background: transparent;
  width: 22px;
  height: 22px;
  // border: 1px solid white;
  border-radius: 100px;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  // transform: rotate(45deg);
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 2px;
}

.trending4ExploreHub {
  display: flex;
  width: 380px;
  justify-content: space-between;
}

.trending4Explore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  background: #313336;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 50%;
}

.trending4Explore:last-child {
  margin-bottom: 0;
}

.trending4Explore span {
  padding: 0;
}

.trending4Explore label {
  color: #919191;
  padding-right: 5px;
}

.trending4Explore select,
.trending4Explore .select {
  width: 120px;
  background: #313336;
  color: white;
}

.nftexploreMain .trendingFiltersExplore {
  display: block;
  position: relative;
  z-index: 0;
}

.trendingFiltersExplore .trending3 {
  width: calc(100% - 380px);
}

.trendingFiltersExplore .trending3 span {
  width: 100%;
}

.trendingFiltersExplore .trending4 {
  width: 252px;
}

.trendingFiltersExplore .trending4 select {
  box-sizing: content-box;
  padding: 5px 0;
  font-size: 14px;
}

.trendingFiltersExplore .trending4 span label {
  display: flex;
  align-items: center;
  padding-right: 5px;
  font-size: 14px;
}

.trendingFiltersExplore .trending4 span label img {
  margin-right: 5px;
}

.trending5 {
  display: flex;
  background: #313336;
  border-radius: 10px;
  overflow: hidden;
  width: 104px;
  justify-content: space-between;
}

.trending5 button {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trending5 button.gridActive {
  background: #38383a;
}

.nftexploreMain a.showme {
  display: none;
}

.trendingFiltersExplore .trending3 span {
  justify-content: center;
}

.trendingFiltersExplore .trending3 label {
  width: auto;
  font-size: 14px;
}

.trendingFiltersExplore .trending3 input {
  width: auto;
  font-size: 14px;
}

.trendingInfo ul.trendingInfoGrid li {
  width: calc(25% - 20px);
  margin-bottom: 20px;
}

.mintBondHoldLeft .trendingInfo ul.trendingInfoGrid li {
  width: 100%;
}
// .nftBondPreviewYield .preview {
//   width: 100% !important;
//   ul li{width: calc(20% - 10px) !important;}
// }
.trendingInfo ul.trendingInfoGrid li span {
  display: block;
}

.listDetail {
  display: none;
}

.trendingInfo ul.trendingInfoGrid li img {
  border-radius: 15px;
}

.popBlowHide {
  display: none !important;
}

.popBlow {
  position: fixed;
  background: #000000b3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.popBlow ul {
  width: 236px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.popBlow ul li {
  background: #1b1b1b;
  border-radius: 10px;
  float: left;
  width: 100%;
  overflow: hidden;
}

.popBlow ul li img {
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0 10px;
  display: block;
}

.popBlow ul li span {
  padding: 7px 15px;
  float: left;
  width: 100%;
  background: #1b1b1b;
}

.popBlow ul li span label {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
}

.popBlow ul li span label b {
  font-size: 12px;
  color: white;
  font-weight: normal;
}

.popBlow ul li span label p {
  font-size: 10px;
  display: flex;
  align-items: center;
}

.popBlow ul li span label p i {
  font-size: 12px;
  font-style: normal;
  color: white;
  margin-left: 3px;
}

.popBlow ul li span label p img {
  font-size: 10px;
}

.popBlow ul li div {
  position: relative;
}

.popBlow ul li div .goMore {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 45px;
  padding: 10px;
  border-radius: 10px 0 0 0;
  cursor: pointer;
}

.popBlow ul li span label p img {
  margin-left: 3px;
}

.popBlow .belowLabel {
  padding: 0 0 5px;
}

.popBlow .belowLabel b {
  // font-size: 10px;
  opacity: 0.5;
  font-weight: 300;
}

.popBlow .belowLabel p {
  padding: 0;
}

.popBlow .belowLabel i {
  padding: 0;
}

.FullProductInfo {
  //padding-top: 150px;
  padding-top: 100px;
  // background: #111117;
  @include tablet {
    padding-top: 0;
  }
  @include mobile {
    padding-top: 0;
  }
}
.spaced {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: solid 1px #ffffff14;
}

.spaced div {
  display: flex;
}

.spaced ul {
  display: flex;
}

.spaced div p {
  margin-right: 20px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 300;
  color: white;
  border-right: 1px solid #ffffff0a;
  letter-spacing: 0.5px;
}

.spaced div p b {
  margin: 0;
  color: #0998b9;
  font-size: 18px;
  white-space: nowrap;
}

.spaced ul li {
  padding-right: 20px;
  border-right: 1px solid #ffffff14;
  margin-right: 20px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 300;
  color: white;
  letter-spacing: 0.4px;
}

.spaced ul li:last-child {
  border-right: 0;
  padding: 0;
  margin: 0;
}

.spaced ul li i {
  font-style: normal;
  opacity: 0.7;
}

.spacedPrice {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 40px;
  margin-bottom: 40px;
}

.spacedPrice p {
  display: flex;
}

.spacedPrice p b {
  margin-left: 8px;
}

.spacedPrice {
  .actions {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  button {
    border-radius: 10px;
    padding: 15px 0;
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
    margin-top: 10px;
    font-size: 18px;
    background: var(--primary-color) !important;
    min-width: 270px;
    transition: background 0.8s;
    &:hover {
      background: var(--primary-color) !important;
      opacity: 0.8;
    }
    &:active {
      background-color: var(--primary-color) !important;
      background-size: 100% !important;
      transition: background 0s;
    }
    &:disabled {
      background: #4c4c4c81 radial-gradient(circle, transparent 1%, #4c4c4c81 1%) !important;
      &:hover {
        background: #4c4c4c81 radial-gradient(circle, transparent 1%, #4c4c4c81 1%)
          center/15000% !important;
      }
    }
  }
  .addFunds {
    background: #01cdfd radial-gradient(circle, transparent 1%, #01cdfd 1%)
      center/15000% !important;
    &:hover {
      background: #1e7a90 radial-gradient(circle, transparent 1%, #1e7a90 1%)
        center/15000% !important;
    }
    &:active {
      background-color: #01cdfd !important;
      background-size: 100% !important;
      transition: background 0s;
    }
  }
}

.spacedRewards {
  padding-top: 20px;
  border-top: 1px solid #262632;
}

.spacedRewards h6 {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 32px;
  font-family: 'shapiro65_light_heavy_wide';
  color: white;
  margin-bottom: 20px;
}

.spacedRewards h6 img {
  margin-right: 10px;
}

.spacedRewards table {
  //background: #1e1e2a;
  background: #15142C;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: 14px;
}

.spacedRewards table th {
  text-align: center;
  color: white;
  font-weight: 300;
  letter-spacing: 0.4px;
  padding: 20px 5px;
}

.spacedRewards table b,
.spacedRewards table b i {
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  margin: 0;
  text-align: center;
}

.spacedRewards table td {
  border-right: 1px solid #2d2d2d;
  padding: 15px 25px;
  font-weight: 400;
  left: 0.3px;
  color: #919191;
}

.spacedRewards table tr {
  border-top: 1px solid #2d2d2d;
}

.spacedRewards table tr:first-child {
  border: none;
}

.spacedRewards table th:first-child {
  text-align: left;
  color: #919191;
  padding: 20px 25px;
}

.spacedRewards table th img {
  vertical-align: middle;
  margin-right: 3px;
}

.spacedRewards table td:last-child {
  border: none;
}

.spacedRewards table b i {
  color: #919191;
}
.detailTrending {
  display: flex;
  margin-top: 80px;
  align-items: flex-start;
}
.FullProductInfo .wrapper {
  max-width: 1145px;
}
.detailTrendingLeft {
  max-width: 473px;
  position: relative;
  margin-right: 60px;

  .detailedIcons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;

    img {
      margin: 0 -10px;
    }
  }
}

@media (max-width: 1024px) {
  .exploreList ul li span p {
    padding: 0 20px;
  }

  .nftexploreMain .trending {
    padding-top: 0px;
  }
}
.detailTrendingLeft > img {
  min-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #212128;
}
.detailTrendingLeft img {
  display: block;
  width: 100%;
  border-radius: 10px;
}

.detailTrendingRight {
  padding-left: 20px;
  width: 61%;
}

.detailTrendingRight1 {
  display: flex;
}

.trendingNewMenu.alside {
  min-width: 193px;
  margin-right: 60px;
}

.dtLeft {
  width: 100%;
}

.dtrow,
.dtLeftbox,
.dtrightbox,
.dtRight {
  padding: 0;
}

.dtLeft .dtrow {
  float: left;
  width: 100%;
}

.dtLeft a {
  font-size: 14px;
}

.dtLeft a {
  width: 12px;
  color: #0998b9;

  img {
    margin-right: 5px;
  }
}
.dtLeft {
  p {
    margin-bottom: 20px !important;
    span {
      margin-left: 5px;
      color: #fff !important;
    }
  }
}

.dtLeft h2 {
  font-family: 'shapiro65_light_heavy_wide';
  color: white;
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1.8px;
  margin-top: 10px;
  @include mobile {
    margin-bottom: 5px !important;
  }
}

.dtLeft b {
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
  display: block;
}

.dtLeft .dtLeftbox .desc p {
  font-size: 15px;
  line-height: 21px;
  font-weight: 300;
}

.dtLeft p {
  color: #919191;
}

.dtrightbox {
  width: 100%;
  float: left;
  margin-top: 30px;
  display: none;
}

.dtRight {
  width: 100%;
  background: #1b1b1b;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  float: left;
  width: 100%;
  display: flex;
}

.dtRight .pointsHold {
  padding: 20px;
  float: left;
  width: 50%;
  display: block;

  div {
    display: flex;
  }
}

.pointsHoldButton {
  width: 50%;
  float: left;
  padding: 20px;
  position: relative;

  div {
    display: flex;
  }
}

.pointsHoldButton:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 80%;
  background: #404040;
  top: 10%;
  left: 0;
}

.dtRight .pointsHold.pointsHoldBottom {
  padding-top: 0;
}

.dtRight div {
  font-size: 19px;
  color: white;
  font-weight: 300;
  align-items: center;
}

.dtRight div.divide {
  padding: 30px 0;
}

.dtRight div label {
  color: #bbbbbb;
  font-size: 15px;
}

.dtRight b {
  margin-left: 5px;
  margin-right: 5px;
  color: #01cdfd;
  font-size: 19px;
  // font-family: "shapiro65_light_heavy_wide";
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-bottom: 0px;
  vertical-align: top;
  font-weight: 500;
}

.dtRight b img {
  margin-left: 8px;
  width: 22px;
}

.dtRight button {
  border-radius: 10px;
  width: 100%;
  padding: 15px 0;
  text-transform: uppercase;
  color: #1b1b1b;
  font-weight: 500;
  margin-top: 10px;
  font-size: 18px;
}

// .dtRight div.pointsHoldButton div {
//   margin-bottom: 6px;
// }
.dtRight button img {
  margin-right: 5px;
  width: 22px;
  vertical-align: sub;
}

.dtRight button.secondaryBtn img {
  vertical-align: text-top;
  margin-right: 7px;
}

.dtRight button.primaryBtn {
  background: var(--primary-color);
}

.dtRight button.secondaryBtn {
  background: #0998b9;
}

.btnAuctionDiv {
  float: left;
  width: 100%;
}

.dtLeft button.btnAuction {
  background: #01cdfd;
  float: left;
  border-radius: 100px;
  margin-top: 0;
  margin-bottom: 15px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 5px 15px;
}

.dtLeft button.btnAuction img {
  margin-right: 5px;
  width: 20px;
  vertical-align: text-bottom;
}

.detailTrendingRight2 {
  padding: 0px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  margin: 30px 0;
}

.detailTrendingRight2 p {
  color: #bbbbbb;
  font-size: 17px;
  padding: 7px 0;
  font-weight: 300;
}

.detailTrendingRight2 b {
  font-weight: 300;
  color: #ffffff;
}

.detailTrendingRight3 {
  padding: 0;
}

.detailTrendingRight3 b {
  color: #919191;
  font-size: 17px;
  font-weight: 300;
}

.detailTrendingRight3 .ab {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 15px;
  line-height: 21px;
  font-weight: 200;
}

.detailTrendingRight3 .ab span {
  padding: 0;
}

.detailTrendingRight3 .ab span img {
  padding: 0;
}

.detailTrendingRight3 .ab p {
  padding-left: 15px;
}

.detailTrendingRight4 {
  //background: #1e1e2a;
  border-radius: 10px;
  margin: 130px 0;
  .tbleAbout table {
    padding: 10px;
    border-radius: 10px;
    //border: 1px solid black;
    
      tbody tr {
        td {
          padding: 15px;
          border-bottom: 9px solid #02001F;
          background: #15142C;
          &:first-child { border-top-left-radius: 10px; }
          &:last-child { border-top-right-radius: 10px; }
        }
    }
  }
}
.tbleAbout table thead tr {
  background: transparent !important;
  width: 100%;
  background: transparent;
  margin: 1em 0;
  border: 0;
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0,0,0,.87);
}

.tbleAbout table tr th:last-child {
  width: 160px;
}

.tbleAbout h6 span {
  float: right;
}

.tbleAbout h6 span button {
  font-weight: normal;
  font-size: 15px;
  margin-left: 20px;
  color: #919191;
}

.tbleAbout h6 span button:hover,
.tbleAbout h6 span button.activeActions {
  color: var(--primary-color);
  text-decoration: underline;
}
.tbleAbout {
  padding: 0;
  padding-bottom: 20px;
}

.tbleAbout h6 {
  font-weight: bold;
  padding: 18px 25px;
  border-bottom: 1px solid #232238;
  color: #ffffff;
  font-size: 15px;
  line-height: 19px;
}

.tbleAbout table {
  padding: 0;
}

.tbleAbout table tr {
  padding: 0;
}
.tbleAbout table thead tr {
  background: transparent !important;
}
// .tbleAbout table thead tr {
//   background: #1b1b1b !important;
// }
.tbleAbout table tr:hover {
  background: #0c0b1240 !important;
}
.tbleAbout table tr:nth-child(odd) {
  background: #21232642;
}
.tbleAbout table tr th:last-child {
  width: 160px;
}
.tbleAbout table tr th {
  padding: 40px 0 20px 15px;
  font-weight: normal;
  //color: #919191;
  color: #787B9C;
  font-size: 14px;
  line-height: 21px;
  min-width: 100px;
}

.tbleAbout table tr td {
  padding: 10px 0 10px 15px;
  color: white;
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  min-width: 100px;
}

.tbleAbout table tr td img {
  vertical-align: top;
  margin-right: 4px;
}

.blueActive {
  color: #01cdfd !important;
}

.expander {
  opacity: 0;
  margin-left: 5px;
}

.tbleAbout table tr:hover .expander {
  opacity: 1;
  cursor: pointer;
}

.trendingInfoList {
  padding: 62px 0;
}

.trendingInfoList h2 {
  font-size: 32px;
  font-family: 'shapiro65_light_heavy_wide';
  color: #fff;
  margin-bottom: 30px;
}

.trendingInfoList li {
  width: calc(20% - 15px);
}

.FullProductInfo .trendingInfoList li {
  margin-bottom: 0;
}
header.header {
  //background-image: url('/assets/images/nav_bg.jpg');
  background: $theme_dark_black_color;
  .wrap {
    max-width: 1440px;
    margin: auto;
    padding: 10px 0;
  }
}

.logo {
  width: 100px !important;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.fans_logo {
  width: 100px !important;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(120%);
  }
  @include mobile {
    width: 80px !important;
  }
}

ul.menu.mneuValues li {
  padding: 0;
  float: right;
}
nav.navbar {
  display: flex;
}

ul.menu.mneuValues {
  position: absolute;
  right: 0;
}

ul.menu.mneuValues li.loginCredHeaderHold:first-child {
  display: block;
}

header {
  min-height: 76px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 3px 60px;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid hsla(0, 0%, 93.3%, 0.14901960784313725);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.wrap {
  display: flex;
  //justify-content: center;
  align-items: center;
  position: relative;
  .navbar {
    margin-left: 50px;
    @include mobile {
      margin-left: 0;
    }
    
    .nav-item {
      padding: 3px 13px;
      margin: 0 4px;
      border: 1px solid transparent;
      &:hover {
        background: var(--secondary-hue);
        border: 1px solid var(--secondary-hue);
        border-radius: 8px;
      }
      a {
        padding: 8px;
      }
    }
    .active {
      background: var(--primary-hue) !important;
      border: 1px solid var(--primary-color) !important;
      border-radius: 8px;
      &:hover {
        background: var(--primary-hue) !important;
        border: 1px solid var(--primary-color) !important;
      }
    }
  }
}

.menu_icon {
  display: none;
  cursor: pointer;
}

.mobile_menu {
  position: absolute;
  right: 20px;
  display: flex;
  gap: 17px;
  align-items: center;
}

// .navbar {
//   max-height: none;
//   width: 100%;
//   display: inline-block;
//   text-align: center;
// }

.navbar .menu {
  padding: 14px 0;
}

.navbar .menu li {
  margin-left: 0;
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
}

.navbar .menu li a sup {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #ffffff;
  margin-left: 5px;
  position: absolute;
  right: -8px;
}

// .navbar .menu li:last-child {
//   float: right;
// }
// .navbar .menu li:last-child ul {
//   width: 100%;
// }
// .navbar .menu li:last-child li {
//   margin: 0;
// }

.showNftDropdown {
  position: absolute;
  width: 170px;
  display: flex !important;
  background: #1b1b1b;
  padding: 18px 20px;
  border-radius: 10px;
  left: -40px;
}

.navbar .menu > li.dropdown_list,
.navbar .menu > li.activeNftDropdown {
  background: #1b1b1b6b;
  border-radius: 6px;
  // padding-right: 22px;
}

.navbar .menu > li.dropdown_list > a,
.navbar .menu > li.activeNftDropdown > a {
  color: var(--primary-color) !important;
}

.navbar .menu > li.dropdown_list > a sup,
.navbar .menu > li.activeNftDropdown > a sup {
  border-top-color: var(--primary-color);
}

.navbar .menu li a {
  display: block;
  position: relative;
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease;
  padding: 8px;
  font-weight: 500;
  border-radius: 6px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showNftDropdown li {
  margin: 0 !important;
  width: 100%;
}

.navbar .menu li a span {
  margin-right: 12px;
  vertical-align: middle;
}

.showNftDropdown li a {
  padding: 0 !important;
  margin: 8px 0;
  color: #9bb0cf !important;
  text-align: left !important;
  display: block !important;
}

.navbar .menu li.nftMainActive a svg path,
.navbar .menu li a:hover svg path {
  fill: var(--primary-color);
}

.navbar .menu li a:hover,
.navbar .menu li.nftMainActive a {
  color: #fff !important;
}

.navbar .menu .logReg li .nftActive:hover {
  color: #ffffff !important;
}

.navbar .menu li a:hover sup {
  border-top: 6px solid var(--primary-color);
}

// .homeTrending .trendingInfo {
//   background: none;
// }
.navbar > div {
  display: none;
}

// .Contract_box {height: 100vh;}

.Contract_box .trending {
  height: 100%;
  padding: 0 0 30px !important;
  // padding: 0 0 30px;
  // background: transparent;
}

.Modal_modalBody__rQPy4 .Contract_box .trending {
  padding: 0 0 30px !important;
  background: transparent;
}

.Contract_box .heading_box {
  text-align: center;
}

.Contract_box .heading_box .exploreHeading {
  text-align: center;
  padding: 0;
}

.Contract_box .heading_box a {
  color: #919191;
  font-size: 14px;
  line-height: 21px;
}

.Contract_box .heading_box h3 {
  color: #ffffff;
  font-family: 'shapiro65_light_heavy_wide';
  padding: 0px 0 40px;
  font-size: 32px;
  line-height: 37.76px;
  font-weight: 100;
  margin-top: 60px;
}

.Contract_box .heading_box p {
  color: #bbbbbb;
  padding: 10px 0 40px;
  font-size: 17px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 300;
}

.Contract_box .btn_box {
  a:first-child {
    margin-top: 0 !important;
  }

  a:last-child {
    margin-bottom: 25px !important;
  }
}

.Contract_box .help {
  margin: 20px 0;
  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    margin: 20px 0;
  }
}
.Contract_box{
  .loginbtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.Contract_box .btn_box a.connect {
  background: var(--primary-color);
  color: #1b1b1b;
  display: flex;
  margin: 20px auto 0;
  align-items: center;
  justify-content: flex-start;
  padding: 19px 60px 19px 19px;
  border-radius: 10px;
  font-size: 19px;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center right 20px;
}

.Contract_box .btn_box a img {
  margin-left: auto;
}

.Contract_box .btn_box a.connect b {
  margin-left: 4px;
}

.Contract_box_flow_2 .heading_box_wrap {
  display: flex;
  justify-content: space-between;
}

.Contract_box_flow_2 .heading_box_wrap .heading_box_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Contract_box .heading_box_wrap .heading_box_right p {
  padding: 0 20px;
}

.Contract_box .heading_box_wrap .heading_box_right a {
  background: #1b1b1b;
  font-size: 21px;
  color: var(--primary-color);
  font-weight: 400;
  padding: 10px 10px;
  border-radius: 7px;
}

.builder_wrap {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.builder_wrap .builder_box {
  width: 33.33%;
  background: #1b1b1b;
  border-radius: 10px;
  margin: 0 15px;
  padding: 35px 10px 47px;
  text-align: center;
  max-width: 310px;
}

.builder_wrap .builder_box .image_box {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background: #2d2d2d96;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.builder_wrap .builder_box .image_box img {
  width: auto;
  height: auto;
}

.builder_wrap .builder_box .text_box {
  padding: 0 30px;
  text-align: left;
}

.builder_wrap .builder_box .text_box h3 {
  color: #ffffff;
  font-size: 19px;
  line-height: 23.75px;
  margin-top: 25px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  margin-bottom: 15px;

  + p {
    max-width: 177px;
    margin: 0 auto;
    font-size: 15px;
    color: #bbb;
    font-weight: 300;
  }
}

.builder_wrap .builder_box .text_box ul.no_bullet {
  list-style: none;
}

.builder_wrap .builder_box .text_box ul {
  width: 170px;
  text-align: left;
  margin: 0 auto 30px;
  list-style: disc;
}

.builder_wrap .builder_box .text_box ul li {
  color: #bbbbbb;
  font-weight: 200;
}

.detailMobileTable {
  display: none;
}

.detailMobileTable label {
  float: left;
  width: 33.33%;
  line-height: 16px;
  padding: 8px 0 8px 10px;
}

.detailMobileTable b {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.promotedSectionInner ul li label,
.promotedSectionInner ul li p {
  padding: 0 15px;
}

.promotedSectionInner ul li p {
  font-size: 14px;
}

// .optionName {
//   padding-bottom: 30px;
// }

.optionName span {
  float: left;
  width: calc(100% - 25px);
  text-align: left;
  white-space: nowrap;
}

.optionName img {
  float: right;
  width: 19px;
  text-align: right;
  margin-top: 1px;
}

// .optionName img {
//   display: none !important;
// }

@mixin styling() {
  border-radius: 4px;
  cursor: pointer;
  width: calc(100% - 4px);
  position: relative;
  z-index: 0;
  padding-top: 10px;
  margin: 0 auto 10px;
}

/* DROPDOWN
============================================ */
.selectCustom {
  width: 100%;
}

.ddwrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;

  .ddheader {
    @include styling();
    // display: flex;
    // justify-content: space-between;
    cursor: pointer;
    width: 100%;

    &__title--bold {
      font-weight: bold;
    }
  }

  .ddheader__title {
    img {
      vertical-align: middle;
    }

    .ddheader__titleBold {
      background-image: url('/assets/images/polygonDropArrow.png');
      background-repeat: no-repeat;
      background-position: right 10px center;
      padding: 3px 25px 3px 13px;
      border-radius: 4px;
      font-size: 14px;
    }

    .ddheader__titleBold.inputCat {
      display: none;

      input {
        background-image: url('/assets/images/search.svg');
        background-size: 16px;
        background-position: left center;
        background-repeat: no-repeat;
        width: 100%;
        padding-left: 20px;
      }
    }

    .ddheader__titleBold.inputText {
      display: block;
    }
  }

  .ddheader__title.open {
    .ddheader__titleBold.inputCat {
      background-image: url('/assets/images/polygonUpArrow.png');
      display: block;
      background-color: rgba(27, 27, 27, 0.5);
    }

    .ddheader__titleBold.inputText {
      background-image: url('/assets/images/polygonUpArrow.png');
      display: none;
    }
  }

  .ddlist {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: 10px 15px 10px;
    margin: 0;
    width: 100%;
    margin-top: 0;
    z-index: 12;
    background: #313336;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    position: relative;
    top: -10px;
    // max-height: 162px;
    overflow-y: scroll;

    li.ddlistitem {
      list-style-type: none;
      font-weight: 300;

      &:first-of-type {
        > button {
          border-top: 1px solid #313336;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      &:last-of-type > button {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      button {
        // display: flex;
        // justify-content: space-between;
        background-color: #313336;
        font-size: 14px;
        padding: 4px 5px;
        border: 0;
        border-bottom: 1px solid #313336;
        width: 100%;
        text-align: left;
        border-left: 1px solid #313336;
        border-right: 1px solid #313336;
        cursor: pointer;
        color: #ffffff !important;

        // &:hover,
        // &:focus {
        //   cursor: pointer;
        //   font-weight: bold;
        //   background-color: #313336;
        // }
      }
    }

    li.ddlistitem.active {
      button {
        // font-weight: 500 !important;
        color: #ffffff !important;
      }
    }
  }

  .ddlist.fullHeight {
    max-height: initial;
  }
}

.trending4Explore.customDropDown {
  align-items: unset;
  justify-content: unset;
  min-width: 140px;

  .select {
    width: 100%;
  }
}

.dropItemsHolder {
  position: relative;

  ul {
    display: inline-block;
    background: #313336;
    width: 100%;
    position: relative;
    z-index: 123456;

    li {
      padding: 5px 20px;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.spinnerUL {
  width: 100%;
  text-align: center;
  overflow-y: scroll;
  max-height: 100px;

  li {
    font-size: 12px;
    padding: 1px 0;
    cursor: pointer;
  }

  li.active {
    color: var(--primary-color);
  }
}

.noPosts {
  float: left;
  width: 100%;
  text-align: center;
  padding: 200px 0;
}

.notConnected {
  float: left;
  width: 100%;
  min-height: 495px;
  text-align: center;
  padding: 100px 0;
  //background: #1a1927;
  background: var(--primary-bg);
  border-radius: 10px;
  div {
    width: fit-content;
    margin: auto;
  }

  h1 {
    font-weight: 400;
    font-size: 27px;
    line-height: 37px;
    color: #ffffff;
    margin-top: 50px !important;
    width: 400px;
    margin: auto;
  }

  p {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #bbbbbb;
    margin-top: 20px !important;
    width: 400px;
    margin: auto;
  }
  .buttons {
    display: flex;
    width: 600px;
    gap: 50px;
    a {
      width: 100%;
    }
  }

  button {
    margin: auto;
    padding: 20px;
    width: 100%;
    font-size: 18px !important;
    font-weight: 500;
    margin-top: 50px;
    border-radius: 10px;
  }

  @include mobile {
    .buttons {
      display: block;
      width: 90%;
      gap: 50px;
    }
    h1 {
      font-size: 24px;
      width: 100%;
    }

    p {
      font-size: 12px;
      width: 100%;
    }

    button {
      font-size: 12px !important;
    }
  }
}

ul li.loginCredHeaderHold .handleDivide:first-child button {
  border: 2px solid #111;
}

.handleDivide {
  float: left;
  margin-left: 5px;

  button {
    background: #0998b9;
    color: #000;
    height: 36px;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 5px;
  }

  button.loginDetails {
    //background: #2c2a38;
    background: #403a59;
    color: #fff;
    border: solid 1px #2c2a38;
    img {
      vertical-align: middle;
      margin-right: 5px;
      border: 1px solid #3a3a3a;
      border-radius: 100px;
      overflow: hidden;
    }
    span {
      opacity: 0.5;
      padding: 0 2px;
    }

    .primary {
      color: #01cdfd;
      opacity: 1;
      font-weight: 500;
    }
    &:hover {
      border: solid 1px var(--primary-color);
    }
    &:active {
      background: var(--primary-color);
    }
  }
}

.handleDivide.currency {
  float: right;
  margin: 0;
  position: relative;
}

.handleDivide.currency button.loginDetails {
  border-radius: 0px 6px 6px 0px;
  margin-left: -5px;
}

.currency.handleDivide {
  button.loginDetails {
    color: #01cdfd;
    font-size: 14px;
    padding: 2px 6px;
    height: 36px;

    img {
      vertical-align: middle;
      margin-left: 3px;
    }
  }

  .showNftDropdown {
    width: 85px;
    text-align: center;
    right: 0;
    padding: 4px 0 10px;
    background: #000000;
    left: auto;

    li {
      a {
        display: inline-block !important;
        font-size: 10px;
        margin: 0;
        width: 100%;
        text-align: center !important;

        &:hover {
          color: #01cdfd !important;
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .headerText h3.exploreHeading {
    padding-left: 0;
  }
}
@media (max-width: 1480px) {
  .exploreList ul li {
    width: calc(50% - 20px);
  }
  .logo {
    position: initial;
  }

  .wrap {
    justify-content: space-between;
  }

  ul.menu.mneuValues {
    position: initial;
  }
}

@media (max-width: 1300px) {
  .trendingNewMenu.alside {
    margin-right: 30px;
  }

  .detailTrendingLeft {
    min-width: 350px;
  }
}

@media (max-width: 1250px) {
  .promotedSectionInner .whitelist {
    display: block;
    position: relative;
  }

  .promotedSectionInner .whitelist p:last-child {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    margin-top: 8px;
  }

  .promotedSectionInner .launchLoader {
    margin: 35px 0 10px;
  }

  .promotedSectionInner .launchlist h6 {
    padding-bottom: 32px;
  }
}

@media (max-width: 1230px) {
  .subSearch {
    width: 360px;
  }
  .sideBarTop {
    padding: 15px 15px 10px;
  }
  .sideBarTop ul li:first-child a {
    padding-left: 0;
  }
  .header,
  .mobileHeader {
    padding: 3px 15px;
  }
  .navbar .menu > li {
    padding: 3px 6px;
  }
}

@media (max-width: 1150px) {
  .textHub h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .textHub p {
    margin-top: 10px;
  }

  .discoverBannerBtn {
    margin-top: 20px;
  }

  .textHubCard {
    width: 100%;
  }

  .textHubValue button {
    height: auto;
    height: 40px;
    font-size: 11px;
    width: 100px;
  }

  .textHubValue {
    width: 82%;
    padding: 15px;
    margin: auto;
    //margin-right: 4%;
    text-align: center;
    height: 64px;
    bottom: -25px;
  }

  .textHubValue h6 {
    font-size: 16px;
  }

  .textHubValue label p {
    justify-content: center;
    font-size: 11px;
  }
}
@media (max-width: 1120px) {
  .mynftInner {
    display: block;
  }

  .mynftSideNav {
    margin: 0 0 20px;
  }

  .mynftSideNav ul {
    display: flex;
    justify-content: space-between;
  }

  .mynftSideNav ul li {
    width: calc(25% - 10px);
    //background: #272734;
    background: #1f1c41;
    border-radius: 6px;
    padding-left: 20px;
  }
  .mynftHub {
    width: calc(100% - 150px);
  }
}

@media (max-width: 1080px) {
  .detailTrendingLeft {
    min-width: 280px;
  }
}

@media (max-width: 1040px) {
  .otherNft ul li {
    width: calc(33.33% - 30px);
  }
}

nav.navbar ul.menu li:first-child {
  display: none;
}

// nav.navbar ul.menu li:last-child {
//   display: block;
// }
@media (max-width: 1024px) {
  .collectionTrendingList.trendingInfo .colletionListingSet ul {
    margin: 0;
    flex-wrap: wrap;
    top: 0 !important;
  }

  .colletionListingSet ul li {
    width: calc(25% - 12px);
    display: flex;
    margin-bottom: 12px !important;
    padding: 15px 20px;
  }

  .collectionTrendingList.trendingInfo .colletionListingSet ul span {
    padding: 0;
  }

  .colletionListingSet ul li:last-child {
    width: 100%;
    flex-direction: initial;
    align-items: center;
    justify-content: space-between;
  }

  .collectionTrendingList.trendingInfo .colletionListingSet ul li ul li {
    width: auto;
    padding: 0;
    margin: 0 20px 0 0 !important;
  }
  .headerText h3.exploreHeading {
    padding-left: 0;
  }
  .exploreList ul li {
    width: 100%;
  }
  .sideBarTop {
    display: none;
  }
  .newFilterJey.collectionHub {
    margin-top: 85px;
  }

  .headerDisconnect.showContent {
    box-shadow: none;
    padding-bottom: 4px;
  }
  ul.menu.mneuValues {
    float: left;
  }
  .currency.handleDivide .showNftDropdown {
    width: calc(100% - 40px);
    margin-left: 20px;
    border-radius: 6px;
  }
  ul.menu.mneuValues li.loginCredHeaderHold {
    display: none !important;
  }
  nav.navbar {
    display: block;
  }
  .logo {
    width: 50px;
  }

  .logo a {
    display: block;
  }

  img.logo_img {
    display: block;
    width: 100%;
  }
  .headerDisconnect {
    position: inherit;
    border: none;
    height: auto;
    margin: 0;
    padding: 20px;
    @include mobile {
      padding: 0 !important;
      .headerDisconnectTop {
        button {
          margin-top: -10px !important;
          margin-bottom: 50px !important;
        }
      }
      section > a {
        display: none !important;
      }
    }
  }

  .mobileHeader .headerDisconnectTop span img {
    max-width: 24px;
  }

  .headerDisconnectTop button {
    padding: 8px !important;
    background: #292727 !important;
    border: none !important;
    color: #b83e93 !important;
    font-weight: 500 !important;
    height: auto !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    width: 100% !important;
  }

  .headerDisconnectBottom {
    flex-direction: column;
    margin-bottom: 7px;
  }

  .headerDisconnectBottom img {
    max-width: 14px;
  }

  .headerDisconnectBottom a {
    display: flex !important;
    width: 100%;
    padding: 12px !important;
    background: #292727 !important;
    border: none !important;
    font-size: 18px !important;
    margin-top: 10px;
    min-height: 40px;
  }

  .headerDisconnectTop {
    margin-bottom: 0;
  }

  .headerDisconnectBottom + button {
    background: #01cdfd !important;
    width: 100% !important;
    color: #111 !important;
    font-weight: 600 !important;
    margin: 0 !important;
    padding: 8px !important;
    height: auto !important;
  }

  .handleDivide.currency button.loginDetails {
    border-radius: 6px;
  }
  li.loginCredHeaderHold .loginStyleHeader .handleDivide .shadebtn {
    display: none;
  }
  .mynftInner section {
    flex-direction: column;
    float: left;
    width: 100%;
  }
  .mynftSideNav {
    margin-top: 20px;
  }
  .mynftLeft {
    width: 100%;
  }

  .mynftRight {
    padding: 0;
    width: 100%;
  }
  .trendingFiltersExplore .trending3 {
    width: 100%;
    padding-left: 15px;
  }

  .trending4 {
    display: none;
  }

  .trending5 {
    display: none;
  }

  .trendingFiltersExplore .trending3 span {
    justify-content: flex-start;
  }

  .trendingFiltersExplore .trending3 input {
    width: 100%;
  }
  .trending1Explore {
    width: 100%;
  }
  .mynftLeft {
    width: 100%;
  }

  // .trending1Explore .mobWidth span {
  //     width: 50%;
  // }
  .trendingNewMenu {
    display: none;
    @include mobile {
      display: inline-block;
    }
  }
  .nftexploreMain .trendingFiltersRight {
    padding-left: 0;
    width: calc(100% - 0px);
    margin-top: 20px;
  }
  .NotLive {
    max-width: 100%;
  }
  .loginStyleHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
  }
  .discoverBannerBtn {
    margin-top: 30px;
  }

  .mobileHeader {
    .wrap {
      .loginCredHeaderHold {
        width: 100%;
        //position: absolute;
        //top: -170px;
        //left: 0;
        margin: 10px 0 0 0;

        div {
          //width: 100%;
          @include desktop{
            margin-left: 0;
          }

          img {
            width: auto;
          }
        }

        .loginStyleHeader {
          float: left;
          width: 100%;

          .handleDivide {
            button {
              font-size: 13px;
              width: calc(100% - 40px);
              margin: 3px 10px;
              display: flex;
              align-items: center;
            }
          }

          .currency.handleDivide {
            button.loginDetails {
              margin: 3px auto;
              display: block;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .mobileHeader:before {
    position: fixed;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    //filter: blur(3px);
    backdrop-filter: blur(5px);
    z-index: 1;
  }

  .mobileHeader a.menu_icon {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }

  .navbar > div {
    display: block;
  }

  .dropdown_list {
    padding: 0 0px !important;
    margin: 0 !important;
  }

  .dropdown_list > a {
    padding: 15px 20px !important;
  }

  .dropdown_list .showNftDropdown {
    margin: 0 !important;
    padding: 0 20px !important;
    background: #2b2b2b !important;
  }

  .navbar .menu li a sup {
    right: auto;
    top: 23px;
  }

  .showNftDropdown {
    position: static;
    width: 100%;
    padding: 12px 20px;
    border-radius: 0px;
  }

  .showNftDropdown ul {
    width: 100%;
  }

  .showNftDropdown li a {
    margin: 23px 0;
  }

  .icn_cloz {
    display: block !important;
  }

  // .mobileHeader .wrap div img {
  //   background: #1b1b1b;
  // }

  .tbleAbout h6 {
    padding: 10px 10px;
  }

  .tbleAbout table tr th {
    font-size: 12px;
    padding: 10px 2px 10px 10px;
  }

  .tbleAbout table tr td {
    padding: 10px 2px 10px 10px;
    font-size: 12px;
  }

  .tbleAbout table tr td img {
    //width: 12px;
    width: 100%;
    vertical-align: middle;
    @include mobile {
      width: 24px;
      float: right;
    }
  }

  .mobileHeader .navbar {
    right: 0;
    /* overflow-x: hidden;
    overflow-y: scroll; */
  }

  .navbar .menu li:last-child {
    padding: 0;
  }

  .navbar {
    position: fixed;
    transition: all 0.4s ease-in-out;
    background: $theme_dark_black_color;
    right: -100%;
    top: 0;
    width: 300px;
    height: 100vh;
    padding-top: 20px;
    overflow: auto;
    z-index: 2;
    border-left: 5px solid rgba(238, 238, 238, 0.1490196078);
    .trendingNewMenu {
      background: $theme_dark_black_color;
    }
    @include mobile {
      overflow: unset;
    }
  }

  .mobileHeader .navbar > div {
    margin-left: 20px;
  }

  .menu_icon {
    display: block;
  }

  .wrap {
    max-width: 100% !important;
    position: initial;
  }

  header a.menu_icon {
    //position: absolute;
    //right: 20px;
  }

  .navbar .menu {
    margin: 0;
    margin-top: 15px;
    padding: 0;
    list-style-type: none;
    position: relative;
    @include mobile {
      display: none;
    }
  }

  .navbar .menu li {
    list-style-type: none;
    display: block;
    text-align: left;
    z-index: 2;
    text-decoration: none;
    color: hsla(0, 0%, 100%, 0.9) !important;
    font-weight: 700;
    background: no-repeat;
    padding: 0px 0px;
    margin: 0 10px;
    clear: both;
  }

  .navbar .menu li a {
    display: block;
    text-decoration: none;
    padding: 15px 10px;
    transition: all 0.2s ease;
    font-weight: normal !important;
    font-size: 14px;
    color: #919191;
  }

  .navbar .menu li a:hover,
  .navbar .menu li.nftMainActive a {
    color: var(--primary-color) !important;
    background: transparent;
  }

  .navbar .menu li:last-child {
    float: none;
  }

  .logReg {
    display: block;
  }

  .nftActive {
    margin-left: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .nftFarmMain {
    .nftBenefitsHold.trending {
      .nftBenefits {
        h2 {
          font-size: 18px;
        }

        .nftBenefitBlockHold {
          .nftBenefitBlock {
            width: 100%;

            .nftBenefitImage {
              margin: 0 auto 20px;
            }

            .nftBenefitContent {
              width: 100% !important;

              h4 {
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .welcomeTexthandle .comingSoon {
    float: left;
  }

  .welcomeTexthandle .comingSoon img {
    width: 130px;
  }

  .discoverBannerBtn .trending3Explore {
    margin-top: 10px;
  }

  .Contract_box_flow_2 .builder_wrap .builder_box {
    width: calc(33.33% - 10px);
    margin: 0 5px;
  }

  .footerPrimary h3 {
    width: 100%;
  }

  .trending1Explore .mobWidth {
    width: 100%;
  }

  .nftexploreMain .trendingFiltersLeft {
    position: static;
    top: auto;
  }

  .footerSecondary1 ul,
  .footerSecondary2 ul {
    line-height: 18px;
  }

  .footerSecondary1 ul li a,
  .footerSecondary2 ul li a {
    font-size: 12px;
  }

  .footerPrimary ul li a img {
    width: 20px;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending1Explore {
    float: left;
    width: 49.5%;
    margin-right: 1%;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending2Explore {
    float: left;
    width: 49.5%;
    margin-right: 0;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending3Explore {
    margin-right: 1%;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending3Explore,
  .trendingFiltersHomeTrending .trendingFiltersLeft .trending4Explore {
    float: left;
    width: 49.5%;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerPrimary h3 {
    width: 70%;
  }
}

@media (max-width: 1299px) {
  .detailedIcons {
    width: 40px;
    top: 10px !important;
    right: 15px !important;
  }

  .detailTrendingRight {
    padding-left: 35px;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .nftexploreMain .trendingFiltersRight {
    padding-left: 0;
  }
  .detailTrendingRight {
    padding-left: 35px;
    width: 100%;
  }

  .dtLeft h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .trendingInfo li {
    width: calc(33.33% - 20px);
    margin: 0px 10px 20px 10px;
  }
  .trendingInfo ul {
    margin: 0 -10px;
  }
}

@media (max-width: 1024px) {
  .nftexploreMain .trending.collectionListing {
    padding-top: 50px;
  }
  .newFilterJey {
    //display: none;
  }
  .trendingInfo li {
    width: calc(50% - 20px);
    margin: 0px 10px 20px 10px;
  }
  nav.navbar ul.menu li:last-child {
    display: block;
    padding: 15px 10px;
  }
  nav.navbar ul.menu li:first-child {
    display: block;
    position: initial;
    float: left;
  }
  li.loginCredHeaderHold .loginStyleHeader .handleDivide .shadebtn {
    display: none;
  }
  //   ul.sidemenuBar {
  //     display: none;
  // }
  .trendingNewMenuLabel {
    text-align: left;
    padding: 15px 20px;
    cursor: pointer;
    position: relative;
    margin: 0;
  }
  .trendingNewMenuLabel + ul {
    padding-top: 0;
  }
  /*
  .trendingNewMenuLabel:before {
    content: '';
    width: 10px;
    height: 10px;
    border-left: 2px solid white;
    position: absolute;
    right: 30px;
    top: 20px;
    transform: rotate(225deg);
    border-top: 2px solid white;
  }
  .trendingNewMenuLabel.Actsidebar:before {
    content: '';
    top: 25px;
    transform: rotate(45deg);
  }
  */
  .trendingNewMenu ul:last-child {
    border-top: 1px solid #3a3a3a;
  }
  .trendingNewMenu {
    position: initial;
    top: 0;
    margin: 10px 0 0 !important;
    width: 100%;
    border-top: 1px solid #3a3a3a;
    border-radius: 0;
  }

  .trendingNewMenu ul li a {
    padding-left: 0px;
  }
  .trendingFiltersNewHub {
    width: initial;
    justify-content: space-between;
  }
  .trending2Explore {
    min-width: 150px;
  }
  .trendingFiltersNew {
    overflow: auto;
  }

  .trending4ExploreHub {
    width: 100%;
  }

  .trending4Explore {
    margin: 0 0px 0px 10px;
  }
  .trending1Explore div span {
    min-width: 120px;
  }
  .nftexploreMain .trending .trendingFiltersExplore .trendingFilters {
    margin-bottom: 10px;
  }
  .showContent .exploreHeading {
    padding: 10px 0 20px;
  }
}

@media (max-width: 991px) {
  .promotedSectionInner .launchCompletedSlider {
    display: block;
  }

  .promotedSectionInner .launchCompletedSliderInner {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 20px;
  }
  .previewStep2 ul.trendingInfoGrid li {
    width: calc(33.33% - 20px) !important;
    float: left;
    margin: 10px 10px !important;
  }
  .trending3Explore label {
    width: 65px;
  }
  .mobWidth {
    width: 100%;
  }

  .dtLeft p {
    width: 100%;
  }

  .nftexploreMain .trendingFiltersLeft {
    width: 100%;
  }

  .discoverBannerBtn {
    max-width: 410px;
  }
  .discoverBannerBtn .trending3Explore {
    width: 100%;
    margin-left: 0;
  }
  input.searchBtn {
    display: block;
    margin: 20px auto 0;
  }
  .trendingInfo ul {
    justify-content: flex-start;
  }
  .trendingInfo li {
    width: calc(33.33% - 20px);
    margin-bottom: 25px !important;
    margin: 0 10px;
  }
  .trendingInfo .trendingInfoGrid li {
    width: calc(25% - 20px);
    margin: 0 10px;
    margin-bottom: 25px;
  }

  section.newFilterJeyInner {
    max-width: 100%;
    width: 100%;
    margin: 0 0 20px;
    position: initial;
  }
}

@media (max-width: 990px) {
  .mintBondContain {
    flex-direction: column;
  }
  .mintBondHoldLeft {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .mintBondHoldRight {
    width: 100%;
  }
  .mintBondHoldRight ul.payout li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ustMint {
    display: none;
  }
  .mintBondHoldLeft .ustMint {
    display: block;
    margin-left: 15px;
  }
}

@media (max-width: 1150px) {
  .textHub {
    flex-direction: column;
  }

  .textHubCard {
    position: inherit;
  }

  .welcomeTexthandle {
    text-align: center;
  }

  .welcomeTexthandle .welcomeText {
    float: none;
  }

  .discoverBannerBtn {
    margin: 20px auto;
    float: none;
  }

  .discoverBanner {
    height: auto;
    padding-bottom: 10px;
  }
}
@media (max-width: 800px) {
  .nftRightChild span {
    width: 120px;
    padding: 5px;
  }

  .nftRightChild {
    padding: 10px;
  }

  .mynftHub {
    padding: 0;
    width: calc(100% - 130px);
  }
}

@media (max-width: 768px) {
  .nftexploreMain .trendingFiltersLeft {
    margin-bottom: 00px;
  }
  .currency.handleDivide {
    .showNftDropdown {
      width: calc(100% - 40px);
      margin: auto;

      li a {
        font-size: 12px;
      }
    }
  }
}

.nftFarmMain {
  .trending {
    padding: 210px 0 10px;

    h2 {
      text-align: left;
      color: white;
      margin: 0;
      font-family: 'shapiro65_light_heavy_wide';
    }
  }
}

.farmBlockHead {
  .farmBlockHeadControl {
    .learnMore {
      padding: 0;
      margin: 0;
      text-transform: uppercase;
      color: #919191;
    }

    h2 {
      padding: 0;
      font-size: 42px;
      font-weight: normal;
      line-height: 42px;
      margin-bottom: 10px;
    }

    p {
      padding: 0;
      margin: 0;
      color: #919191;
    }
  }

  .videoFarmFeatured {
    margin: 60px 0;
    position: relative;

    video {
      width: 100%;
      border-radius: 22px;
    }

    .videoPlayBtn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      cursor: pointer;
      display: inline-block;
      margin-top: 20px;
    }
  }
}

.nftFarmMain {
  .nftBenefitsHold.trending {
    padding: 70px 0;
    background: #1b1b1b;

    .nftBenefits {
      * {
        color: #fff;
      }

      h2 {
        text-align: center !important;
        margin-bottom: 60px;
      }

      .nftBenefitBlockHold {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .nftBenefitBlock {
          background: #212326;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05),
            0px 6px 40px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          display: flex;
          width: 48%;
          margin-right: 2%;
          margin-bottom: 20px;
          padding: 30px 30px;

          &:nth-child(2n + 2) {
            margin-right: 0;
          }

          .nftBenefitImage {
            margin-right: 20px;
            display: flex;
            align-items: center;
          }

          .nftBenefitContent {
            // display: inline-block;
            // width: calc(100% - 115px);
            // vertical-align: middle;

            h4 {
              font-weight: 700;
              font-size: 19px;
            }

            p {
              font-size: 13px;
              color: #bbb;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.nftBondHold {
  // background: #212326;
  padding: 70px 0;

  .nftBond {
    background: #1b1b1b;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 30px 0;

    .nftBondHead {
      text-align: center;
      color: white;
      padding-bottom: 50px;
      margin-bottom: 40px;
      border-bottom: 1px solid #2d2d2d;

      h2 {
        font-size: 32px;
        margin: 0;
        font-family: 'shapiro65_light_heavy_wide';
        font-weight: normal;
      }

      p {
        color: #bbbbbb;
      }
    }

    .nftBondSection {
      font-size: 17px;
      padding: 0 30px;

      .nftBondPreviewYield {
        h4 {
          margin-bottom: 10px;
          font-size: 15px;
          font-weight: 300;
        }

        .preview {
          display: inline-block;
          width: 220px;
          margin-right: 15px;
          vertical-align: top;

          .trendingInfo {
            li {
              width: 100%;
              background: #2a2a2a;
              margin-bottom: 0;
            }
          }
        }

        .previewYield {
          display: inline-block;
          width: calc(100% - 235px);
          margin-right: 0;
          vertical-align: top;

          img {
            width: 100%;
          }
        }
      }
    }

    .nftBondFoot {
      padding: 40px 30px 30px;
      display: inline-flex;
      width: 100%;
      margin-top: 30px;
      border-top: 1px solid #2d2d2d;

      .bondNavigation {
        display: inline-block;
        width: 70%;
        vertical-align: middle;

        .navSteps {
          display: inline-block;
          width: 100%;

          .stp {
            display: inline-block;
            vertical-align: middle;
          }

          .stepsLine {
            background: #2d2d2d;
            height: 8px;
            width: calc(25% - 70px);
            position: relative;

            div {
              height: 2px;
              width: 104%;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -4%;
              z-index: 123;
            }

            .active {
              background: linear-gradient(
                90deg,
                var(--primary-color) 0%,
                rgba(200, 62, 147, 0) 100%
              );
            }

            .complete {
              width: 108%;
              background: linear-gradient(90deg, #32fe9a 0%, var(--primary-color) 100%);
            }

            .final {
              width: 108%;
              background: #32fe9a;
            }
          }

          .stepsHigh {
            background: #2d2d2d;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            text-align: center;
            padding: 0;
            color: #fff;
            position: relative;

            span {
              border: 2px solid transparent;
              display: inline-block;
              width: 86%;
              height: 86%;
              position: absolute;
              top: 0;
              left: 0;
              padding: 4px 0;
              text-align: center;
              border-radius: 100%;
              margin: 7%;
              color: #fff;
              font-size: 20px;
              z-index: 1023;
            }

            span.active {
              border-color: var(--primary-color);
              color: var(--primary-color);
            }

            span.complete,
            span.final {
              border-color: #32fe9a;
              color: #32fe9a;
            }

            h6 {
              font-size: 14px;
              font-weight: 400;
              position: absolute;
              left: 50%;
              right: 0;
              margin: 0 auto;
              bottom: -27px;
              transform: translate(-50%, 0);
              min-width: 70px;
            }

            h6.active {
              color: var(--primary-color);
            }

            h6.complete,
            h6.final {
              color: #32fe9a;
            }
          }
        }
      }

      .bondBtn {
        display: flex;
        width: 30%;
        vertical-align: middle;

        button.primaryBtnTask:first-child {
          margin-right: 10px;
        }

        .primaryBtnTask {
          background: var(--primary-color);
          border-radius: 10px;
          font-weight: 500;
          font-size: 18px;
          color: #000;
          padding: 14px;
          width: 100%;
        }

        .primaryBtnTask.final {
          background: #32fe9a;
        }
      }
    }
  }
}

.graphMap {
  padding-top: 40px;

  img {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .comingSoon {
    width: 100px;

    img {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  //.aucHub > section {width: 130px;}
  //.aucHub {padding-left: 150px;}

  .AucInput h5 {
    font-size: 20px;
    margin: 0px 0 20px;
  }

  .SellAuction button {
    margin-top: 30px;
  }
  .trending.collectionListing h2.showContent {
    margin-top: 50px;
  }
  .collectionTrendingList.trendingInfo
    .colletionListingSet
    ul
    li
    ul
    li:last-child {
    margin: 0 !important;
  }

  .sideListing > h6,
  .sideListing > label {
    display: none;
  }
  .colletionListingSet ul li {
    flex-direction: initial;
    width: 100%;
  }

  .collectionTrendingList.trendingInfo .colletionListingSet ul span {
    margin: 0;
    position: absolute;
    right: 40px;
    width: auto;
  }
  .mynftSideNav {
    margin-top: 120px;
  }
  .mynftSideNav ul li {
    width: calc(50% - 10px);
    margin: 5px 0;
  }

  .mynftSideNav ul {
    flex-wrap: wrap;
  }

  .mynftLeft ul li {
    width: 100%;
    margin: 5px 0;
  }

  .mynftLeft ul {
    flex-wrap: wrap;
  }
  .exploreList {
    width: 100%;
  }
  .exploreCollections {
    flex-direction: column;
  }
  .decideBalanceSplit button.primaryBtnTask {
    right: 0;
    width: 150px;
  }

  .newFilterJeyLeft {
    padding: 0;
  }

  .newFilterJeyLeft h6 {
    padding-bottom: 15px;
  }
  .newFilterJeyRight section {
    margin: 0 0 10px;
    width: calc(50% - 5px);
  }

  .newFilterJeyRight {
    display: flex;
    justify-content: space-between;
  }

  .jeyDrops {
    width: calc(33.33% - 6px);
  }
  .detailTrendingLeft h5 {
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-transform: capitalize;

    /* white - text #FFFFFF */
    color: #ffffff;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .detailTrendingLeft section ul {
    display: flex;
    flex-wrap: wrap;
  }

  .detailTrendingLeft section ul li {
    width: 50%;
  }
  .otherNft ul li {
    width: calc(50% - 30px);
  }
  .exploreList ul {
    flex-direction: column;
    margin: 0;
  }

  .exploreList ul li {
    width: 100%;
    margin: 0 0 20px;
  }
  .dtRight div.pointsHoldButton div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .NotLive {
    text-align: left;
    padding-left: 5px;
  }
  .trendingFiltersHomeTrending {
    max-width: 440px;
    margin: 0 auto;
  }
  .mintBondHoldRight ul.payout li p {
    text-align: right;
    width: 50%;
  }
  .mintBondHoldRight ul.payout li p.head {
    text-align: left;
  }
  .ustMint h3 {
    background: transparent;
    padding: 0;
    width: auto;
  }
  .nftBondHold {
    h2 {
      font-size: 25px !important;
      padding: 0 15px !important;
      line-height: initial;
      margin-bottom: 15px !important;
      display: block;
    }

    .nftBondHead {
      padding-bottom: 30px !important;
      margin-bottom: 20px !important;
    }
  }

  .decideFarmManage {
    display: none;
  }
  .FarmManageMobile {
    display: block;
  }
  .decideBalance {
    max-width: 100%;
  }
  .discoverBannerBtn {
    min-height: 1px;
  }
  .comingSoon {
    min-height: 1px;
  }

  .discoverBanner .bannerText .textHub {
    width: 440px;
    margin: 0 auto;
  }
  .nftBondFoot {
    padding: 25px 15px !important;
  }
  .previewStep2 ul.trendingInfoGrid li {
    width: calc(50% - 20px) !important;
    margin: 10px !important;
  }
  // .trendingInfo ul.trendingInfoGrid {
  //   display: block !important;
  // }
  // .nftBondPreviewYield .preview {
  //   ul li{    margin: 10px !important;
  //     width: calc(33.33% - 20px) !important;
  //     float: left;}
  // }
  .preview {
    margin: 0 auto 20px !important;
    display: block !important;
  }
  .previewYield {
    width: 100% !important;
  }
  .ddheader__title {
    .ddheader__titleBold {
      font-size: 14px;
    }
  }
  .trending4Explore:last-child {
    margin-bottom: 10px;
  }
  .trending1Explore .mobWidth {
    span {
      margin: 0;
    }
  }
  .optionSet {
    margin: 0;
  }
  .expander {
    opacity: 1;
  }
  .tbleAbout table {
    //display: none;
    display: block;
    overflow: scroll;
  }
  .detailMobileTable {
    display: block !important;

    tr {
      width: 100%;
    }

    td {
      width: 100%;
    }
  }
  .discoverBannerBtn {
    margin-top: 30px;
  }

  .nftFarmMain {
    .trending {
      padding: 110px 0 10px;
    }
  }

  .farmBlockHead {
    .farmBlockHeadControl {
      h2 {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 5px;
      }

      p {
        padding: 0;
        margin: 0;
        color: #919191;
      }
    }

    .videoFarmFeatured {
      margin: 40px 0;
      position: relative;

      video {
        width: 100%;
        border-radius: 22px;
      }

      .videoPlayBtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        cursor: pointer;
        display: inline-block;
      }
    }
  }

  .nftFarmMain {
    .nftBenefitsHold.trending {
      .nftBenefits {
        h2 {
          font-size: 18px;
        }

        .nftBenefitBlockHold {
          .nftBenefitBlock {
            margin-right: 0;
            width: 100%;

            .nftBenefitContent {
              width: 100%;

              h4 {
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .trending2Explore {
    width: 226px;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .trending1Explore .mobWidth span:first-child {
    margin-right: 10px;
  }

  .welcomeTexthandle .comingSoon img {
    width: 65px;
    margin-top: 10px;
  }

  .Contract_box_flow_2 .heading_box_wrap {
    display: inline-block;
  }

  .Contract_box_flow_2 .builder_wrap {
    display: inline-block;
    width: 100%;
  }

  .builder_wrap .builder_box {
    margin: 0 0 20px;
    width: 100%;
  }

  .discoverBannerBtn .trending3Explore {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .nftexploreMain .trendingFiltersLeft {
    position: static;
    top: auto;
  }

  .footerPrimary h3 {
    width: 100%;
  }

  .trending1Explore div .optionSet select {
    background-position: bottom 25px center !important;
  }

  .trending1Explore .mobWidth {
    width: 100%;
  }

  .nftexploreMain .trendingFiltersLeft {
    padding-bottom: 0;
  }

  .nftexploreMain .trendingFiltersRight {
    margin-top: 0;
  }

  .nftexploreMain .trending .trendingFiltersExplore .trendingFilters {
    border-bottom-right-radius: 15px;
  }

  .nftexploreMain .trendingFiltersLeft {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending1Explore {
    float: left;
    width: 100%;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending2Explore {
    float: left;
    width: 100%;
  }

  .trendingFiltersHomeTrending .trendingFiltersLeft .trending3Explore,
  .trendingFiltersHomeTrending .trendingFiltersLeft .trending4Explore {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .trendingFiltersHomeTrending
    .trendingFiltersLeft
    .trending1Explore
    .optionSet
    select {
    background-position: bottom 4px center !important;
  }

  .detailTrendingLeft {
    width: 100% !important;
  }

  .detailTrending {
    display: block;
  }

  .detailTrendingRight {
    padding-left: 0;
    margin-top: 20px;
    width: 100%;
  }

  .trendingInfo ul.trendingInfoGrid li {
    width: calc(33.33% - 15px);
    margin-bottom: 20px;
    margin: 7px;
  }

  .trendingInfo ul.trendingInfoGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .promotedSectionInner h2 {
    font-size: 18px;
    margin-bottom: 45px;
  }

  .promotedSectionInner ul li p {
    margin-bottom: 5px;
  }

  .creator ul li {
    width: 50%;
  }

  .category {
    padding: 46px 15px 36px;
  }

  .category .wrapper {
    padding: 0;
  }

  .trending {
    padding: 40px 0;
  }

  .trending h2 {
    margin-bottom: 30px;
    font-size: 22px;
  }

  .trendingInfo li {
    width: calc(50% - 20px);
  }

  .promotedSection {
    padding: 48px 0 39px;
  }

  .promotedSectionInner ul {
    display: block;
  }

  .promotedSectionInner ul li {
    width: 400px;
    padding-top: 5px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .promotedSectionInner ul li label {
    font-size: 20px;
  }

  .nftexploreMain .trending .trendingFiltersExplore .trendingFilters {
    display: flex;
    margin-bottom: 00px;
    flex-wrap: wrap;
  }

  .trending .trendingFilters {
    flex-wrap: wrap;
  }

  .trendingFiltersExplore .trending3 {
    width: calc(100% - 0px);
    margin-bottom: 10px;
  }

  .trendingFiltersExplore .trending4 {
    width: 100%;
  }
  .trendingFiltersExplore .trending5 {
    border-radius: 8px;
    width: 100px;
    margin: 10px auto 0;
  }
  .trending1Explore div span {
    margin-bottom: 0;
  }
  .trending1Explore div.mobWidth span:first-child {
    margin-bottom: 10px;
  }
  .trendingFiltersExplore .trending5 {
    width: 100%;
  }
  .trending5 button.gridActive {
    padding: 18px 10px;
  }

  .footerPrimary {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }

  .footerSecondary {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }

  .footerc {
    padding: 50px 0;
  }

  .nftexploreMain .trending h2 {
    padding: 20px 0;
    font-size: 22px;
  }

  // .trending2Explore,
  // .trending1Explore div span,
  // .trending3Explore,
  // .trending4Explore,
  // .trending3,
  // .trending4 {
  //   height: 40px !important;
  // }
  .textHub h1 {
    font-size: 26px;
    width: auto;
    line-height: 38px;
  }

  .textHub p {
    width: auto;
    font-size: 15px;
  }

  .discoverBannerBtn a {
    padding: 10px;
    font-size: 15px;
    min-width: calc(50% - 8px);
    @include mobile {
      width: 100%;
      margin-top: 10px;
    }
  }

  .discoverBannerBtn .trending3Explore {
    height: 42.5px;
  }
  input.searchBtn {
    width: 285px;
    padding: 18px 50px;
    background-position: left 24px center;
  }

  .dtLeft .dtrow {
    display: block;
  }

  .dtRight {
    width: 100%;
    margin-bottom: 25px;
    margin-top: 25px;
    padding-left: 0;
  }

  .dtRight span {
    justify-content: flex-start;
  }

  .dtRight button {
    width: 170px;
    float: none;
    margin: 0 auto;
    display: block;
  }

  .dtRight b {
    font-size: 16px;
    margin-bottom: 0;
  }

  .dtLeft p {
    font-size: 15px;
  }

  .trending1Explore div .optionSet i {
    bottom: 22px;
  }
}

.detailTrendingRight2 p:nth-child(1) b,
.detailTrendingRight2 p:nth-child(2) b {
  color: #01cdfd;
}

.icn_cloz {
  background-color: #292727;
  width: 40px !important;
  height: 30px;
  display: none;
  border-radius: 7px;
  font-size: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.icn_cloz:before {
  position: absolute;
  color: #fff;
  content: '+';
  font-size: 32px;
  line-height: 27px;
  width: 100%;
  top: 8px;
  left: 9px;
  transform: rotate(45deg);
}

// .sidebarActiveBg {
//   filter: blur(3px);
//   -webkit-filter: blur(3px);
// }

@media (max-width: 680px) {
  .waitApprovalPopup img {
    width: 30px;
  }

  .waitApprovalPopup h6 {
    margin-top: 20px;
    font-size: 20px;
  }

  .waitApprovalPopup button {
    margin-top: 50px;
  }
  .nftRightChild {
    flex-direction: column;
  }

  .nftRightChild span {
    width: 100%;
  }

  .mynftHub {
    width: 100%;
    margin-top: 15px;
  }
}

@media (max-width: 560px) {
  .mynftHubHedare {
    flex-direction: column;
  }

  .mynftHubHedare label {
    width: 100%;
    justify-content: center;
    margin-bottom: 5px;
  }

  .nftButtons {
    width: 100%;
    display: block;
    margin-top: 10px;
    button:last-child {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .nftButtons button {
    width: 100%;
    height: 52px;
  }

  .nftData label {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .trendingInfo ul {
    margin: 0;
  }
  .trending.collectionListing h2.showContent {
    margin-top: 0;
  }
  .nftexploreMain .trending.collectionListing {
    padding: 0;
  }
  .mynftSideNav {
    margin-top: 40px;
  }
  .jeyDrops {
    margin-bottom: 10px;
  }
  .newFilterJey.collectionHub {
    margin-top: 0;
  }
  .newFilterJeyLeft section .jeyDrops:last-child {
    margin: 0;
  }
  .detailTrendingLeft section ul li {
    width: 100%;
  }

  .detailTrendingLeft section {
    padding: 20px 0;
  }

  .detailStakeSell section {
    flex-wrap: wrap;
  }

  .detailStakeSellLeft {
    width: 100%;
    padding-bottom: 15px;
  }

  .detailStakeSellRight {
    width: 100%;
    padding: 20px 0 0;
    border: none;
    border-top: 1px solid #29292e;
  }
  .nftBondFoot {
    flex-direction: column;
  }

  .bondNavigation {
    width: 100% !important;
  }

  .bondBtn.bothbtn.primaryBtnTask {
    width: 100% !important;
    margin-top: 50px;
  }
  .otherNft ul li {
    width: calc(100% - 30px);
  }
  .mynftLeft ul li a {
    min-height: 90px;
    padding: 0 20px;
  }
  .mintBondHoldRight ul.payout li p {
    width: 100px;
  }
  .nftBondHold .nftBond .nftBondSection {
    padding: 0 15px;
  }
  .mintBondHoldRight ul.payout li p.head {
    width: calc(100% - 100px);
  }
  .mintBondHoldRight ul.payout li {
    padding-right: 0;
  }
  .ustMint h3 {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .trendingInfoGrid li {
    border-radius: 14px !important;
  }
  .nftBondHold .nftBond .nftBondFoot .bondNavigation .navSteps .stepsLine {
    width: calc(25% - 38px);
  }
  .stepsHigh {
    width: 30px !important;
    height: 30px !important;

    h6 {
      width: 70px;
      left: -20px;
    }

    span {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
  .farmingPeriodHold {
    flex-direction: column;
  }

  .farmingPeriod {
    width: 100%;
    margin: 5px 0;
  }
  .bondNavigation {
    width: 100% !important;

    .navSteps {
      text-align: center;
    }
  }
  .bondBtn {
    width: 100% !important;
    margin-top: 50px;
  }
  .Contract_box .heading_box p {
    font-size: 15px;
    line-height: 22px;
  }

  .Contract_box .heading_box h3 {
    font-size: 22px;
    line-height: 30.76px;
  }

  .Contract_box .btn_box a img {
    width: 30px;
  }

  .Contract_box .btn_box {
    padding: 25px 25px;
    border-radius: 20px;
    @include mobile {
    padding: 5px;
    }
  }

  .Contract_box .btn_box a.connect {
    font-size: 16px;
  }

  .showContent .exploreHeading {
    padding: 40px 0 40px;
    font-size: 22px !important;
    line-height: 30px;
    text-align: center;
  }

  .trendingInfo ul.trendingInfoGrid li {
    width: calc(50% - 15px);
  }

  .nftexploreMain .trendingInfo li {
    width: 290px;
    @include mobile {
      width: 100%;
    }
  }

  .category ul li a {
    font-size: 15px;
    padding: 5px 25px;
  }

  .trendingInfo li {
    width: 280px;
    margin: 0 auto 30px !important;
  }

  .category ul li a img {
    width: 30px;
  }

  .category h2 {
    font-size: 18px;
  }

  .promotedSectionInner ul li label {
    font-size: 16px;
  }

  .promotedSectionInner ul li {
    width: 300px;
  }

  .dtLeft p {
    width: 100%;
  }

  .trendingInfoList h2 {
    font-size: 22px;
  }

  .trendingInfoList {
    padding: 32px 0;
  }

  .nftexploreMain .trending {
    padding: 0px 0 50px;
    margin: 0;
  }

  .creator ul li {
    width: 100%;
    margin-bottom: 50px;
  }

  header a.menu_icon svg {
    width: 35px;
  }

  header a.menu_icon {
    right: 15px;
  }

  .header,
  .mobileHeader {
    position: relative;
    padding: 10px 15px;
  }

  .footerSecondary1 {
    float: left;
    width: 100%;
  }

  .footerSecondary2 {
    width: 100%;
    display: block;
    margin-top: 15px;
  }

  .footerSecondary2 ul {
    margin-bottom: 15px;
  }

  .refreshBtn {
    max-width: 175px;
    padding: 10px;
    font-size: 14px;
  }

  .refreshBtn img {
    margin-right: 5px;
    width: 15px;
  }

  .footerLogo img {
    width: 120px;
  }

  .footer {
    padding: 50px 0 0;
  }

  .trending3 label,
  .trendingExploreValues span label,
  .trending3 input,
  .trending4 select {
    font-size: 14px;
  }

  .trending5 button img {
    width: 15px;
  }

  .dtLeft h2 {
    font-size: 20px;
  }
}
@media (max-width: 530px) {
  .newFilterJeyRight {
    flex-direction: column;
  }

  .newFilterJeyLeft section {
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }

  .jeyDrops {
    width: 100%;
  }

  .newFilterJeyLeft .newFilterJeyRight section {
    flex-direction: initial;
  }
}
@media (max-width: 470px) {
  .dtRight {
    flex-wrap: wrap;
  }

  .dtRight .pointsHold {
    width: 100%;
  }

  .pointsHoldButton {
    width: 100%;
  }

  .dtRight .pointsHold div {
    align-items: center;
    justify-content: center;
  }

  .pointsHoldButton:before {
    display: none;
  }

  .pointsHoldButton {
    border-top: 1px solid #323232;
  }
  .trendingFiltersNewHub .trending2Explore {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 420px) {
  .NotLive {
    text-align: center;
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .detailStakeSellButtons button {
    width: 100%;
    margin: 10px 0;
  }
  .Contract_box .btn_box {
    padding: 15px 15px;
  }

  .tbleAbout table tr td {
    padding: 10px 2px 10px 5px;
    font-size: 12px;
  }

  .promotedSectionInner ul li {
    width: 290px;
  }

  .Contract_box .btn_box a.connect {
    font-size: 13px;
  }

  .Contract_box .btn_box a img {
    width: 25px;
  }
}

@media (max-width: 359px) {
  .newFilterJeyRight {
    flex-direction: column;
  }

  .newFilterJeyRight section {
    width: 100%;
  }
  .stepsHigh h6 {
    font-size: 12px !important;
  }
  .Contract_box .btn_box a.connect {
    background-size: 30px;
    background-position: center right 10px;
  }
}

// to show and hide
.hideContent {
  display: none !important;
}

.showContent {
  display: block !important;
}

.showContent .exploreHeading {
  text-align: left;
  font-size: 32px;
  line-height: 30px;
  font-weight: normal;
  padding: 60px 15px 55px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @include tablet {
    padding: 15px 0;
  }
  @include mobile {
    padding: 15px 0;
  }
}

.exploreHeading {
  text-align: left;
  color: white;
  margin: 0;
  padding: 30px 0 25px;
  font-family: 'shapiro65_light_heavy_wide';
}

.backLink {
  font-size: 14px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.nftTitle {
  //display: block !important;
  display: inline-block !important;
}

.nftTitle {
  &_tier {
    //display: block !important;
    display: inline-block !important;
    background: transparent !important;
    gap: 5px;
    color: #9bb0cf !important;
  }
}

.nftTitle img {
  width: 15px !important;
}

.nftTitle .bidInfo {
  display: flex;
  align-items: center;

  background: transparent;
  color: #9bb0cf;
  font-size: small;

  img {
    margin-top: 2px;
  }

  span {
    color: white;
    padding: 5px;
    width: auto;
  }

  .separator {
    padding: 0px;
    border: 1px solid #9bb0cf;
    margin: 0px 10px;
  }
}

.nftStakeData {
  display: flex;
  align-items: center;
  background-color: #152239;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 10px;
  b {
    margin: 0px;
    color: #919191;
    font-weight: normal;
  }
  p {
    color: white;
    margin: 0px 5px;
  }
}


.nftImage {
  float: left;
  //display: flex;
  align-items: center;
  background: #ffffff08;
  border-radius: 5%;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;

  .auction-timestamp {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  /* .counterNewSet {
    img {
      width: 100%;
      height: auto;
    }
  }

  img {
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 430px;
  } */
}

.nftImage:before {
  content: '';
  float: left;
  padding-top: 100%;
}

.loadMore-button {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  padding: 16px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 7px;
  }
}

.counterNewSet {
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 35px;
  background: #1e1e2a;
  padding: 0 5px;
  border: 0.5px solid #303041;
  border-radius: 4px;
  font-size: 15px;
  img {
    filter: invert(66%) sepia(12%) saturate(5%) hue-rotate(324deg)
      brightness(86%) contrast(83%);
  }
  .counter {
    display: flex;
    flex-direction: row;
    color: #919191;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  //border: 10px solid #f3f3f3;
  //border-top: 10px solid #3498db;
  //border-radius: 50%;
  width: 100px;
  height: 100px;
  //animation: spinner 1s linear infinite;
  background-image: url('/assets/loading.gif');
  background-repeat: no-repeat;
  filter: grayscale(1);
  opacity: 0.7;
}

.loading_animation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #1b1a28 8%, #ffffff15 25%, #1b1a28 33%);
  background-size: 800px 104px;
  height: 336px;
  position: relative;
  border-radius: 20px;
  border: solid 1px #ffffff08;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.asset_loader {
  display: inline-block;
  width: 22px;
  height: 22px;
}
.asset_loader:after {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: asset_loader 1.2s linear infinite;
}
@keyframes asset_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.warning {
  background: transparent;
  padding: 10px 20px;
  color: #fff;
  border-radius: 9px;
  margin-bottom: 20px;
}

.super_loop_power_modal {
  > div {
    max-width: 500px;
  }
}

.super_loop_power_dialog {
  padding-left: 1rem;
  padding-right: 1rem;

  h2 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 1rem;
    text-align: center;
  }

  span {
    display: block;
    color: white;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: center;
  }

  div {
    display: flex;
    > button:nth-child(1) {
      margin-right: 1rem;
    }
  }
}
.noPostsLaunchpad{
  font-weight: 700;
  font-size: 20px;

}
.msg{
  margin-top: 100px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Lexend", sans-serif;
}
.hereLink{
  text-decoration: underline !important;
  color: var(--primary-color) !important;
}


li.apello {
  position: relative;
  a {
    text-decoration: none !important;
  }
}

li.apello:before {
  background: #9c2a6f;
  content: 'Apello 3rd party secondary sales tracker';
  position: absolute;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 3px;
  left: 0;
  bottom: -70px;
}

li.apello:after {
  width: 10px;
  height: 10px;
  background: #9c2a6f;
  content: '';
  position: absolute;
  left: 50%;
  top: 45px;
  transform: rotate(45deg);
  z-index: -1;
  border-radius: 3px;
}

li.apello:before,
li.apello:after {
  display: none;
}
li.apello:hover::before,
li.apello:hover::after {
  display: block;
}

.image-gallery-thumbnails {
  .image-gallery-thumbnails-container {
    transform: none !important;
  }
}
.image-gallery-slides {
  height: 100% !important;
  width: 100% !important;
  @include tablet {
    height: 450px !important;
  }
  @include mobile {
    height: 450px !important;
  }

  .image-gallery-image {
    min-height: 600px !important;
  }
}

.image-gallery-thumbnail {
  width: 79px;
  border: 0 solid transparent !important;
  border-radius: 4px;
  img {
    border: 2px solid #22203B !important;
    border-radius: 4px;
  }
  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
    margin-top: 15.36px !important;
  }
}
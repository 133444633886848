@import '../../styles/mixins';

.container {
  height: 500px;
  padding: 0 15px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  margin: 250px 150px;
  border-radius: 20px;
  //background-color: #1A1927;
  background-color: var(--primary-bg);
  justify-content: center;
  @include mobile{
    margin: 20px 20px;
  }

  .item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-content: center;

    h1{
      font-family: 'shapiro65_light_heavy_wide', 'Lexend', serif;
      font-style: normal;
      font-weight: 286;
      font-size: 24px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      align-self: stretch;
      flex-grow: 0;
      margin-top: 50px;
    }
    p{
      font-family: 'Lexend', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #BBBBBB;
      flex: none;
      flex-grow: 0;
      margin: 7px 0;
      opacity: 0.6;
    }
    .extLink{
      color: #BBBBBB;
      text-decoration: underline !important;
    }
    .btn{
      display: flex;
      align-self: center;
      background-color: transparent;
      color: #c83e93;
      border: 1px solid #c83e93;
      margin-top: 50px;
      border-radius: 5.2px;
      font-size: 13px;
      padding: 20px 10px !important;
      min-width: unset !important;
      width: fit-content !important;
      text-transform: unset !important;
      img{
        margin-left: 3px;
      }
      &:hover {
        color: #fff;
      }
    }
  }
}
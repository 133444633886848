@import '../../styles/mixins';

.notFound {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  margin: auto;

  text-align: center;

  h3 {
    font-size: 56px;
    font-weight: 800;
  }
  p {
    font-size: 14px;
    color: var(--secondary-color);
  }
  button {
    margin-top: 20px;
    padding: 20px;
  }
  @include mobile {
    h3 {
      font-size: 28px;
    }
  }
}

.rainbow {
  display: none;
  width: 100%;
  height: 100%;
  animation: o-rotate-360 linear 4s infinite;

  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(-50%, -50%);

    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100%;
    }

    &:first-child {
      background: var(--color-first);
    }

    &:first-child:after {
      background: var(--primary-color);
    }

    &:last-child {
      background: var(--primary-color);
    }

    &:last-child:after {
      background: var(--color-first);
    }
  }
}

.desktopFilter {
  display: block;
}

.mobileFilter {
  position: fixed !important;
  transition: all 0.4s ease-in-out;
  background: #1b1b1b;
  left: -100%;
  top: 0 !important;
  height: 100vh;
  padding-top: 150px !important;
  overflow: auto;
  z-index: 10000;
}

@media (max-width: 450px) {
  .mobileFilter {
    position: fixed !important;
    transition: all 0.4s ease-in-out;
    background: #1b1b1b;
    left: 0;
    top: 0 !important;
    height: 100vh;
    padding-top: 150px !important;
    overflow: auto;
    z-index: 10000;
  }

  .desktopFilter {
    display: none;
  }

  .btnFilter {
    position: fixed !important;
    right: 16px !important;
    bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 9999;
    background: linear-gradient(95.63deg, #3168F7 0%, #01CDFD 106.47%);
    border: 0.806763px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    border-radius: 32px;
    padding: 9px 15px;
  }

  .mobileFilter.show {
    display: block;
  }

  .mobileFilter.hide {
    display: none;
  }
}

@media (min-width: 451px) {
  .btnFilter {
    position: fixed !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    color: #FFFFFF;
    right: -100% !important;
    bottom: 96px;
    z-index: 9999;
    background: linear-gradient(95.63deg, #3168F7 0%, #01CDFD 106.47%);
    border: 0.806763px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    border-radius: 32px;
    padding: 9px 15px;
  }
}

.cloz_left {
  left: 20px;
  background: transparent;
}

.nftListContainer {
  margin: 0 !important;
}

@media (min-width: 1551px) {
  .collectionStatistics li {
    width: calc(20% - 20px) !important;
  }
}

@media (max-width: 1550px) and (min-width: 1281px) {
  .collectionStatistics ul li {
    width: calc(20% - 20px) !important;
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .collectionStatistics ul li {
    width: calc(33.3333% - 20px);
  }
}

@media (max-width: 991px) and (min-width: 576px) {
  .collectionStatistics ul li {
    width: calc(50% - 20px);
  }

  .collectionStatistics ul .socials {
    width: calc(100% - 20px) !important;
  }
}

@media (max-width: 575px) {
  .collectionStatistics {
    ul {

      .socials,
      .floorPrice,
      .totalNo,
      .owners,
      .volume {
        width: calc(100% - 20px) !important;
      }
    }
  }

  .collectionStatistics li {
    margin-bottom: 20px !important;
  }

  .bidHeader {
    display: block;

    h6 {
      text-align: center;

      span {
        width: 100%;

        button {
          margin-top: 10px;
        }
      }
    }

    .cancelButton {
      width: 100%;
    }
  }
}

.collectionStatistics .socialIcons {
  div {
    padding: 0 0px;

    img {
      width: 18px !important;
      max-width: fit-content;
    }
  }
}


.headerWithBack {
  //padding: 220px 15px 20px !important;
  padding: 100px 15px 20px;
}

// .exploreCollectionHeading, .headerText {
//     padding-left: 0;
//     text-align: left;
// }
.collectionHeading {
  width: 100%;
  height: 100%;
  min-height: 525px;
  max-height: 525px;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 40px;
  //margin-bottom: 40px;

  @include mobile {
    min-height: 250px !important;
  }

  .overlay {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 1px;
    left: 0;
    background: linear-gradient(180deg, #030120 0%, rgba(3, 2, 32, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);

    @include mobile {
      background: linear-gradient(180deg, #030120 0%, rgba(3, 2, 32, 0.377) 70%);
    }
  }

  .collectionContainer {
    margin-top: 240px;

    @include mobile {
      margin-top: 20px;
    }

    .seeMore {
      background-color: transparent;
      border: none;
      color: #fff;

      margin: 10px 0;

      &:hover {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  .collectionAbout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 25px;

    @include mobile {
      display: block;
    }

    .collectionSocial {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 16px;

      @include mobile {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        //width: 50%;
        margin: auto;
      }

      position: relative;

      a,
      div {
        height: 100%;
        display: block;
        margin-top: 8px;
      }
    }

    .owner {
      span {
        color: var(--primary-color);
      }
    }
  }

  .desc {
    max-width: 800px;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;

    color: #C1C4D7;
  }

  .collectionLogo {
    width: 175px;
    height: 175px;
    //border: 1px solid rgba(255, 255, 255, 0.396);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      padding: 1px;
      object-fit: contain;
    }
  }
}

.collectionStats {
  margin: 80px 0;

  @include mobile {
    margin-bottom: 30px;
  }

  .statContainer {
    display: flex;
    justify-content: flex-start;
    margin: 0 !important;

    max-width: 700px;
    width: 100%;

    @include mobile {
      flex-wrap: wrap;
      width: auto;
      margin: auto !important;
    }
  }

  .collectionStatistics {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ConnectionDetailBtn {
      background-color: transparent;
      color: var(--primary-color);
      border-color: var(--primary-color);
      border-radius: 12px;
      padding: 25px 35px;

      &:hover {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
        color: #fff;
      }

      @include mobile {
        display: none;
      }
    }
  }

  .collectionStatistics li p,
  .exploreCollection li p {
    font-size: 14px !important;
    padding: 5px 0 !important;
    justify-content: flex-start !important;

    .icon {
      margin-left: 0 !important;
    }
  }

  .collectionStatistics li {
    margin: auto;
    padding-bottom: 0 !important;
    //padding: 0px 25px !important;
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;

    //border-right: 2px solid #39365D !important;
    &:not(:last-of-type) {
      padding: 0px 0;
      border-right: 2px solid #39365D !important;
    }

    @include mobile {
      width: calc(50% - 10px);
      margin: auto;
      padding: 10px !important;
      border-radius: 10px !important;
      background: #403a59 !important;

      &:not(:last-of-type) {
        padding: 0px 0;
        border-right: 0px solid #39365D !important;
      }
    }
  }

  .collectionStatistics li p label {
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    color: #fff;
    padding: 0 !important;
    padding-bottom: 6px !important;

    small {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: #999CB6;
      padding: 0 !important;
    }
  }

  .collectionStatistics li p {
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    color: #999CB6;
    padding: 0 !important;
    padding-bottom: 6px !important;
  }
}

.collectionNav {
  .navbar {
    margin-bottom: 50px;
    border-bottom: solid 1px rgba(35, 34, 56, 1);

    .items {
      width: auto;
      display: flex;
      gap: 30px;

      .item {
        padding: 30px 0;
        color: #787B9C;

        &:hover {
          color: var(--primary-color);
          cursor: pointer;
        }
      }

      .itemActive {
        padding: 30px 0;
        border-bottom: solid 4px var(--primary-color);
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.exploreCollectionHeading,
.headerText {
  color: white;
  font-family: 'shapiro65_light_heavy_wide';
  text-align: left;
  font-size: 32px;
  line-height: 60px;
  font-weight: normal;
  width: 100%;
  max-width: 1460px;
  margin: 0;

  @include mobile {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

.exploreCollectionHeading,
.headerText {
  text-align: left;
}

@media (max-width: 1600px) {

  h3.exploreCollectionHeading,
  .headerText {
    padding-left: 0;
  }
}

@media (max-width: 1024px) and (min-width: 575px) {
  .headerWithBack {
    padding: 100px 20px !important;
  }
}

@media (max-width: 574px) {
  .headerWithBack {
    padding: 50px 20px !important;
  }
}

.backButton {
  color: #0998B9;
}

@media (max-width: 767px) {
  .select_dropdown {
    margin-right: 10px;
  }

  .select_dropdown:last-child {
    margin-right: 0px;
  }
}

.floorPrice {
  background: #252433;
  border-radius: 12px !important;
}

.exploreCollections ul {
  margin: 0 !important;
}

.collectionDetail {
  min-height: 1000px;
  .detailBenefits {
    display: flex;
    align-items: center;
    gap: 130px;
    margin: 0 0 200px 0;

    @include mobile {
      display: block;
    }

    .left {
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }

    .right {
      span {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #FFFAFD;
      }

      p {
        font-weight: 400 !important;
        padding-top: 4px !important;
      }
    }

    @include mobile {
      width: 100%;
    }

    h3 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;
      color: #FFFFFF;
    }

    p {
      font-weight: 200;
      font-size: 16px;
      line-height: 28px;
      color: #C1C4D7;

      margin-top: 10px;
    }

  }

  .detailAbout {
    width: 80%;
    margin: 80px 0;

    @include mobile {
      width: 100%;
    }

    h3 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;
      color: #FFFFFF;
    }

    p {
      font-weight: 200;
      font-size: 16px;
      line-height: 28px;
      color: #C1C4D7;

      margin-top: 10px;
    }

  }

  .AboutArtist {
    display: flex;
    gap: 40px;
    align-items: center;
    position: relative;
    margin: 100px 0;
    padding: 40px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, transparent, rgba(35, 34, 56, 1), transparent);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, transparent, rgba(35, 34, 56, 1), transparent);
    }

    @include mobile {
      display: block;
    }

    .profilePic {
      width: 300px;
      height: 220px;
      border-radius: 12px;
      background: var(--primary-bg);
      border: 1px solid rgba(255, 255, 255, 0.12);
      overflow: hidden;

      @include mobile {
        width: 100%;
        margin-bottom: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

      }
    }

    h3 {
      display: flex;
      gap: 10px;
      align-items: center;

      margin-top: 18px;
      margin-bottom: 8px;

      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.02em;

      color: #FFFAFD;
    }

    p {
      width: 80%;
      font-weight: 200;
      font-size: 16px;
      line-height: 28px;
      color: #C1C4D7;

      margin-top: 10px;

      @include mobile {
        width: 100%;
      }

      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #787B9C;
      }
    }
  }

  .moreCollections {

    margin: 140px 0;

    h3 {
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.03em;
      font-feature-settings: 'cv05'on, 'cv11'on;

      color: #F6F5FF;

      span {
        background: var(--background-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .cards {
      display: flex;
      justify-content: left;
      position: relative;
      overflow-x: scroll;

      gap: 30px;
      padding: 40px 0;

      .item {
        overflow: hidden;
        position: relative;

        min-width: 270px;
        width: 270px;
        height: 330px;
        border-radius: 15px;

        @include mobile {
          width: 100%;
          margin: 40px 0;
        }

        &:hover {
          .rainbow {
            display: block;
          }

          .nameOverlay {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            transform: scale(1);

            p {
              position: absolute;
              bottom: 0px;
              right: 0;
              color: #fff;
            }
          }

          img {
            //object-position: left;
          }
        }

        img {
          width: 100%;
          height: 100%;

          width: calc(100% - var(--border-width));
          height: calc(100% - var(--border-width));

          top: 50%;
          left: 50%;
          border-radius: 14px;

          background: #000;

          display: flex;
          flex-direction: column;
          position: absolute;
          object-fit: cover;
          object-position: center;
          transition: ease-in-out 1s;
          transform: translate(-50%, -50%);
        }

        .nameOverlay {
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          height: 0%;
          //background: #ffffff4f;
          //background: linear-gradient(180deg, #ffffff1c 0%, #ffffff55 100%);
          color: #fff;

          border-radius: 10px;
          z-index: 1;

          transition: ease-in-out 350ms;

          p {
            position: absolute;
            bottom: -40px;
            right: 0;
            padding: 8px 15px;
            width: 100%;

            text-align: center;

            background-color: var(--primary-color);
            color: #ffffff00;
            box-shadow: 0 0 20px 5px #000000d2;
            transition: ease-in 150ms;
          }
        }
      }
    }
  }
}

.placeholderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: #f6f7f8;
  background: linear-gradient(to right, #1b1a28 8%, #ffffff15 25%, #1b1a28 33%);
  background-size: 800px 104px;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

.loadingTitle {
  width: 300px;
  height: 40px;
  border-radius: 5px;

  background-color: #f6f7f8;
  background: linear-gradient(to right, #1b1a28 8%, #ffffff15 25%, #1b1a28 33%);
  background-size: 800px 104px;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

.loadingText {
  width: 200px;
  height: 20px;
  margin: 20px 0;
  border-radius: 5px;

  background-color: #f6f7f8;
  background: linear-gradient(to right, #1b1a28 8%, #ffffff15 25%, #1b1a28 33%);
  background-size: 800px 104px;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }
}



@keyframes o-rotate-360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
@import "../styles/mixins";
@import "../styles/variables";

.tooltip {
  font-size: 12px;
  white-space: pre-line;
  border-radius: 13px;
  article:not(:first-child) {
    margin-top: 1em;
  }
}

.button {
  @include flex;
}
.newButton{
  @include flex;
  width:100%;
  justify-content:flex-start;
}

/* with tooltip */
.flex {
  @include flex(flex-start);
  display: inline-flex;
}

.icon {
  color: $battleship;
  margin-left: 3px;
  margin-top: -0.04em;
}
.TooltipLgIcon{
  margin-left: 3px;
}
@import "../../styles/mixins.scss";

.block {
  display: flex;

  @include mobile {
    display: block;
  }

  .daynamicSlider {
    display: flex;
    border: none;
    margin: 0;
    width: 100%;

    @include mobile {
      width: 100%;
      height: 100%;
    }

    .activeImage {
      width: 100%;
      height: 600px;
      overflow: hidden;
      @include mobile {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    div {
      //width: 33.33%;
      border: none;
      margin: 0;
      //padding: 0 10px;
    }

    img {
      width: 100%;
      //flex-shrink: 0;
      //min-width: 100%;
      min-height: 100%;
    }
  }



  .slider {
    @include mobile {
      display: flex;
    }
  }

  .slider_img {
    width: 79px;
    height: 96.64px;
    margin: 10px;
    background-color: rgb(0, 0, 0)03B;

    border: 2px solid #22203B;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      border: 3px solid #8877EA;
      cursor: pointer;
    }
  }
}
@import '../../styles/mixins';

.main {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 100px;
  position: relative;
  z-index: 0;

  .mainHeading {
    font-family: 'shapiro65_light_heavy_wide';
    position: absolute;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include mobile{
      margin-top: -120px;
    }
  }
}
.mainMob{
  margin-top: 170px;
}
.link{
  margin-top: 10px !important;
}
.inner {
  background: #1b1b1b;
  border: 1px solid #2e2e2e;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  padding: 0 15px 20px;
  width: 90%;

  @include mobile{
    margin: 0 auto;
  }
}

.container {
  margin-top: 130px;
}
.containerMob {
  margin-top: 90px;
}
.container span {
  //position: absolute;
  //top: -30px;
  //left: 50%;
  //margin-left: -29px;
}
.container span img {
  margin: 0;
}
.head{
  display: flex;
  flex-direction: column;
}
.heading {
  font-size: 21px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
  font-family: Lexend;
  font-weight: 700;
  position: initial;
}
.small{
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8D8D8D !important;
  text-align: center !important;
}
.minted{
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right !important;
  color: #8D8D8D !important;

  .active{
    color: white !important;
  }
}
.container p {
  font-size: 14px;
  text-align: center;
  color: #8d8d8d;
}
.container div a {
  padding: 0 10px;
  position: relative;
  top: -12px;
  background: #1b1b1b;
}
.container div a img {
  margin: 0;
}
.container label {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #8d8d8d;
  padding: 5px 0;
}
.container label b {
  font-weight: normal;
  color: white;
}

.child{
  margin-top: 30px !important;
  border-top: 6px solid #32FE9A;
  padding: 10px;
}
.btn{
  margin: 40px 0 0 0;
  @include purple_btn;
}
.headDiv{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -29px;
}
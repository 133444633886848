@import '../../styles/mixins';

.wrapper {
    background-image: url('/assets/confetti.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 50%;
    //margin-top: 185px;
    padding: 0 15px;
    text-align: center;
    .card {
        max-width: 500px;
        margin: auto;
        padding: 200px 0 300px 0;
        img {
            max-width: 200px;
            margin: 20px 0;
        }
        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            text-align: center;
            letter-spacing: -0.03em;

            color: #FFFFFF;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            text-align: center;

            color: #FFFFFF;

            opacity: 0.7;

            padding: 10px 0 30px;
        }

        .buy {
            background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
            width: 100%;
            height: 44px;
            border-radius: 12px;
            border: none;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
        }
    }
}

.mobileFilter {
    position: fixed !important;
    transition: all 0.4s ease-in-out;
    background: #1b1b1b;
    left: -100%;
    top: 0 !important;
    height: 100vh;
    padding-top: 150px !important;
    overflow: auto;
    z-index: 10000;
}

@media (max-width: 450px) {
    .mobileFilter {
        position: fixed !important;
        transition: all 0.4s ease-in-out;
        background: #1b1b1b;
        left: 0;
        top: 0 !important;
        height: 100vh;
        padding-top: 150px !important;
        overflow: auto;
        z-index: 10000;
    }
    .desktopFilter {
        display: none;
    }

    .btnFilter {
        right: 16px !important;
        position: fixed !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        color: #FFFFFF;
        bottom: 96px;
        z-index: 9999;
        background: linear-gradient(95.63deg, #3168F7 0%, #01CDFD 106.47%);
        border: 0.806763px solid rgba(255, 255, 255, 0.6);
        box-sizing: border-box;
        border-radius: 32px;
        padding: 9px 15px;
    }

    .mobileFilter.show {
        display: block;
    }
    .mobileFilter.hide {
        display: none;
    }
}

@media (min-width: 451px) {
    .btnFilter {
        position: fixed !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        color: #FFFFFF;
        right: -100% !important;
        bottom: 96px;
        z-index: 9999;
        background: linear-gradient(95.63deg, #3168F7 0%, #01CDFD 106.47%);
        border: 0.806763px solid rgba(255, 255, 255, 0.6);
        box-sizing: border-box;
        border-radius: 32px;
        padding: 9px 15px;
    }
}

.cloz_left {
    left: 20px;
    background: transparent;
}

.collectionStatistics {
    width: 100%;
}

.statContainer {
    justify-content: flex-start;
    margin: 0 !important;
}

.nftListContainer {
    margin: 0 !important;
}

.collectionStatistics li p, .exploreCollection li p {
    font-size: 14px !important;
    padding: 5px 0 !important;
    justify-content: flex-start !important;

    .icon {
        margin-left: 0 !important;
    }
}

.collectionStatistics li {
    padding-bottom: 0 !important;
    justify-content: center !important;
    padding: 15px 13px !important;
    background: #252433 !important;
    border-radius: 12px !important;

    margin: 0px 10px 20px 10px !important;
}

.collectionStatistics li p label {
    font-size: 18px !important;
}

.collectionStatistics li p label small {
    font-size: 12px !important;
}

@media (min-width: 1551px) {
    .collectionStatistics li {
        width: calc(20% - 20px) !important;
    }
}

// @media (max-width: 1625px) and (min-width: 1553px) {
//     .collectionStatistics {
//         ul {
//             .floorPrice {
//                 width: calc(15% - 5px) !important;
//             }
//             .volume {
//                 width: calc(18% - 5px) !important;
//             }
//             .owners {
//                 width: calc(15% - 5px) !important;
//             }
//             .totalNo {
//                 width: calc(17% - 5px) !important;
//             }
//             .socials {
//                 width: calc(19% - 5px) !important;
//             }
//         }
//     }
// }

// @media (min-width: 1626px) {
//     .collectionStatistics {
//         ul {
//             .floorPrice {
//                 width: calc(18% - 20px) !important;
//             }
//             .volume {
//                 width: calc(18% - 20px) !important;
//             }
//             .owners {
//                 width: calc(18% - 20px) !important;
//             }
//             .totalNo {
//                 width: calc(18% - 20px) !important;
//             }
//             .socials {
//                 width: calc(20% - 20px) !important;
//             }
//         }
//     }
// }

// @media (max-width: 1552px) and (min-width: 1431px) {
//     .collectionStatistics {
//         ul {
//             .floorPrice {
//                 width: calc(17% - 5px) !important;
//             }
//             .volume {
//                 width: calc(18% - 5px) !important;
//             }
//             .owners {
//                 width: calc(15% - 5px) !important;
//             }
//             .totalNo {
//                 width: calc(17% - 5px) !important;
//             }
//             // .socials {
//             //     width: calc(24% - 5px) !important;
//             // }
//         }
//     }
// }

@media (max-width: 1550px) and (min-width: 1281px) {
    .collectionStatistics ul li{
        width: calc(20% - 20px) !important;
    }
}

@media (max-width: 1280px) and (min-width: 1025px) {
    .collectionStatistics ul li{
        width: calc(33.3333% - 20px);
    }
}

@media (max-width: 991px) and (min-width: 576px) {
    .collectionStatistics ul li {
        width: calc(50% - 20px);
    }

    .collectionStatistics ul .socials {
        width: calc(100% - 20px) !important;
    }
}

@media (max-width: 575px) {
    .collectionStatistics {
        ul {
            .socials, .floorPrice, .totalNo, .owners, .volume {
                width: calc(100% - 20px) !important;
            }
        }
    }

    .collectionStatistics li {
        margin-bottom: 20px !important;
    }

    .bidHeader {
        display: block;
        h6 {
            text-align: center;
            span {
                width: 100%;

                button {
                    margin-top: 10px;
                }
            }
        }

        .cancelButton {
            width: 100%;
        }
    }
}

.collectionStatistics .socialIcons {
    div {
        padding: 0 0px;

        img {
            width: 18px !important;
            max-width: fit-content;
        }
    }
}


.headerWithBack {
    padding: 220px 15px 20px !important;
}

// .exploreCollectionHeading, .headerText {
//     padding-left: 0;
//     text-align: left;
// }

.exploreCollectionHeading, .headerText {
    color: white;
    padding: 30px 0 25px;
    font-family: 'shapiro65_light_heavy_wide';
    text-align: left;
    font-size: 32px;
    line-height: 60px;
    font-weight: normal;
    width: 100%;
    max-width: 1460px;
    margin: 0;
}

.exploreCollectionHeading, .headerText {
    text-align: left;
}

@media (max-width: 1600px) {
    h3.exploreCollectionHeading, .headerText {
      padding-left: 0;
    }
}

@media (max-width: 1024px) and (min-width: 575px){
    .headerWithBack {
        padding: 0 20px !important;
    }
}

@media (max-width: 574px){
    .headerWithBack {
        padding: 0 20px !important;
    }
}

.backButton {
    color: #0998B9;
}
@media (max-width: 767px) {
    .select_dropdown {
        margin-right: 10px;
    }
    
    .select_dropdown:last-child {
        margin-right: 0px;
    }
}

.floorPrice {
    background: #252433;
    border-radius: 12px !important;
}

.exploreCollections ul {
    margin: 0 !important;
}
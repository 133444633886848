@import "../styles/mixins";



.timeLeft {
  background: #1E1E2A;
  border: 0.5px solid #303041;
  border-radius: 4px;    
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  padding: 2px 6px;
  left: 14px;
  bottom: 10px;
  label {
      img {
          width: 12px !important;
          margin-right: 6px !important;
      }
      span {
          display: block;
      }
  }
}

.timeContainer {
  position: relative;
}

.nftItem {
  width: calc(25% - 20px) !important;
  background: #1E1E2A;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 10px !important;
}

.nftItem p {
  padding: 5px 15px !important;
  justify-content: flex-start !important;

  img {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}

@media (max-width: 1280px) and (min-width: 1025px){
  .nftItem {
      width: calc(33.33% - 20px) !important;
  }
}

@media (max-width: 1024px) and (min-width: 821px){
  .nftItem {
      width: calc(50% - 20px) !important;
  }
}

@media (max-width: 820px) and (min-width: 768px){
  .nftItem {
      width: calc(100% - 20px) !important;
  }
}

@media (max-width: 767px) and (min-width: 576px){
  .nftItem {
      width: calc(50% - 20px) !important;
  }
}

@media (max-width: 575px) {
  .nftItem {
    width: calc(100% - 20px) !important;
  }
}
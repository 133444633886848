@import "../styles/mixins";

.table{
  //border: 1px solid $table_border;
  //border-radius: 15px;
}
.thead{
  border-bottom: 2px solid $table_border;
}
.wrapper {
  width: 100%;
  overflow-x: scroll;

  thead th:nth-child(-n+1) {
    text-align: left !important;
    padding: 17px;
  }
  thead th:first-child {
    text-align: left !important;
    padding-left: 15px;
  }
  thead th:last-child {
    text-align: left !important;
    padding-right: 20px;
  }
  thead th:nth-child(1){
    border-top-left-radius: 12px !important;
  }
  thead th:last-child{
    border-top-right-radius: 12px !important;
  }
}

.margin {
  margin-top: 20px;
}

.cell {
  padding: 10px;
  padding-right: 23px;
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  color: white;
  &:nth-child(-n+1) {
    text-align: left !important;
    padding: 17px;
  }
  &:last-child {
    text-align: left !important;
    span{
      font-weight: 100 !important;
      color: white;
    }
  }
}

.th {
  color: $table_border_bg;
  text-transform: capitalize;
  cursor: pointer;
  background: $theme_light_black_color;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  .colspan & {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    text-align: center;
  }
}
.tr {
  background: $theme_light_black_color_even;
}
.tr:nth-child(odd) {
  background-color: $theme_light_black_color;
}
.newTr {
  background: $theme_light_black_color_even;
  height: 78px;
}
.newTr:nth-child(odd) {
  background-color: $theme_light_black_color;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fixed-left {
  @include desktop {
    position: sticky;
    left: 0;
  }
}

.fixed-right {
  @include desktop {
    position: sticky;
    right: 0;
  }
}

.narrow-left {
  padding-left: 5px;
}

.narrow-right {
  padding-right: 5px;
}

/* color */
.red {
  background: fade-out($red, 0.92);
}

.bold {
  color: white;
  font-weight: 500;
}

/* border */
.border-left {
  border-left: $border;
}

.border-right {
  border-right: $border;
}
.emptyPlaceHolder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loading{
  display: table;
  margin: 0 auto !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

//.tr:first-child td:first-child { border-top-left-radius: 10px; }
//.tr:first-child td:last-child { border-top-right-radius: 10px; }

.tr:last-child td:first-child { border-bottom-left-radius: 10px; }
.tr:last-child td:last-child { border-bottom-right-radius: 10px; }

//.tr:last-child th:first-child { border-top-left-radius: 10px; }
//.tr:last-child th:last-child { border-top-right-radius: 10px; }
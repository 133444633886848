@import "../../../../styles/mixins.scss";

.hidden {
  display: none !important;
}

.back {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  align-items: center;

  color: #01CDFD;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 130px;
  cursor: pointer;

  @include mobile {
    margin-top: 50px;
  }
}

.typeSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  //margin: 50px;
  gap: 8px;

  .types {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: auto;
    padding: 5px;
    background: var(--primary-bg);
    border-radius: 40px;

    .type {
      padding: 5px 20px;

      &:hover {
        cursor: pointer;
      }
    }

    .type_active {
      padding: 5px 20px;
      background: var(--primary-color);
      border-radius: 32px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.launchViewHeader {
  display: flex;
  gap: 20px;
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  padding-bottom: 270px !important;
  margin-top: 40px;
  position: relative;
  z-index: 0;

  @media (max-width: 1300px) {
    margin-top: 20px;

    .launchViewLeft {
      width: 50%;
    }

    .launchViewRight {
      width: 50%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  @include mobile {
    margin-top: 20px;
    display: block;

    .launchViewLeft {
      width: 100%;
    }

    .launchViewRight {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .titleBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    color: #8d8d8d;
    //padding: 0 15px;
    margin-bottom: 33px;

    @include mobile {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .title {
      display: flex;
      align-items: center;

      img {
        @include mobile {
          display: none;
        }
      }

      h6 {
        font-family: 'shapiro65_light_heavy_wide';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @include mobile {
          font-size: 18px;
          padding: 0 0 !important;
        }
      }
    }

    .status {
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 14px;
      color: #fff;
      width: fit-content;
      margin: 0 auto;
      padding: 4px 8px;
      border: 2px solid transparent;
      border-radius: 10px;
      background: #FA7753;
      border: 0.5px solid #FFFFFF;
      border-radius: 4px;
      z-index: 9999;

      @include mobile {
        // margin-top: 100px !important;
      }
    }

    .activeTab {
      color: #fff;
      background:
        linear-gradient(#3168F7, #01CDFD) padding-box,
        linear-gradient(#3168F7, #01CDFD) border-box;
    }
  }
}

.launchViewLeft {
  width: 50%;

  @include mobile {
    display: block;
    width: 100%;
    margin-top: 0;
  }

  .block {
    display: flex;

    @include mobile {
      display: block;
    }

    p {
      margin-top: 36px;
      font-weight: 300;
      font-size: 18px;
      text-align: left;
      line-height: 30px;
      color: #e1e1e1;
      padding: 0;
    }

    @include mobile {
      width: 100%;
    }
  }

  h6 {
    padding: 0 15px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 0;
    font-family: Lexend;
    font-weight: 700;
    position: initial;
  }

  p {
    padding: 0 15px;
    font-size: 14px;
    text-align: center;
    color: #8d8d8d;
  }

  .social {
    text-align: center;
    align-items: end;

    a {
      padding: 0 10px;
      position: relative;
      top: -12px;
    }
  }
}

.launchViewRight {
  width: 50%;
  margin-left: 33px;

  @include mobile {
    width: 100%;
    margin-left: 0;
    margin-top: -65px;
  }

  .project_social {
    //margin-top: 20px;
    color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;
    justify-content: right;

    img {
      width: 40px;
    }
  }

  .nft_view {
    @include mobile {
      width: 100%;
      border-radius: 10px;
    }

    .mint_normal {
      width: 100%;
      height: 77px;
      border-radius: 12px;
      background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;

      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        color: #fff;
      }
    }

    .mint_live {
      width: 100%;
      background: #171936;
      border-radius: 12px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 26px;

      .endDate {
        text-align: center;
        width: 100%;
      }

      .countdown {
        text-align: center;
        width: 100%;

        font-weight: 700;
        font-size: 24px;
        line-height: 29px;

        h6 {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;

          color: #F6F5FF;
        }

        div {
          margin-bottom: 0px !important;
        }
      }

      .price_text {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: rgba(246, 245, 255, 0.6);
      }

      .price_value {
        background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        font-weight: 400;
        font-size: 24px;
        line-height: 29px;

        padding: 10px 0;
      }

      .convert {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #FAFAFA;

        span {
          color: rgba(250, 250, 250, 0.7);
        }
      }

      .buy {
        background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
        width: 50%;
        height: 77px;
        border-radius: 12px;

        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #fff;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .mint {
      width: 100%;
      height: 77px;
      background: #171936;
      border-radius: 12px;

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;

        h6 {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;

          margin-top: 20px;

          color: #F6F5FF;
        }
      }

      .soon {
        background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
      }
    }

    .status_message {
      display: block;
      margin: 25px 0;
      text-align: center;
      font-size: 12px;
      line-height: 19px;
      color: #8D8D8D;
    }

    .ProgressBar {
      .container {
        background: #3b3a3a;
        border-radius: 10px;
      }
    }

    .header_timer {
      margin-bottom: 40px;

      .time {
        margin: auto;
        background: #2A2938;
        border: 1px solid #262636;
        border-radius: 15px;
        padding: 20px;
        margin-top: 30px;
        height: 150px;
        width: 100%;

        h6 {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 900;
          font-size: 34px;
          line-height: 80px;
          text-align: center;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #FFFFFF;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        p {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height, or 158% */

          text-align: center;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          color: #8D8D8D;
        }
      }
    }

    .header {
      position: relative;

      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #fff;
      }

      .about {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        color: #999CB6;
      }

      .benefits {
        display: block;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #FFFAFD;

        .benefit_details {
          margin: 20px 0;

          label p {
            display: flex;
            gap: 10px;
            font-size: 14px;
            line-height: 26px;
            color: #F6F5FF;
            padding: 7px 0;
            font-weight: 500 !important;

            .text-green {
              color: #32FE9A;
            }

            b {
              font-weight: 300 !important;
              color: #fff;

              span {
                color: #8d8d8d;
              }
            }
          }
        }
      }

      .artist {
        display: flex;
        gap: 10%;
        border-top: 1px solid red;
        border-bottom: 1px solid red;
        border-left-width: 0;
        border-right-width: 0;
        border-image: linear-gradient(to right, transparent, rgba(56, 54, 78, 0.6), transparent) 1 stretch;

        margin: 20px 0;
        padding: 24px 0;

        .divider {
          background: rgba(56, 54, 78, 0.6);
          height: auto;
          width: 2px;
          margin: 2px;
        }

        span {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #FFFAFD;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.02em;

          color: #8877EA;
        }

        .profile {
          display: flex;
          gap: 10px;
          align-items: flex-end;

          img {
            width: 40px;
            border-radius: 50px;
          }
        }
      }

      .price {
        display: grid;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 37px;
        letter-spacing: -0.02em;
        color: #ffffff;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #8d8d8d;
        }
      }

      .status {
        font-size: 14px;
        color: #fff;
        background: #fd3eda;
        border: 1.5px solid #ffffff;
        border-radius: 4px;
        padding: 4px 16px;
        position: absolute;
        right: 0%;
        top: 5%;
      }

      .upocming {
        font-size: 14px;
        text-align: center;
        color: #fff;
        background: #FA7753;
        border: 1.5px solid #ffffff;
        border-radius: 4px;
        padding: 4px 16px;
        width: 120px;
        margin: auto;
        margin-bottom: 15px;
      }

      .time {
        margin: auto;
        background: #2A2938;
        border: 1px solid #262636;
        border-radius: 15px;
        padding: 20px;
        //margin-top: 30px;
        height: 150px;
        width: 100%;

        h6 {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 900;
          font-size: 34px;
          line-height: 80px;
          text-align: center;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #FFFFFF;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        .time_detail {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 400;
          font-size: 12px !important;
          line-height: 0;
          text-align: center;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #8D8D8D;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        p {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height, or 158% */

          text-align: center;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          color: #8D8D8D;
        }

        @include mobile {
          h6 {
            font-size: 24px !important;

            span {
              opacity: 0.4;
              padding: 0 10px;
            }
          }

          .time_detail {
            font-size: 10px !important;

            span {
              opacity: 0.4;
              padding: 0 10px;
            }
          }
        }
      }
    }

    .details {
      margin: 20px 0;

      label p {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #8d8d8d;
        padding: 7px 0;
        font-weight: 300 !important;

        .text-green {
          color: #32FE9A;
        }

        b {
          font-weight: 300 !important;
          color: #fff;

          span {
            color: #8d8d8d;
          }
        }
      }
    }

    .mint {
      margin-top: 40px;

      span {
        font-weight: 300px;
        color: #8d8d8d;
        font-size: 12px;
        line-height: 19px;

        b {
          color: #fff;
          margin: 0 5px;
        }
      }
    }

    .input {
      display: inline-flex;
      width: 100%;
      margin: 20px 0;

      input {
        width: 100%;
        border: solid 2px #2f2f43;
        padding: 15px;
        text-align: center;
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
      }

      button {
        width: 90px;
        color: #fff;
        transition: ease-in-out 0.2s;
        border: 2px solid #2f2f43 !important;
        background: var(--secondary-bg) radial-gradient(circle, transparent 1%, var(--secondary-bg) 1%) center/15000% !important;

        &:first-child {
          border-right: none !important;
          border-radius: 10px 0 0 10px;

          &::before {
            content: url('../../../../assets/minus.svg');
            display: block;
            margin-top: 7px;
          }
        }

        &:last-child {
          border-left: none !important;
          border-radius: 0 10px 10px 0;

          &::before {
            content: url('../../../../assets/add.svg');
            display: block;
            margin-top: 7px;
          }
        }

        &:last-child,
        &:first-child {
          transition: background 0.4s;

          &:hover {
            background: #01cffdb0 radial-gradient(circle, transparent 1%, #01cffdb0 1%) center/15000% !important;
          }

          &:disabled {
            background: var(--disabled-bg) radial-gradient(circle, transparent 1%, var(--disabled-bg) 1%) center/15000% !important;

            &:active {
              background-color: #272738 !important;
              background-size: 100% !important;
              transition: background 0s;
            }
          }

          &:active {
            background-color: #01cdfd !important;
            background-size: 100% !important;
            transition: background 0s;
          }
        }
      }
    }

    .footer {
      padding: 0;
      margin: 0 !important;
      float: none;

      #mint {
        &:disabled {
          img {
            filter: brightness(6);
          }
        }
      }

      button {
        background: #01cdfd radial-gradient(circle, transparent 1%, #01cdfd 1%) center/15000%;
        border-radius: 10px;
        width: 100%;
        height: 52px;
        margin-top: 20px;
        font-size: 18px;
        color: #1b1b1b;
        font-weight: 500;
        transition: background 0.8s;

        &:hover {
          background: #01cffdca radial-gradient(circle, transparent 1%, #01cffdca 1%) center/15000%;
          border: solid 2px #01cdfd;
        }

        &:active {
          background-color: #13c3ea83;
          background-size: 100%;
          transition: background 0s;
        }

        &:disabled {
          background: var(--disabled-bg);
          border: solid 2px var(--disabled-bg);
          color: var(--disabled-color);
        }
      }
    }
  }
}

.benefits_bg {
  background-image: url('../../../../assets/fans/benefitbg.svg');
  background-size: cover;
  background-position: top center;
  background-repeat: repeat;

  .divider {
    width: 80%;
    height: 2px;
    background: radial-gradient(51.5% 12875000.21% at 50% 0%, #232238 0%, #312E4B 0.01%, rgba(36, 34, 57, 0.76) 100%);
    margin: 50px auto;
  }

}

.benefits_type {
  max-width: $--max-width;
  margin: auto;
  //padding: 230px 0;
  position: relative;
  display: flex;
  gap: 100px;

  @include mobile {
    display: block;
    width: 100%;

    padding: 20px;
    margin: 100px auto;
  }

  .title {
    display: flex;
    margin-bottom: 30px;

    img {
      margin-right: 15px;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 48px;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    span {
      background: linear-gradient(123.75deg, #9B83F9 53.46%, #8164F5 66.94%, #27AEF8 80.94%, #31A2FA 97.26%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .info_header {
    margin-top: 20px;
    max-width: 500px;

    @include mobile {
      width: 100%;
      margin-bottom: 100px;
    }

    p {
      margin: 20px 0;
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 28px;

      color: #C1C4D7;
    }

    .buy {
      width: 165px;
      height: 58px;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;

      background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%);
      border-radius: 12px;
    }
  }

  .benefit {
    display: flex;
    gap: 32px;
    align-items: center;

    padding: 42px;
    margin-bottom: 20px;


    //background: rgba(255, 255, 255, 0.06);
    //border: 1.10831px solid rgba(255, 255, 255, 0.04);
    //border-radius: 26.5995px;

    @include mobile {
      margin-top: 20px;
      display: block;
    }

    .img {
      width: 500px;
      border-radius: 20px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      @include mobile {
        width: 100%;
      }
    }

    b {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #F6F5FF;
    }

    span {
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      color: #999CB6;

      @include mobile {
        font-size: 13px !important;
        font-weight: 200;
      }
    }
  }

  table {
    text-align: left;
    border-radius: 15px;
    background: #1E1E2A;

    .tableHeader {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #919191;
      border-bottom: 1px solid #262636;
      background: #1E1E2A !important;

      .tooltip_nfts {
        position: relative;
      }

      .tooltip_nfts:before {
        background: #9c2a6f;
        content: "Minted/Total Supply";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        left: -10px;
        bottom: -10px;
      }

      .tooltip_nfts:after {
        width: 12px;
        height: 12px;
        background: #9c2a6f;
        content: "";
        position: absolute;
        left: 50px;
        bottom: 9px;
        transform: rotate(45deg);
        border-radius: 3px;
      }

      .tooltip_nfts:before,
      .tooltip_nfts:after {
        display: none;
      }

      .tooltip_nfts:hover::before,
      .tooltip_nfts:hover::after {
        display: block;
      }
    }

    @include mobile {
      display: none;
    }
  }

  tr {
    &:nth-child(odd) {
      background-color: #22222d;
    }

    color: #fff;

    td {
      padding: 30px 40px;
      font-weight: 400;

      &:first-child {
        display: flex;
        padding: 40px 40px;

        img {
          margin-right: 10px;
        }
      }

      span {
        color: #919191;
      }
    }
  }

  .tableHeader__mobile {
    background: #1e1e2a !important;
    border: solid 1px #262636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: solid 1px #262636;
    }

    @include desktop {
      display: none;
    }
  }

  .tableHeader__mobile__divider {
    background: #262636;
    width: 100%;
    height: 1px;

    @include desktop {
      display: none;
    }
  }

  .buy {
    background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
    width: 50%;
    height: 77px;
    border-radius: 12px;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #fff;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.projectinfo {
  margin-top: 120px;

  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      display: block;
    }
  }

  .split {
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: block;
    }
  }

  .info_card {
    background: #1E1E2A;
    border-radius: 15px;
    padding: 32px;
    text-align: center;

    //width: 100%;
    p {
      display: grid;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      color: #919191;
    }

    span {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }

    &__left {
      margin: 26px 0 0 0;
    }

    &__center {
      margin: 26px 0 0 26px;
    }

    &__right {
      margin: 26px 0 0 26px;
    }

    &__wallet {
      min-width: 600px;
    }

    @include mobile {

      &__left,
      &__center,
      &__right {
        margin: 26px 0 !important;
      }

      &__wallet {
        min-width: 100% !important;
      }
    }
  }
}

.modalContent {
  position: relative;
  background-color: #100F1E;
  // border: 4px solid rgba(255, 255, 255, 0.05);
  margin: auto;
  text-align: center;
  padding: 0;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  transition: ease-in-out 2s;
  border-radius: 10px;
  border: 1px solid #24243C;
  z-index: 9999;

  backdrop-filter: blur(10px);

  .content {
    padding: 80px 0;

    .text {
      padding: 40px 0;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

      text-align: center;
      letter-spacing: -0.03em;

      color: #FFFFFF;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      text-align: center;

      color: #FFFFFF;
      opacity: 0.7;
    }
  }

  @include mobile {
    width: 80%;
  }

  .paymentModal {
    padding: 40px 25px;

    .modalHeader {
      margin: auto;
      text-align: center;

      img {
        width: 152px;
        border-radius: 12px;
        border: 1px solid #24243C;
      }

      h6 {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.03em;

        padding: 30px 0;

        text-align: center;
        color: #FFFFFF;
      }
    }

    .modalBody {
      .items {
        padding: 30px 0;

        .item {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          .left {
            display: flex;
            gap: 10px;

            color: #fff;
          }

          .right {
            color: #fff;

            .input {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 100px;

              background: #1D1B3A;
              border-radius: 12px;
              border: 2px solid #24243C;

              padding: 8px 14px;

              input {
                width: 100%;
                margin: 0 16px;

                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
              }

              .minus,
              .plus {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;

                &:hover {
                  color: #887DEA;
                }

                &:disabled {
                  color: #d1cdcd;
                  opacity: 0.3;
                }
              }
            }
          }
        }
      }

      .divider {
        background: #2B2843;
        height: 1px;
        width: 100%;
        margin: 12px auto;
      }

      .buy {
        background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
        width: 100%;
        height: 55px;

        //padding: 30px 0;
        border-radius: 10px;
        border: none;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fff;

        &:hover {
          border: none;
        }

        &:disabled {
          filter: grayscale(0.3) opacity(0.5)
        }
      }
    }
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  text-align: center;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
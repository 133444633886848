@import "../../styles/mixins.scss";

.giftCard {
  max-width: $--max-width;
  height: 457px;
  margin: 0 auto;
  padding: 50px;
  margin-top: 120px;
  position: relative;
  //margin-bottom: 200px;

  background-image: url('/assets/images/giftbg.svg');
  background-size: cover;
  border-radius: 12px;

  @include mobile {
    margin: 20px;
  }


  .container {
    width: 676px;
    margin: auto;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mobile {
      width: 100%;
    }
  }

  .title {
    display: flex;
    margin-bottom: 30px;

    img {
      margin-right: 15px;
    }
  }

  h6 {
    font-family: "shapiro65_light_heavy_wide";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 53px;
    letter-spacing: -0.03em;
    text-align: center;
    color: white;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    span {
      background: linear-gradient(343deg, #27AEF8 40%, #31A2FA 30%, #9B83F9 74.94%, #8164F5 90.26%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    @include mobile {
      font-size: 7vw;
    }
  }

  .info_header {
    @include mobile {
      width: 100%;
    }

    p {
      margin: 20px 0;
      font-size: 15px;
      line-height: 18px;
      color: #BBBBBB;
    }
  }

  table {
    text-align: left;
    border-radius: 15px;
    background: #1E1E2A;

    .tableHeader {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #919191;
      border-bottom: 1px solid #262636;
      background: #1E1E2A !important;

      .tooltip_nfts {
        position: relative;
      }

      .tooltip_nfts:before {
        background: #9c2a6f;
        content: "Minted/Total Supply";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        left: -10px;
        bottom: -10px;
      }

      .tooltip_nfts:after {
        width: 12px;
        height: 12px;
        background: #9c2a6f;
        content: "";
        position: absolute;
        left: 50px;
        bottom: 9px;
        transform: rotate(45deg);
        border-radius: 3px;
      }

      .tooltip_nfts:before,
      .tooltip_nfts:after {
        display: none;
      }

      .tooltip_nfts:hover::before,
      .tooltip_nfts:hover::after {
        display: block;
      }
    }

    @include mobile {
      display: none;
    }
  }

  tr {
    &:nth-child(odd) {
      background-color: #22222d;
    }

    color: #fff;

    td {
      padding: 30px 40px;
      font-weight: 400;

      &:first-child {
        display: flex;
        padding: 40px 40px;

        img {
          margin-right: 10px;
        }
      }

      span {
        color: #919191;
      }
    }
  }

  .tableHeader__mobile {
    background: #1e1e2a !important;
    border: solid 1px #262636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: solid 1px #262636;
    }

    @include desktop {
      display: none;
    }
  }

  .tableHeader__mobile__divider {
    background: #262636;
    width: 100%;
    height: 1px;

    @include desktop {
      display: none;
    }
  }
  
  .buttonGradient {
    position: relative;
    width: 200px;
    margin: auto;
    margin-top: 41px;
    transition: ease-in-out 500ms;
    border-radius: 15px; 
    background: transparent;
    &:hover {
      border-radius: 15px; 
      background:linear-gradient(45deg,rgba(98, 217, 236, 1),rgba(148, 105, 224, 1));
      cursor: pointer;
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 15px; 
      padding: 3px; 
      background:linear-gradient(45deg,rgba(98, 217, 236, 1),rgba(148, 105, 224, 1)); 
      -webkit-mask: 
         linear-gradient(#fff 0 0) content-box, 
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }
    .button {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: auto;
      padding: 15px;
      color: #fff;
      &:disabled {
        background: transparent !important;
        opacity: 0.5;
      }
    }
  }
}
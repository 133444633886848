@import '../../../styles/mixins';


.card {
  margin: 200px 0;

  .right {
    max-width: 600px;
    width: 100%;
    span {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #787B9C;
    }
  }

  .item {
    display: flex;
    align-items: center;
    gap: 80px;
    max-width: 2000px;

    @include mobile {
      display: block;
    }

    .collectionImage {
      border: 1px solid #22203B;
      position: relative;
      border-radius: 8px;

      width: 600px;
      height: 600px;
      overflow: hidden;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include mobile {
        width: 100%;
        height: 400px;

        margin: 50px 0;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      padding: 15px 0 20px 0;

      color: #FFFFFF;
    }

    p {
      margin-top: 15px;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #C1C4D7;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

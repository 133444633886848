@import '../../styles/mixins';

.shareSection {
  position: relative;
  transform: scale(1);
  transition: ease-in-out 100ms;

  .socialShare {
    height: auto !important;
    position: absolute;
    text-align: center;

    background-color: #7b87a8;

    border-radius: 50px;
    padding: 10px 0;
    bottom: 10px;
    right: -8px;
    width: 50px;
    @include mobile {
      position: absolute;
      bottom: -10px;
      right: 50px;
      width: 50px;
      display: flex !important;
      width: 220px;
      justify-content: center;
    }

    a {
      display: block;
      max-height: 40px;
      height: 100%;

      img {
        padding: 4px 10px;
        width: 100%;
        height: 100%;
        max-width: 40px;
      }
    }
  }
}
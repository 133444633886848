.hidden {
  display: none !important;
}
.back {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  align-items: center;

  color: #01CDFD;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  //margin-top: 230px;
  margin-top: 130px;
  cursor: pointer;
  @include mobile {
    margin-top: 50px;
  }
}

.launchViewHeader {
  display: flex;
  gap: 20px;
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 40px;
  position: relative;
  z-index: 0;

  @media (max-width: 1300px) {
    margin-top: 20px;
    .launchViewLeft {
      width: 50%;
    }
    .launchViewRight {
      width: 50%;
      margin-left: 0;
      margin-top: 20px;
  }
  }
  @include mobile {
    margin-top: 20px;
    display: block;
    .launchViewLeft {
      width: 100%;
    }
    .launchViewRight {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
  }
  }

  .titleBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    color: #8d8d8d;
    //padding: 0 15px;
    margin-bottom: 33px;
    @include mobile {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .title {
      display: flex;
      align-items: center;

      img {
        @include mobile {
          display: none;
        }
      }
      h6 {
        font-family: 'shapiro65_light_heavy_wide';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @include mobile {
          font-size: 18px;
          padding: 0 0 !important;
        }
      }
    }

      .status {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 14px;
        color: #fff;
        width: fit-content;
        margin: 0 auto;
        padding: 4px 8px;
        border: 2px solid transparent;
        border-radius: 10px;
        background: #FA7753;
        border: 0.5px solid #FFFFFF;
        border-radius: 4px;
        z-index: 9999;

        @include mobile {
         // margin-top: 100px !important;
        }
    }
    .activeTab {
      color: #fff;
      background:
      linear-gradient(#3168F7, #01CDFD) padding-box,
      linear-gradient(#3168F7, #01CDFD) border-box;
    }
  }
}

.launchViewLeft {
  width: 70%;
  @include mobile {
    display: block;
    width: 100%;
    margin-top: 0;
  }

  .block {
    p {
      margin-top: 36px;
      font-weight: 300;
      font-size: 18px;
      text-align: left;
      line-height: 30px;
      color: #e1e1e1;
      padding: 0;
    }
    @include mobile {
      width: 100%;
    }
    .daynamicSlider {
      display: flex;
      border: none;
      margin: 0;

      div {
        width: 33.33%;
        border: none;
        margin: 0;
        padding: 0 10px;
      }

      img {
        width: 100%;
        //flex-shrink: 0;
        //min-width: 100%;
        min-height: 100%;
      }
    }
  }

  h6 {
    padding: 0 15px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 0;
    font-family: Lexend;
    font-weight: 700;
    position: initial;
  }

  p {
    padding: 0 15px;
    font-size: 14px;
    text-align: center;
    color: #8d8d8d;
  }

  .social {
    text-align: center;
    align-items: end;
    a {
      padding: 0 10px;
      position: relative;
      top: -12px;
    }
  }

  .project_social {
    margin-top: 20px;
    color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: left;
    a {
      img {
        width: 30px;
      }
    }
  }
}

.launchViewRight {
  width: 30%;
  margin-left: 33px;
  @include mobile {
    width: 100%;
    margin-left: 0;
    margin-top: -65px;
  }
  .nft_view {
    //background: #1e1e2a;
    background: var(--primary-bg);
    border: 1px solid #262636;
    border-left: none;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    position: relative;
    padding: 35px 20px;

    @include mobile {
      width: 100%;
      border-radius: 10px;
    }

    .status_message {
      display: block;
      margin: 25px 0;
      text-align: center;
      font-size: 12px;
      line-height: 19px;
      color: #8D8D8D;
    }

    .ProgressBar {
      .container {
        background: var(--border-color);
        border-radius: 10px;
      }
    }

    .header_timer {
      margin-bottom: 40px;
      .time {
        margin: auto;
        background: #2A2938;
        border: 1px solid #262636;
        border-radius: 15px;
        padding: 20px;
        margin-top: 30px;
        height: 150px;
        width: 100%;

        h6 {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 900;
          font-size: 34px;
          line-height: 80px;
          text-align: center;
          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #FFFFFF;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        p {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height, or 158% */

          text-align: center;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          color: #8D8D8D;
        }
      }
    }

    .header {
      position: relative;
      .price {
        display: grid;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 37px;
        letter-spacing: -0.02em;
        color: #ffffff;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #8d8d8d;
        }
      }
      .status {
        font-size: 14px;
        color: #fff;
        background: #fd3eda;
        border: 1.5px solid #ffffff;
        border-radius: 4px;
        padding: 4px 16px;
        position: absolute;
        right:0%;
        top: 5%;
      }
      .upocming {
        font-size: 14px;
        text-align: center;
        color: #fff;
        background: #FA7753;
        border: 1.5px solid #ffffff;
        border-radius: 4px;
        padding: 4px 16px;
        width: 120px;
        margin: auto;
        margin-bottom: 15px;
      }

      .time {
        margin: auto;
        //background: #2A2938;
        background: #1E1B45;
        border: 1px solid #262636;
        border-radius: 15px;
        padding: 10px;
        //margin-top: 30px;
        height: 150px;
        width: 100%;

        h6 {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 900;
          font-size: 34px;
          line-height: 80px;
          text-align: center;
          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #FFFFFF;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        .time_detail {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 400;
          font-size: 12px !important;
          line-height: 0;
          text-align: center;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #8D8D8D;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        p {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height, or 158% */

          text-align: center;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          color: #8D8D8D;
        }
        @include mobile {
         h6 {
          font-size: 24px !important;
          span {
            opacity: 0.4;
            padding: 0 10px;
          }
         }
         .time_detail {
          font-size: 10px !important;
          span {
            opacity: 0.4;
            padding: 0 10px;
          }
         }
        }
      }
    }

    .daynamicSlider {
      width: 33.33%;
      display: flex;
      border: none;
      margin: 0;

      div {
        width: 33.33%;
        border: none;
        margin: 0;
      }

      img {
        width: 100%;
      }
    }

    .details {
      margin: 20px 0;
      label p {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #8d8d8d;
        padding: 7px 0;
        font-weight: 300 !important;

        .text-green {
          color: #32FE9A;
        }

        b {
          font-weight: 300 !important;
          color: #fff;
          span {
            color: #8d8d8d;
          }
        }
      }
    }

    .mint {
      margin-top: 40px;
      span {
        font-weight: 300px;
        color: #8d8d8d;
        font-size: 12px;
        line-height: 19px;
        b {
          color: #fff;
          margin: 0 5px;
        }
      }
    }

    .input {
      display: inline-flex;
      width: 100%;
      margin: 20px 0;
      input {
        width: 100%;
        border: solid 2px var(--border-color);
        padding: 15px;
        text-align: center;
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
      }
      button {
        width: 90px;
        color: #fff;
        transition: ease-in-out 0.2s;
        border: 2px solid var(--border-color) !important;
        background: var(--secondary-bg) radial-gradient(circle, transparent 1%, var(--secondary-bg) 1%) center/15000% !important;
        &:first-child {
          border-right: none !important;
          border-radius: 10px 0 0 10px;
          &::before {
            content:url('../../../assets/minus.svg');
            display: block;
            margin-top: 7px;
          }
        }
        &:last-child {
          border-left: none !important;
          border-radius: 0 10px 10px 0;
          &::before {
            content:url('../../../assets/add.svg');
            display: block;
            margin-top: 7px;
          }
        }
        &:last-child, &:first-child {
          transition: background 0.4s;
          &:hover {
            background: #01cffdb0 radial-gradient(circle, transparent 1%, #01cffdb0 1%) center/15000% !important;
          }
          &:disabled {
            background: var(--disabled-bg) radial-gradient(circle, transparent 1%, var(--disabled-bg) 1%) center/15000% !important;
            &:active {
              background-color: #272738 !important;
              background-size: 100% !important;
              transition: background 0s;
            }
          }
          &:active {
            background-color: #01cdfd !important;
            background-size: 100% !important;
            transition: background 0s;
          }
        }
      }
    }

    .footer {
      padding: 0;
      margin: 0 !important;
      float: none;
      #mint {
        &:disabled {
          img {
            filter: brightness(6);
          }
        }
      }
      button {
        background: #01cdfd radial-gradient(circle, transparent 1%, #01cdfd 1%) center/15000%;
        border-radius: 10px;
        width: 100%;
        height: 52px;
        margin-top: 20px;
        font-size: 18px;
        color: #1b1b1b;
        font-weight: 500;
        transition: background 0.8s;
        &:hover {
          background: #01cffdca radial-gradient(circle, transparent 1%, #01cffdca 1%) center/15000%;
          border: solid 2px #01cdfd;
        }
        &:active {
          background-color: #13c3ea83;
          background-size: 100%;
          transition: background 0s;
        }
        &:disabled {
          background: var(--disabled-bg);
          border: solid 2px var(--disabled-bg);
          color: var(--disabled-color);
        }
      }
    }
  }
}

.launchRewards {
  max-width: $--max-width;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 120px;
  position: relative;
  //margin-bottom: 200px;

  .title {
    display: flex;
    margin-bottom: 30px;

    img {
      margin-right: 15px;
    }
  }

  h6 {
    font-family: "shapiro65_light_heavy_wide";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  table {
    text-align: left;
    border-radius: 15px;
    background: var(--primary-bg);
    .tableHeader {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #919191;
      border-bottom: 1px solid #262636;
      background: var(--primary-bg) !important;
      .tooltip_nfts { position: relative; }
      .tooltip_nfts:before {
        background: #9c2a6f;
        content: "Minted/Total Supply";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        left: -10px;
        bottom: -10px;
      }
      .tooltip_nfts:after {
        width: 12px;
        height: 12px;
        background: #9c2a6f;
        content: "";
        position: absolute;
        left: 50px;
        bottom: 9px;
        transform: rotate(45deg);
        border-radius: 3px;
      }
      .tooltip_nfts:before, .tooltip_nfts:after {display: none;}
      .tooltip_nfts:hover::before, .tooltip_nfts:hover::after {display: block;}
    }
    @include mobile {
      display: none;
    }
  }
  tr {
    &:nth-child(odd) {background-color: var(--secondary-bg);}
    color: #fff;
    td {
      padding: 30px 40px;
      font-weight: 400;
      &:first-child {
        display: flex;
        padding: 40px 40px;
        img {
          margin-right: 10px;
        }
      }
      span {
        color: #919191;
      }
    }
  }

  .tableHeader__mobile {
    background: #1e1e2a !important;
    border: solid 1px #262636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    p {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: solid 1px #262636;
    }
    @include desktop {
      display: none;
    }
  }
  .tableHeader__mobile__divider {
    background: #262636;
    width: 100%;
    height: 1px;
    @include desktop {
      display: none;
    }
  }
}

.projectinfo {
  margin-top: 120px;
  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      display: block;
    }
    .info_header {
      width: 600px;
      @include mobile {
        width: 100%;
      }
      p {
        font-size: 15px;
        line-height: 18px;
        color: #BBBBBB;
      }
    }
  }

  .split {
    display: flex;
    justify-content: space-between;
    @include mobile {
      display: block;
    }
  }

  .info_card {
    //background: #1E1E2A;
    background: var(--primary-bg);
    border-radius: 15px;
    padding: 32px;
    text-align: center;
    //width: 100%;
    p {
      display: grid;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      color: #919191;
    }
    span {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }

    &__left {
      margin: 26px 0 0 0;
    }
    &__center {
      margin: 26px 0 0 26px;
    }
    &__right {
      margin: 26px 0 0 26px;
    }
    &__wallet {
      min-width: 600px;
    }

    .address {
      display: flex;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      .hyperlink {
        width: 30px;
      }
      &:active {
        .hyperlink {
          transition: ease-in-out all 0.5ms;
          transform: scale(0.7);
        }
      }
    }
    @include mobile {
      &__left, &__center, &__right {
        margin: 26px 0 !important;
      }
      &__wallet {
        min-width: 100%  !important;
      }
    }
  }
}


.pauseModal {
  z-index: 99999;
  margin-top: 95px;

  .pauseCard {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    color: #fff;
    @include mobile {
      padding-bottom: 30px !important;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: 800;
  }
}